import * as React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TextField,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Select,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { ACTION_STATUSES } from "constants/type";
import { userRols } from "constants/userRols";
import EnhancedTableHead from "./EnhancedTableHead";

export default function TeamList(props) {
  const {
    users,
    onDelete,
    onEdit,
    selectedUser,
    setSelectedUser,
    changeSelectedUser,
    isEdit,
    onUpdateRow,
    onClose,
  } = props;
  const { update_status } = useSelector(state => state.tag);
  const {role} = useSelector(state => state.signin.user);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 500 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead />
            <TableBody>
              {users.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell align="center">
                      {isEdit && selectedUser?.id === row.id ? (
                        <div className="update-tag-input-wrapper">
                          <TextField
                            autoFocus
                            margin="dense"
                            id="first_name"
                            label="Update name"
                            type="text"
                            fullWidth
                            disabled={update_status === ACTION_STATUSES.PENDING}
                            value={selectedUser?.first_name}
                            onChange={changeSelectedUser}
                          />
                        </div>
                      ) : (
                        <>{row.first_name}</>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {isEdit && selectedUser?.id === row.id ? (
                        <div className="update-tag-input-wrapper">
                          <TextField
                            autoFocus
                            margin="dense"
                            id="last_name"
                            label="Update surname"
                            type="text"
                            fullWidth
                            disabled={update_status === ACTION_STATUSES.PENDING}
                            value={selectedUser?.last_name}
                            onChange={changeSelectedUser}
                          />
                        </div>
                      ) : (
                        <>{row.last_name}</>
                      )}
                    </TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">
                      {isEdit && selectedUser?.id === row.id ? (
                        <div className="update-tag-input-wrapper">
                          <Box sx={{ maxWidth: 120 }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Role
                              </InputLabel>
                              <Select
                                labelId="role"
                                id="role"
                                value={selectedUser.role}
                                label="Role"
                                onChange={event => setSelectedUser({...selectedUser, role: event.target.value})}
                              >
                                <MenuItem value="company_admin">Admin</MenuItem>
                                <MenuItem value="company_regular_user">
                                  Regular user
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      ) : (
                        <>{userRols[row.role]}</>
                      )}
                    </TableCell>
                    {role === "company_admin" &&
                    <TableCell align="center">
                      {isEdit && selectedUser?.id === row.id ? (
                        <div className="update-tag-input-wrapper">
                          {update_status === ACTION_STATUSES.PENDING ? (
                            <CircularProgress color="success" size={25} />
                          ) : (
                            <div>
                              <CheckIcon
                                color="success"
                                onClick={onUpdateRow}
                              />
                              <CloseIcon color="error" onClick={onClose} />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="update-tag-input-wrapper">
                          <IconButton onClick={() => onEdit(row)}>
                          <EditIcon
                            color="primary"
                          />
                          </IconButton>
                          <IconButton onClick={() => onDelete(row)}>
                          <DeleteIcon
                            color="secondary"
                          />
                          </IconButton>
                        </div>
                      )}
                    </TableCell>
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
