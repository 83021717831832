export function getLineChartOptions(title, height, width, series) {
  return {
    title: {
      text: title,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      enabled: false,
      labels: {
        enabled: true,
      },
      dataLabels: {
        enabled: true,
      },
      title: "",
    },
    series: series ? series : [],

    credits: {
      enabled: false,
    },
  };
}
export function getPieChartOptions(title, height, width, data) {
  return {
    title: {
      text: title,
    },
    credits: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    // legend: {
    //   // x: 100,
    //   // y: 80,
    //   width: "100%",
    //   floating: true,
    //   backgroundColor: '#FFFFFF',
    //   shadow: true
    // },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Count of leads",
        colorByPoint: true,
        data: data,
      },
    ],
  };
}

/* series: [
      {
        type: "pie",
        sliced: true,
        dataLabels: {
          enabled: false,
        },
      },

      {
        name: 'Website',
        data: [{
          y: 1,
          name: "Website",
         ,}]
      },
      {
        name: 'Tiktok',
        data: [{
          y: 7,
          name: "Tiktok",
          }]
      },
      {
        name: 'Facebook',
        data: [{
          y: 8,
          name: "Facebook",
         }]
      },
    ],*/

export function getBarChartOptions(title) {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "column",
      style: {
        float: "centre",
      },
    },
    title: {
      text: "Leads per Audience",
    },
  };
}
