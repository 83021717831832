import * as Yup from "yup";
import ErrorMessages from "./errorMessages";
export const setPassValidation = () =>
  Yup.object({
    password: Yup.string()
      .min(6, ErrorMessages.scemasMin(6))
      .required("This field is required"),
    password_confirm: Yup.string()
      .min(6, ErrorMessages.scemasMin(6))
      .required("This field is required")
      .oneOf([Yup.ref("password")], "The passwords do not match"),
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
  });
export const resetPassValidation = () =>
  Yup.object({
    password: Yup.string()
      .min(6, ErrorMessages.scemasMin(6))
      .required("This field is required"),
    password_confirm: Yup.string()
      .min(6, ErrorMessages.scemasMin(6))
      .required("This field is required")
      .oneOf([Yup.ref("password")], "The passwords do not match"),
  });

export const resetPassInitialValues = {
  password: "",
  password_confirm: "",
};
