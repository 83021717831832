import React, {useState} from "react";
import { TextField } from "@mui/material";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const DatePicker = (props) => {
  const { value, handleChange, handleAccept, label } = props;

    return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={handleChange}
        onAccept={handleAccept}
        renderInput={(params) => <TextField {...params} color="info" />}
      />
    </LocalizationProvider>
  );
};
export default DatePicker;
