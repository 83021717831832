import React from "react";
import {useSelector} from "react-redux";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { headCells } from "./constants";

export default function EnhancedTableHead() {
    const {role} = useSelector(state => state.signin.user);

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (headCell.id !== "actions" || role === "company_admin") && (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "center" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
