import {
  GET_CHARTS_LEADS_REQUEST,
  GET_CHARTS_LEADS_SUCCEED,
  GET_CHARTS_LEADS_FAILURE,
  ACTION_STATUSES,
  GET_DASHBOARD_LEADS_REQUEST,
  GET_DASHBOARD_LEADS_SUCCEED,
  GET_DASHBOARD_LEADS_FAILURE,
} from "constants/type";

const initialState = {
  leadsData: {},
  leadsDataStatus: null,
  dashboardData: {},
  dashboardDataStatus: null,
  message: "",
};

const tagReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DASHBOARD_LEADS_REQUEST:
      return {
        ...state,
        dashboardDataStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case GET_DASHBOARD_LEADS_SUCCEED:
      return {
        ...state,
        dashboardData: action.payload.data,
        dashboardDataStatus: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case GET_DASHBOARD_LEADS_FAILURE:
      return {
        ...state,
        dashboardDataStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case GET_CHARTS_LEADS_REQUEST:
      return {
        ...state,
        leadsDataStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case GET_CHARTS_LEADS_SUCCEED:
      return {
        ...state,
        leadsData: action.payload.data,
        leadsDataStatus: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case GET_CHARTS_LEADS_FAILURE:
      return {
        ...state,
        leadsDataStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };

    default:
      return state;
  }
};

export default tagReducer;
