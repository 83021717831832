import {
  GET_CHARTS_LEADS_REQUEST,
  GET_CHARTS_LEADS_SUCCEED,
  GET_CHARTS_LEADS_FAILURE,
  GET_DASHBOARD_LEADS_REQUEST,
  GET_DASHBOARD_LEADS_SUCCEED,
  GET_DASHBOARD_LEADS_FAILURE,
} from "constants/type";
import { getLeadsData, getDashboardData } from "vendor/services/chartsService";

export function fetchLeadsChartData() {
  return async (dispatch) => {
    dispatch({ type: GET_CHARTS_LEADS_REQUEST });
    const result = await getLeadsData();
    if (result?.status === 200) {
      dispatch({
        type: GET_CHARTS_LEADS_SUCCEED,
        payload: result?.data,
      });
    } else {
      dispatch({
        type: GET_CHARTS_LEADS_FAILURE,
        message: "",
      });
    }
  };
}
export function fetchDashboardChartData() {
  return async (dispatch) => {
    dispatch({ type: GET_DASHBOARD_LEADS_REQUEST });
    const result = await getDashboardData();
    if (result?.status === 200) {
      dispatch({
        type: GET_DASHBOARD_LEADS_SUCCEED,
        payload: result?.data,
      });
    } else {
      dispatch({
        type: GET_DASHBOARD_LEADS_FAILURE,
        message: "",
      });
    }
  };
}
