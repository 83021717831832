import {
  EXPORT_FACEBOOK_PAGES_REQUEST,
  EXPORT_FACEBOOK_PAGES_SUCCEED,
  EXPORT_FACEBOOK_PAGES_FAILURE,
  GET_FACEBOOK_LEADS_REQUEST,
  GET_FACEBOOK_LEADS_SUCCEED,
  GET_FACEBOOK_LEADS_FAILURE,
  LEADS_ASSIGNTO_USER_REQUEST,
  LEADS_ASSIGNTO_USER_SUCCEED,
  LEADS_ASSIGNTO_USER_FAILURE,
  ASSIGN_TAG_LEADS_REQUEST,
  ASSIGN_TAG_LEADS_SUCCEED,
  ASSIGN_TAG_LEADS_FAILURE,
  REASSIGN_TAG_LEADS_REQUEST,
  REASSIGN_TAG_LEADS_SUCCEED,
  REASSIGN_TAG_LEADS_FAILURE,
  LEADS_UNASSIGN_FROM_USER_REQUEST,
  LEADS_UNASSIGN_FROM_USER_SUCCEED,
  LEADS_UNASSIGN_FROM_USER_FAILURE,
  CREATE_A_LEAD_REQUEST,
  CREATE_A_LEAD_SUCCEED,
  CREATE_A_LEAD_FAILURE,
  REMOVE_LEADS_REQUEST,
  REMOVE_LEADS_SUCCEED,
  REMOVE_LEADS_FAILURE,
  IMPORT_LEADS_REQUEST,
  IMPORT_LEADS_SUCCEED,
  IMPORT_LEADS_FAILURE,
} from "constants/type";
import {
  exportFbPages,
  getFbLeads,
  assignTags,
  leadsAssignToUser,
  reAssignTags,
  leadsUnassignUser,
  createLead,
  remove,
  importLeads
} from "vendor/services/leadsService";


export function exportPages(pages) {
  return async (dispatch) => {
    dispatch({ type: EXPORT_FACEBOOK_PAGES_REQUEST });
    const result = await exportFbPages(pages);
    if (result?.status === 200) {
      dispatch({
        type: EXPORT_FACEBOOK_PAGES_SUCCEED,
        message: "",
      });
    } else {
      dispatch({
        type: EXPORT_FACEBOOK_PAGES_FAILURE,
        message: "",
      });
    }
  };
}

export function getLeads(company_id, filters) {
  return async (dispatch) => {
    dispatch({ type: GET_FACEBOOK_LEADS_REQUEST });
    const result = await getFbLeads(company_id, filters);
    if (result?.status === 200) {
      dispatch({
        type: GET_FACEBOOK_LEADS_SUCCEED,
        payload: result.data,
      });
    } else {
      dispatch({
        type: GET_FACEBOOK_LEADS_FAILURE,
        message: "",
      });
    }
  };
}
export function assignToUser(data) {
  return async (dispatch) => {
    dispatch({ type: LEADS_ASSIGNTO_USER_REQUEST });
    const result = await leadsAssignToUser(data);
    if (result?.status === 200) {
      dispatch({
        type: LEADS_ASSIGNTO_USER_SUCCEED,
        payload: result.data,
      });
    } else {
      dispatch({
        type: LEADS_ASSIGNTO_USER_FAILURE,
        message: "",
      });
    }
  };
}
export function assignTagsToLeads(data) {
  return async (dispatch) => {
    dispatch({ type: ASSIGN_TAG_LEADS_REQUEST });
    const result = await assignTags(data);
    if (result?.status === 200) {
      dispatch({
        type: ASSIGN_TAG_LEADS_SUCCEED,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: ASSIGN_TAG_LEADS_FAILURE,
        message: "",
      });
    }
  };
}
export function importLeadsFile(file) {
  return async (dispatch) => {
    dispatch({ type: IMPORT_LEADS_REQUEST });
    const result = await importLeads(file);
    if (result?.status === 201) {
      dispatch({
        type: IMPORT_LEADS_SUCCEED,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: IMPORT_LEADS_FAILURE,
        message: "",
      });
    }
  };
}
export function reAssignTagsToLeads(data) {
  return async (dispatch) => {
    dispatch({ type: REASSIGN_TAG_LEADS_REQUEST });
    const result = await reAssignTags(data);
    if (result?.status === 200 || result?.status === 204) {
      dispatch({
        type: REASSIGN_TAG_LEADS_SUCCEED,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: REASSIGN_TAG_LEADS_FAILURE,
        message: "",
      });
    }
  };
}
export function unassignUser(data) {
  return async (dispatch) => {
    dispatch({ type: LEADS_UNASSIGN_FROM_USER_REQUEST });
    const result = await leadsUnassignUser(data);
    if (result?.status === 200) {
      dispatch({
        type: LEADS_UNASSIGN_FROM_USER_SUCCEED,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: LEADS_UNASSIGN_FROM_USER_FAILURE,
        message: "",
      });
    }
  };
}
export function createALead(data) {
  return async (dispatch) => {
    dispatch({ type: CREATE_A_LEAD_REQUEST });
    const result = await createLead(data);
    if (result?.status === 201) {
      dispatch({
        type: CREATE_A_LEAD_SUCCEED,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: CREATE_A_LEAD_FAILURE,
        message: "",
      });
    }
  };
}
//remove
export function removeLeads(data) {
  return async (dispatch) => {
    dispatch({ type: REMOVE_LEADS_REQUEST });
    const result = await remove({ lead_ids: data });
    if (result?.status === 200) {
      dispatch({
        type: REMOVE_LEADS_SUCCEED,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: REMOVE_LEADS_FAILURE,
        message: "",
      });
    }
  };
}

