import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_STATUSES } from "constants/type";
import { setUser } from "vendor/redux/actions";
import {
  Paper,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useFormik } from "formik";
import {
  signInInitialValues,
  signInValidation,
} from "vendor/services/yup-schemas/signInValidation";
import { useHistory } from "react-router-dom";
import routes from "constants/routes";

import styles from "./styles";

const SignIn = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { status, message } = useSelector((state) => state.signin);
  const onSubmit = async (values) => {
    await dispatch(setUser(values));
  };
  const formik = useFormik({
    initialValues: signInInitialValues,
    validationSchema: signInValidation,
    onSubmit,
  });
  const submitListener = () => {
    if (status === ACTION_STATUSES.SUCCEED) {
      history.push(routes.DASHBOARD);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(submitListener, [status]);

  return (
    <div className="auth-pages">
      <form onSubmit={formik.handleSubmit}>
        <Paper className={`paper`} elevation={3}>
          <div className={classes.margin}>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <Typography variant="h5" style={{textAlign: "center"}}> Sign In </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="div" style={{textAlign: "center"}}>
                  Don't have an account?
                  <Button
                      disableFocusRipple
                      disableRipple
                      style={{ textTransform: "none", marginLeft: "4px" }}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        history.push(routes.SIGNUP);
                      }}
                  >
                    Sign up
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  autoComplete="new-password"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{ p: 1 }}
            >
              <Grid item>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Remember me"
                />
              </Grid>
              <Grid item>
                <Button
                  disableFocusRipple
                  disableRipple
                  style={{ textTransform: "none" }}
                  variant="text"
                  color="primary"
                  onClick={() => {
                    history.push(routes.FORGOT_PASS);
                  }}
                >
                  Forgot password ?
                </Button>
              </Grid>

            </Grid>
            <Grid
              container
              justify="center"
              style={{ marginTop: "10px" }}
              sx={{ p: 1 }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                fullWidth
                type="submit"
                disabled={status === ACTION_STATUSES.PENDING}
              >
                Sign In
              </Button>
              {message && <Typography className="error">{message}</Typography>}
            </Grid>
          </div>
        </Paper>
      </form>
    </div>
  );
};

export default withStyles(styles)(SignIn);
