import React, {useEffect} from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { signOut } from "vendor/redux/actions";
import { getRefreshToken } from "vendor/helper/storage";
import routes from "constants/routes";

const HeaderRightPart = (props) => {
  const { user, anchorEl, setAnchorEl } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickSignOut = async () => {
    dispatch(signOut({ refresh: getRefreshToken() }, history));
  };

  useEffect(() => {}, [user.first_name])

  const redirectAccountPage = () => {
    history.push(routes.USER_ACCOUNT);
  };


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        m: 1,
        marginLeft: "auto"
      }}
    >
      <Typography
        variant="p"
        noWrap
        component="div"
        sx={{ flexGrow: 1, pr: 0, fontSize: "17px"}}
        mr={1}
      >
        {user?.first_name
          ? `${user?.first_name} ${user?.last_name}`
          : "Client name"}
      </Typography>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="info"
        size={"large"}
      >
        <AccountCircleIcon style={{fontSize: "32px"}} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onClickSignOut}> Logout</MenuItem>
        <MenuItem onClick={redirectAccountPage}>My account</MenuItem>
      </Menu>
    </Box>
  );
};

export default HeaderRightPart;
