import React from "react";

import { Provider } from "react-redux";
import store from "vendor/redux/store";
import Routes from "routes/routes";
import "styles/_app.scss";
import FacebookService from "./vendor/services/facebook";

window.onload = () => {
    FacebookService.init()
}

const App = () => {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default App;
