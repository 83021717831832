import React, { useEffect, useState } from "react";
import {format, isValid} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Grid, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import {
  fetchAllAudiences,
  exportAudiences,
  removeMutipleAudiences,
  removeAudience,
} from "vendor/redux/actions";
import { ACTION_STATUSES } from "constants/type";
import {
  AudiencesList,
  DatePicker,
  Search,
  Loading,
  handleShowModal,
  modalTypes,
} from "components";
import { csvDownload } from "vendor/helper/common";
import {handleEnterPress} from "../../vendor/services/keyboardEventHandlers/pressEnterKey";

const AudiencesPage = () => {
  const dispatch = useDispatch();
  const { status, remove_multi_status, remove_status } = useSelector(
    (state) => state.audiences
  );
  const [actionValue, setActionValue] = useState("");
  const [audienceId, setAaudienceId] = useState(0);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    search: "",
    ordering: "",
    start_date: null,
    end_date: null,
  });
  const [selectedAudience, setSelectedAudience] = useState({
    name: "",
    id: 0,
  });
  const [selectedAudiences, setSelectedAudiences] = useState([]);
  const onOpenModal = (component) => handleShowModal(dispatch, component, true);

  const init = () => {
    getAllAudieces(filters);
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (selectedAudience.id > 0) {
      onOpenModal(
        modalTypes.removeAudience({
          message: `Remove ${selectedAudience.name}`,
          action: handleRemoveAudience,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAudience]);

  const getAllAudieces = (filters) => {
    dispatch(fetchAllAudiences(filters));
  };

  const handleSearch = () => {
    dispatch(fetchAllAudiences(filters));
  };
  const handleChangePage = (page) => {
    dispatch(fetchAllAudiences({
      ...filters,
      start_date: filters.start_date?.toISOString().split('T')[0],
      end_date: filters.end_date?.toISOString().split('T')[0],
      offset: page * filters.limit
    }));
    setFilters({ ...filters, offset: page * filters.limit });
  };
  const handleChangeRowsPerPage = (limit) => {
    dispatch(fetchAllAudiences({
      ...filters,
      start_date: filters.start_date?.toISOString().split('T')[0],
      end_date: filters.end_date?.toISOString().split('T')[0],
      offset: 0, limit: limit
    }));
    setFilters({ ...filters, limit: limit });
  };
  const handleRequestSort = (order) => {
    dispatch(fetchAllAudiences({
      ...filters,
      start_date: filters.start_date?.toISOString().split('T')[0],
      end_date: filters.end_date?.toISOString().split('T')[0],
      ordering: order }));
    setFilters({
      ...filters,
      ordering: order
    });
  };

  const handleExportAudiences = async (audienceId) => {
    setAaudienceId(audienceId);
    let result = await dispatch(exportAudiences(audienceId));
    setAaudienceId(0);
    csvDownload(result, "leads.csv");
  };

  const handleRemoveMutipleAudiences = async () => {
    await dispatch(removeMutipleAudiences({ audience_ids: selectedAudiences }));
    handleShowModal(dispatch, "", false);
  };
  const handleRemoveAudience = async () => {
    await dispatch(removeAudience(selectedAudience.id));
    setSelectedAudience({ ...selectedAudience, id: 0 });
    handleShowModal(dispatch, "", false);
  };
  const handleDeleteAudience = (audiecne) => {
    setSelectedAudience({
      name: audiecne.name,
      id: audiecne.id,
    });
  };

  const submitListener = () => {
    if (
      remove_multi_status === ACTION_STATUSES.SUCCEED ||
      remove_status === ACTION_STATUSES.SUCCEED
    ) {
      setSelectedAudiences([]);
      getAllAudieces({ ...filters, offset: 0 });
    }
  };

  const handleChangeStartDate = (start_data) => {
    setFilters({ ...filters, start_date: start_data });
  };

  const handleChangeEndDate = (end_date) => {
    setFilters({ ...filters, end_date: end_date });
  };

  useEffect( () => {
    if(isValid(filters.start_date) || isValid(filters.end_date)) {
      let formattedStartDate = (isValid(filters.start_date) && format(new Date(filters.start_date), "yyyy-MM-dd"));
      let formattedEndDate =  (isValid(filters.end_date) && format(new Date(filters.end_date), "yyyy-MM-dd"))
      dispatch(
          fetchAllAudiences({
            ...filters,
            start_date: (formattedStartDate ? formattedStartDate : null),
            end_date: (formattedEndDate ? formattedEndDate : null)
          })
      )
    } else {
      return null
    }

  }, [filters.end_date, filters.start_date])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(submitListener, [remove_multi_status, remove_status]);

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{
          marginBottom: 30,
        }}
      >
        <Grid item xs={4} sm={4} md={4} onKeyPress={e => handleEnterPress(e, handleSearch)}>
          <Search
            label="Search"
            value={filters.search}
            onChange={(event) =>
              setFilters({ ...filters, search: event.target.value })
            }
            onSearch={handleSearch}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <DatePicker
            label="Start Date"
            value={filters.start_date}
            handleChange={handleChangeStartDate}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <DatePicker
            label="End Date"
            value={filters.end_date}
            handleChange={handleChangeEndDate}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <FormControl style={{ width: 150 }} color="info">
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Table Actions"
              onChange={(event) => {
                setActionValue(event.target.value);
              }}
              disabled={selectedAudiences.length === 0}
              value={actionValue}
            >
              <MenuItem
                onClick={() => {
                  onOpenModal(
                    modalTypes.removeAudiences({
                      message: `Remove selected Audiences`,
                      action: handleRemoveMutipleAudiences,
                    })
                  );
                }}
                value={"remove"}
              >
                Remove Audiecne
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {ACTION_STATUSES.PENDING === status && <Loading color="info" />}
      <AudiencesList
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        orderBy={filters.ordering}
        handleRequestSort={handleRequestSort}
        setSelected={(audiences) => setSelectedAudiences(audiences)}
        selected={selectedAudiences}
        onClickDownload={handleExportAudiences}
        audienceId={audienceId}
        onDelete={handleDeleteAudience}
      />
    </>
  );
};

export default AudiencesPage;
