import React from "react";
import {TextField, Button, Grid} from "@mui/material";
import { useFormik } from "formik";
import {
  changePassValidation,
  changePassInitialValues,
} from "vendor/services/yup-schemas/changePassValidation";
const ChangePassForm = (props) => {
  const { handleChangePass, disabled } = props;
  const onSubmit = async (values) => {
    await handleChangePass(values);
  };
  const formik = useFormik({
    initialValues: changePassInitialValues,
    validationSchema: changePassValidation,
    onSubmit,
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4} >
            <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label="Old password"
                style={{ width: "100%"}}
                value={formik.values.old_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.old_password && Boolean(formik.errors.old_password)
                }
                helperText={
                  formik.touched.old_password && formik.errors.old_password
                }
                type="password"
                required
                id="old_password"
                name="old_password"
            />
          </Grid>
          <Grid item  xs={12} md={6} lg={4}>
            <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label="New password"
                style={{ width: "100%", marginBottom: 10 }}
                value={formik.values.new_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.new_password && Boolean(formik.errors.new_password)
                }
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
                type="password"
                required
                id="new_password"
                name="new_password"
            />

          </Grid>
          <Grid item  xs={12} md={6} lg={4}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Confirm password"
            style={{ width: "100%"}}
            value={formik.values.new_password_confirm}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.new_password_confirm &&
              Boolean(formik.errors.new_password_confirm)
            }
            helperText={
              formik.touched.new_password_confirm &&
              formik.errors.new_password_confirm
            }
            type="password"
            required
            id="new_password_confirm"
            name="new_password_confirm"
          />

          </Grid>
          <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
            <Grid item xs={12} md={6} lg={3}>
              <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={disabled}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default ChangePassForm;
