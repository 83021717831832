import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_STATUSES } from "constants/type";
import {Link, useHistory} from "react-router-dom";
import routes from "constants/routes";
import { Paper, Grid, TextField, Button, Typography, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useFormik } from "formik";
import {
  forgotPassValidation,
  forgotPassInitialValues,
} from "vendor/services/yup-schemas/forgotPassValidation";
import { forgotPassUser } from "vendor/redux/actions/authActions";
import { SuccessAlert } from "components";
import styles from "./styles";

const ForgotPassword = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { message, status } = useSelector((state) => state.forget);
  const onSubmit = async (values) => {
    await dispatch(forgotPassUser(values));
  };

  const submitListener = () => {
    if (status === ACTION_STATUSES.SUCCEED) {
      setOpen(true);
    }
  };
  const formik = useFormik({
    initialValues: forgotPassInitialValues,
    validationSchema: forgotPassValidation,
    onSubmit,
  });
  useEffect(submitListener, [status]);

  return (
    <div className="auth-pages">
      {
        open && <SuccessAlert
            open={open}
            setOpen={setOpen}
            message="The message for reset your password send successfully. Please check your email."
        />
      }

      {
        !open &&
      <form onSubmit={formik.handleSubmit} className="forgot-pass__form">
        <Paper className={`${classes.paper} paper`} elevation={3}>
          <div className={classes.margin}>
            <Grid item md={true} sm={true} xs={true}>
              <Typography variant="h5" style={{textAlign: "center"}}>Forgot Password?</Typography>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Link to={routes.APP}>
                    {/* <ArrowLeftIcon className="has-cursor" /> */}
                  </Link>
                  <Typography>
                    {" "}
                    Enter the email address associated with your account and
                    we'll send you a link to reset your password.{" "}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  autoFocus
                  autoComplete='off'
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="standard"
                />
              </Grid>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: "10px" }}
              sx={{ p: 1 }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                fullWidth
                type="submit"
                disabled={status === ACTION_STATUSES.PENDING}
              >
                Continue
              </Button>
              {message && <Typography className="error">{message}</Typography>}
              <Grid item xs={12} mt={2}>
                <Typography component="div" style={{textAlign: "center", fontSize: "17px"}}>
                  Go back to
                  <Button
                      disableFocusRipple
                      disableRipple
                      style={{ textTransform: "none", marginLeft: "4px" }}
                      variant="text"
                      color="primary"
                      ml={1}
                      onClick={() => {
                        history.push(routes.SIGNIN);
                      }}
                  >

                    Sign in
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </form>}
    </div>
  );
};

export default withStyles(styles)(ForgotPassword);
