import React, { useState } from "react";
import { ACTION_STATUSES } from "constants/type";
import { useDispatch } from "react-redux";
import { Input, handleShowModal } from "components";
const CreateTag = (props) => {
  const dispatch = useDispatch();
  const { status, onSave } = props;
  const [title, setTitle] = useState("");
  const onClose = () => {
    handleShowModal(dispatch, "", false);
  };
  return (
    <div className="add-tag-input-wrapper">
      <Input
        autoFocus
        margin="dense"
        label="Create new tag"
        type="text"
        fullWidth
        disabled={status === ACTION_STATUSES.PENDING}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        onUpdate={() => (title !== "" ? onSave(title) : null)}
        onClose={onClose}
        status={status}
        isEdit
      />
    </div>
  );
};
export default CreateTag;
