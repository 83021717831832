import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_STATUSES } from "constants/type";
import { changePassUser, preliminaryCheckUser } from "vendor/redux/actions";
import { Paper, Grid, TextField, Button, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useFormik } from "formik";
import {
  resetPassValidation,
  resetPassInitialValues,
  setPassValidation,
} from "vendor/services/yup-schemas/resetPassValidation";
import { useHistory, useLocation, useParams } from "react-router-dom";

import routes from "constants/routes";

import styles from "./styles";

const ResetPassword = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const isResetPass = location.pathname.includes("reset");
  const { message, status, preliminaryCheckDetails } = useSelector(
    (state) => state.forget
  );
  useEffect(() => {
    if (!isResetPass) {
      dispatch(preliminaryCheckUser(params.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values) => {
    await dispatch(
      changePassUser({
        uuid: params.uuid,
        type: isResetPass ? "FORGOT_PASSWORD" : "VERIFICATION",
        ...values,
      })
    );
  };

  const submitListener = () => {
    if (status === ACTION_STATUSES.SUCCEED) {
      history.push(routes.DASHBOARD);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(submitListener, [status]);
  let initialValues = {
    password: "",
    password_confirm: "",
    email: preliminaryCheckDetails?.data?.email
      ? preliminaryCheckDetails?.data?.email
      : "",
    first_name: preliminaryCheckDetails?.data?.first_name
      ? preliminaryCheckDetails?.data?.first_name
      : "",
    last_name: preliminaryCheckDetails?.data?.last_name
      ? preliminaryCheckDetails?.data?.last_name
      : "",
  };
  if (preliminaryCheckDetails?.data?.company) {
    initialValues = {
      password: "",
      password_confirm: "",
      email: preliminaryCheckDetails?.data?.email
        ? preliminaryCheckDetails?.data?.email
        : "",
      first_name: preliminaryCheckDetails?.data?.first_name
        ? preliminaryCheckDetails?.data?.first_name
        : "",
      last_name: preliminaryCheckDetails?.data?.last_name
        ? preliminaryCheckDetails?.data?.last_name
        : " ",
      company_name: preliminaryCheckDetails?.data?.company.name,
      company_address: preliminaryCheckDetails?.data?.company.address,
    };
  }
  const formik = useFormik({
    initialValues: preliminaryCheckDetails
      ? initialValues
      : resetPassInitialValues,
    enableReinitialize: true,
    validationSchema: preliminaryCheckDetails
      ? setPassValidation
      : resetPassValidation,
    onSubmit,
  });
  return (
    <div className="auth-pages">
      <form onSubmit={formik.handleSubmit}>
        <Paper className={`paper`} elevation={3}>
          <div className={classes.margin}>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <Typography variant="h5">
                  {!isResetPass ? "Set" : "Reset"} Password
                </Typography>
              </Grid>
            </Grid>
            {!isResetPass && (
              <>
                {" "}
                <Grid container sx={{ p: 1 }} alignItems="flex-end">
                  <Grid item md={true} sm={true} xs={true}>
                    <TextField
                      InputLabel={{ shrink: true }}
                      label="First Name"
                      name="first_name"
                      type="text"
                      fullWidth
                      required
                      autoComplete="off"
                      variant="standard"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                      error={
                        formik.touched.first_name &&
                        Boolean(formik.errors.first_name)
                      }
                      helperText={
                        formik.touched.first_name && formik.errors.first_name
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ p: 1 }} alignItems="flex-end">
                  <Grid item md={true} sm={true} xs={true}>
                    <TextField
                      label="Last Name"
                      name="last_name"
                      type="text"
                      fullWidth
                      required
                      autoComplete="off"
                      variant="standard"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      helperText={
                        formik.touched.last_name && formik.errors.last_name
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ p: 1 }} alignItems="flex-end">
                  <Grid item md={true} sm={true} xs={true}>
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                      autoComplete="off"
                      required
                      variant="standard"
                      disabled
                      autoFocus
                      value={formik.values.email}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {preliminaryCheckDetails?.data?.company && (
              <>
                <Grid container sx={{ p: 1 }} alignItems="flex-end">
                  <Grid item md={true} sm={true} xs={true}>
                    <TextField
                      label="Company Name"
                      name="company_name"
                      type="text"

                      fullWidth
                      required
                      variant="standard"
                      value={formik.values.company_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.company_name &&
                        Boolean(formik.errors.company_name)
                      }
                      helperText={
                        formik.touched.company_name &&
                        formik.errors.company_name
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ p: 1 }} alignItems="flex-end">
                  <Grid item md={true} sm={true} xs={true}>
                    <TextField
                      label="Company Address"
                      name="company_address"
                      type="text"
                      fullWidth
                      required
                      variant="standard"
                      value={formik.values.company_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.company_address &&
                        Boolean(formik.errors.company_address)
                      }
                      helperText={
                        formik.touched.company_address &&
                        formik.errors.company_address
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  required
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="off"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  required
                  fullWidth
                  id="password_confirm"
                  name="password_confirm"
                  label="Confirm Password"
                  type="password"
                  autoComplete="off"
                  value={formik.values.password_confirm}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password_confirm &&
                    Boolean(formik.errors.password_confirm)
                  }
                  helperText={
                    formik.touched.password_confirm &&
                    formik.errors.password_confirm
                  }
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ marginTop: "10px" }}
              sx={{ p: 1 }}
            >
              <Button
                variant="contained"
                color="warning"
                style={{ textTransform: "none" }}
                fullWidth
                type="submit"
                disabled={status === ACTION_STATUSES.PENDING}
              >
                {!isResetPass ? "Set" : "Reset"} Password
              </Button>
              {message && <Typography className="error">{message}</Typography>}
            </Grid>
          </div>
        </Paper>
      </form>
    </div>
  );
};

export default withStyles(styles)(ResetPassword);
