import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "vendor/redux/actions/authActions";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@mui/material/styles";

import {
  SignInPage,
  SignUpPage,
  LandingPage,
  NotFoundPage,
  InvitationPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  PricingPage,
  HowItWorksPage,
} from "screens";
import routes from "constants/routes";
import { theme } from "theme/theme";
import {DashboardLayout, Modal} from "components";
import DashboardRoutes from "./DashboardRoute";
import Dashboard from "../screens/dashboard/Dashboard";
const customHistory = createBrowserHistory();

const Routes = () => {
  const dispatch = useDispatch();
  const { isLoging } = useSelector((state) => state.signin);

  const init = () => {
    if (isLoging) {
      dispatch(fetchUserDetails());
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <Modal />
      <BrowserRouter customHistory={customHistory}>
        <Switch>
          <Route component={LandingPage} path={routes.APP} exact />
          <Route component={SignInPage} path={routes.SIGNIN} exact />
          <Route component={SignUpPage} path={routes.SIGNUP} exact />
          <Route
            component={InvitationPage}
            path={routes.USER_INVITATION}
            exact
          />
          <Route
            component={ForgotPasswordPage}
            path={routes.FORGOT_PASS}
            exact
          />
          <Route component={ResetPasswordPage} path={routes.SET_PASS} exact />
          <Route component={ResetPasswordPage} path={routes.RESET_PASS} exact />
          <Route component={PricingPage} path={routes.PRICING} exact />
          <Route component={HowItWorksPage} path={routes.HOW_IT_WORKS} exact />
          {DashboardRoutes.map((item) => {
            return (
              <Route path={item.path} exact key={item.id}>
                {!isLoging ? (
                  <Redirect to={routes.SIGNIN} />
                ) : (
                  <DashboardLayout breadcrumb={item.breadcrumb}>
                    {item.component}
                  </DashboardLayout>
                )}
              </Route>
            );
          })}
          <Route component={NotFoundPage} path={"*"} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Routes;
