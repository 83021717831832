import * as React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper, Button, IconButton,
} from "@mui/material";
import { Input } from "components";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import EnhancedTableHead from "./EnhancedTableHead";

export default function TagsList(props) {
  const {
    tags,
    onDelete,
    onEdit,
    selectedTag,
    isEdit,
    setSelectedTag,
    onUpdateTag,
    onClose,
  } = props;
  const { update_status } = useSelector((state) => state.tag);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 500 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead />
            <TableBody>
              {tags.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {isEdit && selectedTag?.id === row.id ? (
                        <Input
                          value={selectedTag?.title}
                          onChange={setSelectedTag}
                          onUpdate={onUpdateTag}
                          onClose={onClose}
                          status={update_status}
                          label="Update tag"
                          isEdit
                        />
                      ) : (
                        <>{row.title}</>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => onEdit(row)} >
                        <EditIcon color="primary" />
                      </IconButton>
                      <IconButton onClick={() => {
                        onDelete(row);
                      }}>
                        <DeleteIcon
                            color="secondary"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
