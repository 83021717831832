import { Tags } from "vendor/services/api/agent";

// getTags
export const getTags = async () => {
  try {
    const response = await Tags.list();
    return response;
  } catch (error) {
    return error;
  }
};
// createTag
export const create = async (data) => {
  try {
    const response = await Tags.create(data);
    return response;
  } catch (error) {
    return error;
  }
};

// deleteTag
export const remove = async (id) => {
  try {
    const response = await Tags.remove(id);
    return response;
  } catch (error) {
    return error;
  }
};

// udate Tag
export const update = async (id, data) => {
  try {
    const response = await Tags.update(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
