import * as Yup from "yup";

export const createLeadValidation = () =>
  Yup.object({
    email: Yup.string()
      .email("The e-mail address is not valid")
  });

export const createLeadInitialValues = {
  email: null,
  name: null,
  phone: null,
  country: null,
  acquisition_date: null,
  campaign: null,
  source: null,
  form: null,
  company: 0
};
