import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "constants/routes";
import { Typography } from "@mui/material";

import { HeaderRightPart } from "components";
import { logo } from "assets";

const LeadsHeader = ({ open }) => {
  const { user } = useSelector((state) => state.user);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Typography
        variant="h6"
        noWrap
        component="div"
        textAlign="center"
        sx={{
          flexGrow: 1,
          cursor: "pointer",
          display: { xs: "none", md: "flex" },
        }}
      >
        {!open && (
          <img
            src={logo}
            alt="nukak"
            className="main-logo"
            onClick={() => {
              history.push(routes.DASHBOARD);
            }}
          />
        )}
      </Typography>
      <HeaderRightPart
        user={user}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        anchorElNav={anchorElNav}
        setAnchorElNav={setAnchorElNav}
        handleMenu={handleMenu}
        handleClose={handleClose}
      />
    </>
  );
};
export default LeadsHeader;
