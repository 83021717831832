import * as Yup from "yup";
import ErrorMessages from "./errorMessages";
export const changePassValidation = () =>
  Yup.object({
    old_password: Yup.string()
      .min(6, ErrorMessages.scemasMin(6))
      .required("This field is required"),
    new_password: Yup.string()
      .min(6, ErrorMessages.scemasMin(6))
      .required("This field is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Number and One Special Case Character"
      ),
    new_password_confirm: Yup.string()
      .min(6, ErrorMessages.scemasMin(6))
      .required("This field is required")
      .oneOf([Yup.ref("new_password")], "The passwords do not match"),
  });

export const changePassInitialValues = {
  old_password: "",
  new_password: "",
  new_password_confirm: "",
};
