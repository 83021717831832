import React from "react";
import { Grid, Typography, Box, Card } from "@mui/material";
import { InvoicesList, InvoiceDetails } from "components";

const Invoices = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h4">Invoices</Typography>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              View the invoices for this account
            </Typography>
            <InvoicesList />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ padding: "16px 24px" }}>
            <InvoiceDetails />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default Invoices;
