import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import {isValid, format} from "date-fns";
import {
    Grid,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    fetchTags,
    getLeads,
    assignTagsToLeads,
    reAssignTagsToLeads,
    unassignUser,
    createAudience,
    removeLeads,
    assignToUser,
} from "vendor/redux/actions";
import { LeadsList, DatePicker, Search, LeadsCharts } from "components";
import { ACTION_STATUSES } from "constants/type";
import routes from "constants/routes";
import { handleShowModal } from "components/common/modal/handleShowModal";
import { modalTypes } from "components/common/modal/modalTypes";
import Sticky from 'react-sticky-el';
import {handleEnterPress} from "../../vendor/services/keyboardEventHandlers/pressEnterKey";

const LeadsPage = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { modalState } = useSelector((state) => state.modals);
    const {
        companies,
        count,
        reassignTagStatus,
        assignTagStatus,
        removeStatus,
        assignUser,
        reassignUser,
    } = useSelector((state) => state.leads);
    const { createStatus } = useSelector((state) => state.audiences);
    const [actionValue, setActionValue] = useState("");
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        search: "",
        ordering: "",
        start_date: null,
        end_date: null,
    });
    const [selectedLeads, setSelectedLeads] = useState([]);
    const [tagId, setTagId] = useState(0);
    const [isDelete, setIsDelete] = useState(false);
    const onOpenModal = (component) => handleShowModal(dispatch, component, true);
    const onCloseModal = (component) => handleShowModal(dispatch, component, false);

    const handleAssignToUser = async (user_id, lead_ids) => {
        await dispatch(
            assignToUser({
                user_id: user_id,
                lead_ids: lead_ids
            })
        );
    };
    const handleAssignTag = async (tagId, leads) => {
        setTagId(tagId);
        await dispatch(
            assignTagsToLeads({
                tag: tagId,
                lead: leads,
            })
        );
    };
    const init = () => {
        dispatch(fetchTags());
    };

    useEffect(init, [dispatch]);

    const userListener = () => {
        if (user?.company?.id || assignUser === ACTION_STATUSES.SUCCEED) {
            dispatch(getLeads(user?.company?.id, filters));
        }
    };
    useEffect(
        userListener,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user, dispatch, assignUser]
    );

    const handleChangeStartDate = (start_data) => {
        setFilters({ ...filters, start_date: start_data});
    };

    const handleChangeEndDate = (end_date) => {
        setFilters({ ...filters, end_date: end_date });
    };
    useEffect( () => {
        if(isValid(filters.start_date) || isValid(filters.end_date)) {
            let formattedStartDate = (isValid(filters.start_date) && format(new Date(filters.start_date), "yyyy-MM-dd"));
            let formattedEndDate =  (isValid(filters.end_date) && format(new Date(filters.end_date), "yyyy-MM-dd"))
            dispatch(
                getLeads(user?.company?.id, {
                    ...filters,
                    start_date: (formattedStartDate ? formattedStartDate : null),
                    end_date: (formattedEndDate ? formattedEndDate : null)
                })
            )
        } else {
            return null
        }

    }, [filters.end_date, filters.start_date])

    useEffect(() => {
        dispatch(fetchTags());
    }, [dispatch]);

    useEffect(() => {
        if (user?.company?.id || assignUser === ACTION_STATUSES.SUCCEED) {
            dispatch(getLeads(user?.company?.id, filters));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, dispatch, assignUser, modalState]);

    const onFilterLeads = () => {
        dispatch(getLeads(user?.company?.id, filters));
    };
    const handleChangePage = (page) => {
        dispatch(
            getLeads(user?.company?.id, {
                ...filters,
                start_date: filters.start_date?.toISOString().split('T')[0],
                end_date: filters.end_date?.toISOString().split('T')[0],
                offset: page * filters.limit })
        );
        setFilters({ ...filters, offset: page * filters.limit });
    };
    const handleChangeRowsPerPage = (limit) => {
        dispatch(
            getLeads(user?.company?.id, {
                ...filters,
                start_date: filters.start_date?.toISOString().split('T')[0],
                end_date: filters.end_date?.toISOString().split('T')[0],
                offset: 0,
                limit: limit
            })
        );
        setFilters({ ...filters, limit: limit });
    };
    const handleRequestSort = (order) => {
        dispatch(getLeads(
            user?.company?.id,
            {
                ...filters,
                start_date: filters.start_date?.toISOString().split('T')[0],
                end_date: filters.end_date?.toISOString().split('T')[0],
                ordering: order
            }));
        setFilters({ ...filters, ordering: order });
    };

    const handleReAssignTags = async (tagId, leads) => {
        await dispatch(
            reAssignTagsToLeads({
                tag: tagId,
                lead: leads,
            })
        );
    };
    const handleCreateAudience = async (name) => {
        await dispatch(
            createAudience({
                name: name,
                lead: selectedLeads,
            })
        );
        toast("The new Audience has been created successfully!");
    };

    const handleUnassignUser = async (leads) => {
        await dispatch(unassignUser({ lead_ids: leads }));
    };
    const handleRemoveLeads = async (leads) => {
        await dispatch(removeLeads(leads));
    };
    const submitListener = () => {
        if (
            reassignTagStatus === ACTION_STATUSES.SUCCEED ||
            assignTagStatus === ACTION_STATUSES.SUCCEED ||
            removeStatus === ACTION_STATUSES.SUCCEED ||
            assignUser === ACTION_STATUSES.SUCCEED ||
            reassignUser === ACTION_STATUSES.SUCCEED ||
            createStatus === ACTION_STATUSES.SUCCEED
        ) {
            setActionValue("actions");
            setSelectedLeads([]);
            setTagId(0);
            setIsDelete(false);
            onCloseModal("");
            dispatch(getLeads(user?.company?.id, { ...filters, offset: 0 }));
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(submitListener, [
        assignUser,
        assignTagStatus,
        reassignTagStatus,
        reassignUser,
        removeStatus,
        createStatus,
    ]);

    return (
        <>
            <LeadsCharts style={{marginBottom: "16px"}} />
            <Sticky stickyClassName="sticky-options">
                <Grid
                    container
                    spacing={{ xs: 1, lg: 2 }}
                    style={{
                        marginBottom: 30,
                    }}
                >
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Search
                            label="Search"
                            value={filters.search}
                            onChange={(event) =>
                                setFilters({ ...filters, search: event.target.value })
                            }
                            onSearch={onFilterLeads}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                        <DatePicker
                            label="Start Date"
                            value={filters.start_date}
                            handleChange={handleChangeStartDate}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                        <DatePicker
                            label="End Date"
                            value={filters.end_date}
                            handleChange={handleChangeEndDate}
                            style={{width: "100%"}}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        sm={6}
                        md={6}
                        lg={2}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <FormControl style={{ width: "100%" }} color="info">
                            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Table Actions"
                                onChange={(event) => {
                                    setActionValue(event.target.value);
                                }}
                                disabled={selectedLeads.length === 0}
                                value={actionValue}
                            >
                                <MenuItem
                                    onClick={() =>
                                        onOpenModal(
                                            modalTypes.addTags({
                                                tagId,
                                                assignTagId: (id) => handleAssignTag(id, selectedLeads),
                                            })
                                        )
                                    }
                                    value={"add"}
                                >
                                    Add Tag
                                </MenuItem>
                                <MenuItem
                                    value={"remove_tags"}
                                    onClick={() =>
                                        onOpenModal(
                                            modalTypes.removeTags({
                                                message: "remove tags",
                                                action: () => handleReAssignTags(tagId, selectedLeads),
                                            })
                                        )
                                    }
                                >
                                    Remove tags
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        onOpenModal(modalTypes.assignTo({action: (userId) => handleAssignToUser(userId, selectedLeads)}))
                                    }
                                    value={"assign"}
                                >
                                    Assign a User
                                </MenuItem>

                                <MenuItem
                                    onClick={() =>
                                        onOpenModal(
                                            modalTypes.unassignUser({
                                                message: "unassign leads from user",
                                                action: () =>  handleUnassignUser(selectedLeads),
                                            })
                                        )
                                    }
                                    value={"unassign"}
                                >
                                    User unassign
                                </MenuItem>
                                <MenuItem
                                    value={"export"}
                                    onClick={() =>
                                        onOpenModal(
                                            modalTypes.export({
                                                message: "he new Audience has been created successfully!",
                                                onSave: handleCreateAudience,
                                            })
                                        )
                                    }
                                >
                                    Export
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        onOpenModal(
                                            modalTypes.removeLead({
                                                message: "remove selected leads",
                                                action: () => handleRemoveLeads(selectedLeads),
                                            })
                                        )
                                    }
                                    value={"removeLeads"}
                                >
                                    Remove Leads
                                </MenuItem>
                                <MenuItem value={"actions"} style={{ display: "none" }}>
                                    Actions
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: 2,
                        }}
                    >
                        <Link to={routes.TAGS}>
                            <SettingsIcon />
                        </Link>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={5}
                        lg={2}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => onOpenModal(modalTypes.createSingleLead())}
                            style={{width: "100%"}}
                        >
                            Create Lead
                        </Button>
                    </Grid>
                </Grid>
            </Sticky>
            <LeadsList
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                orderBy={filters.ordering}
                handleRequestSort={handleRequestSort}
                setSelected={(leads) => setSelectedLeads(leads)}
                selected={selectedLeads}
                onClickTag={(leads) => {
                    onOpenModal(
                        modalTypes.addTags({
                            tagId,
                            assignTagId: (id) => handleAssignTag(id, leads),
                        })
                    );
                }}
                onClickAssign={(leads) => {
                    onOpenModal(
                        modalTypes.assignTo({
                            selectedLeads,
                            action: (id) => handleAssignToUser(id, leads)})
                    );
                }}
                handleReAssignTags={handleReAssignTags}
                handleReAssignUser={handleUnassignUser}
                leadsData={companies}
                count={count}
                isLeadPage
                onDeleteLead={(id) => {
                    onOpenModal(
                        modalTypes.removeLead({
                            message: "remove lead",
                            action: () => handleRemoveLeads([id]),
                        })
                    );
                }}
            />
        </>
    );
};

export default LeadsPage;
