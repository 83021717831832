import requests from "./base";



const User = {
  login: (data) => requests.post(`user/login/`, data),
  register: (data) => requests.post(`user/register/`, data),
  forgotPass: (data) => requests.post(`user/forgot-password/`, data),
  changePass: (data) => requests.put(`user/set-password/`, data),
  inviteTeamMember: (data) => requests.post(`user/invite-team-member/`, data),
  preliminaryCheck: (uuid) => requests.get(`user/preliminary-check/${uuid}/`),
  logout: (data) => requests.post(`user/logout/`, data),
  details: () => requests.get(`user/me/`),
  list: () => requests.get(`user/company-user/`),
  updateUser: (id, data) => requests.patch(`user/company-user/${id}/`, data),
  deleteUser: (id) => requests.del(`user/company-user/${id}/`),
  update: (data) => requests.patch(`user/me/edit/`, data),
  changePassMe: (data) => requests.patch(`user/me/change-password/`, data),
};

export default User;
