import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

const InvoiceDetails = () => {
  return (
    <Box sx={{ flexDirection: "column" }}>
      <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
        Print <PrintIcon />
      </Box>
      <Typography variant="h5">Invoice</Typography>
      <Typography>Charged on Wednesday, November 24, 2021 </Typography>
      <Divider
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
      />
      <Typography>ISSUED To</Typography>
      <Typography>Babymarket SAS</Typography>
      <Typography>Card Number **** **** **** 0721</Typography>
      <Typography>ISSUED BY</Typography>
      <Typography>NUKUK Thecnologies LLC</Typography>
      <Typography>1415 Coral Ridge Dr.</Typography>
      <Typography>Forth Lauderadale, FL</Typography>
      <Typography>33304</Typography>
      <Typography>CHARGES</Typography>
      <Typography>All prices in UDS dollar</Typography>
      <Divider
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Typography>Subscription:Business (Monthly)</Typography>
        <Typography>$199.00</Typography>
      </Box>
      <Divider
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Subtotal</Typography>
        <Typography>$199.00</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Discount</Typography>
        <Typography>-</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>Due</Typography>
        <Typography>$0.00</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>Paid</Typography>
        <Typography>$199.00</Typography>
      </Box>
    </Box>
  );
};
export default InvoiceDetails;
