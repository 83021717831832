const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "#",
    disabled: true,
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Audience Name",
    disabled: false,
  },
  {
    id: "size",
    numeric: true,
    disablePadding: false,
    label: "Size",
    disabled: false,
  },
  {
    id: "create_date",
    numeric: true,
    disablePadding: false,
    label: "Create Date",
    disabled: false,
  },
  {
    id: "last_update",
    numeric: true,
    disablePadding: false,
    label: "Last Update",
    disabled: false,
  },
  {
    id: "creator",
    numeric: true,
    disablePadding: false,
    label: "Creator",
    disabled: false,
  },
  {
    id: "action",
    label: "Action",
    disabled: true,
  },
];

export { headCells };
