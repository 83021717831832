import React from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const Search = (props) => {
  const { value, label, onSearch, onChange } = props;
  return (
    <div className="search">
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        fullWidth
        color="info"
        value={value}
        onChange={onChange}
      />
      <div className="icon" onClick={onSearch}>
        <SearchIcon />
      </div>
    </div>
  );
};
export default Search;
