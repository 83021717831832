import {
  SET_USER_REQUEST,
  SET_USER_SUCCEED,
  SET_USER_FAILURE,
  SET_LOGIN,
  SIGN_UP_USER_REQUEST,
  SIGN_UP_USER_SUCCEED,
  SIGN_UP_USER_FAILURE,
  FORGOT_PASS_USER_REQUEST,
  FORGOT_PASS_USER_SUCCEED,
  FORGOT_PASS_USER_FAILURE,
  SET_PASS_USER_REQUEST,
  SET_PASS_USER_SUCCEED,
  SET_PASS_USER_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCEED,
  INVITE_USER_FAILURE,
  PRELIMINARY_CHECK_USER_REQUEST,
  PRELIMINARY_CHECK_USER_SUCCEED,
  PRELIMINARY_CHECK_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCEED,
  UPDATE_USER_FAILURE,
  CHANGE_PASS_ME_REQUEST,
  CHANGE_PASS_ME_SUCCEED,
  CHANGE_PASS_ME_FAILURE,
  CHANGE_INVITE_USER_STATUS,
} from "constants/type";
import {
  singIn,
  singUp,
  forgotPassword,
  changePassword,
  inviteTeamMember,
  preliminaryCheck,
  logout,
  getUserDetails,
  updateInfo,
  changePassMe,
} from "vendor/services/authService";
import toast from "react-hot-toast";
import { setToken, clearStorage } from "../../helper/storage";
import routes from "../../../constants/routes";

export function setUser(values) {
  return async (dispatch) => {
    dispatch({ type: SET_USER_REQUEST });
    const result = await singIn(values);
    if (result?.status === 200) {
      await setToken(result.data.token.access, result.data.token.refresh);
      dispatch({
        type: SET_USER_SUCCEED,
        payload: result.data.user,
      });
    } else {
      dispatch({
        type: SET_USER_FAILURE,
        message: "No active account found with the given credentials",
      });
    }
  };
}

export function setLogin(isLogin) {
  return {
    type: SET_LOGIN,
    payload: isLogin,
  };
}

export function signUpUser(values) {
  return async (dispatch) => {
    dispatch({ type: SIGN_UP_USER_REQUEST });
    const result = await singUp(values);
    if (result?.status === 200) {
      dispatch({
        type: SIGN_UP_USER_SUCCEED,
        payload: result,
      });
    } else {
      dispatch({
        type: SIGN_UP_USER_FAILURE,
        message: "This Email field must be unique.",
      });
    }
  };
}
export function forgotPassUser(values) {
  return async (dispatch) => {
    dispatch({ type: FORGOT_PASS_USER_REQUEST });
    const result = await forgotPassword(values);
    if (result?.status === 200) {
      dispatch({
        type: FORGOT_PASS_USER_SUCCEED,
        payload: result,
      });
    } else {
      dispatch({
        type: FORGOT_PASS_USER_FAILURE,
        message: "User not found. Please enter correct email.",
      });
    }
  };
}
export function changePassUser(values) {
  return async (dispatch) => {
    dispatch({ type: SET_PASS_USER_REQUEST });
    const result = await changePassword(values);
    if (result?.status === 200) {
      setToken(result.data.token.access, result.data.token.refresh);
      dispatch({
        type: SET_PASS_USER_SUCCEED,
        payload: result,
      });
    } else {
      dispatch({
        type: SET_PASS_USER_FAILURE,
        message: result.data.password
          ? result.data.password.toString()
          : result.data.message,
      });
    }
  };
}
export function sendInvitationTeamMember(values) {
  return async (dispatch) => {
    dispatch({ type: INVITE_USER_REQUEST });
    const result = await inviteTeamMember(values);
    if (result?.status === 200) {
      dispatch({
        type: INVITE_USER_SUCCEED,
        payload: result,
      });
    } else {
      dispatch({
        type: INVITE_USER_FAILURE,
        message: "This Email field must be unique.",
      });
    }
  };
}
export function changeInviteStatusToInitial() {
  return async (dispatch) => {
    dispatch({ type: CHANGE_INVITE_USER_STATUS });
  };
}
export function preliminaryCheckUser(values) {
  return async (dispatch) => {
    dispatch({ type: PRELIMINARY_CHECK_USER_REQUEST });
    const result = await preliminaryCheck(values);
    if (result?.status === 200) {
      dispatch({
        type: PRELIMINARY_CHECK_USER_SUCCEED,
        payload: result.data,
      });
    } else {
      dispatch({
        type: PRELIMINARY_CHECK_USER_FAILURE,
        message: "This Email field must be unique.",
      });
    }
  };
}
export function signOut(values, history) {
  return async (dispatch) => {
    const result = await logout(values);
    if (result?.status === 205) {
      clearStorage();
      history.push("/");
      dispatch({
        type: SET_LOGIN,
        payload: false,
      });
    } else {
      toast("Something went wrong, please try again.");
    }
    return result;
  };
}
export function fetchUserDetails() {
  return async (dispatch) => {
    dispatch({ type: SET_USER_REQUEST });
    const result = await getUserDetails();
    if (result?.status === 200) {
      return dispatch({
        type: SET_USER_SUCCEED,
        payload: result.data,
      });
    } else {
        clearStorage();
        window.location.href = routes.SIGNIN;
        dispatch({
          type: SET_USER_FAILURE,
          message: "No active account found with the given credentials",
        });
    }
  };
}
export function updateUserInfo(data) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    const result = await updateInfo(data);
    if (result?.status === 200) {
      dispatch({
        type: UPDATE_USER_SUCCEED,
        payload: result.data,
      });
    } else {
      dispatch({
        type: UPDATE_USER_FAILURE,
        message: "",
      });
    }
  };
}
export function changePassCurrentUser(data) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_PASS_ME_REQUEST });
    const result = await changePassMe(data);
    if (result?.status === 200) {
      toast.success("Your password has been changed successfully!");
      dispatch({
        type: CHANGE_PASS_ME_SUCCEED,
        payload: result.data,
      });
    } else {
      toast.error("Old password is incorrect. Please enter right password.");
      dispatch({
        type: CHANGE_PASS_ME_FAILURE,
        payload: result,
      });
    }
  };
}
