import { Sources } from "vendor/services/api/agent";

// get Sources
export const getSources = async (filters) => {
  try {
    const response = await Sources.list(filters);
    return response;
  } catch (error) {
    return error;
  }
};
// get one sourse
export const detials = async (sourceId) => {
  try {
    const response = await Sources.details(sourceId);
    return response;
  } catch (error) {
    return error;
  }
};
// update source
export const update = async (id, data) => {
  try {
    const response = await Sources.update(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const subscribeFields = async (token) => {
  try {
    const response = await Sources.subscribeFields(token);
    return response;
  } catch (error) {
    return error;
  }
};
