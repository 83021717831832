import * as Yup from "yup";
export const userSignUpValidation = () =>
  Yup.object({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("The e-mail address is not valid")
      .required("This field is required"),
  });

export const userSignUpInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
};
