import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ACTION_STATUSES } from "constants/type";
import { signUpUser } from "vendor/redux/actions";
import { withStyles } from "@mui/styles";
import { useFormik } from "formik";
import {
  signUpInitialValues,
  signUpValidation,
} from "vendor/services/yup-schemas/signUpValidation";
import { Paper, Grid, TextField, Button, Typography } from "@mui/material";
import { handleShowModal, modalTypes } from "components";
import styles from "./styles";
import routes from "../../constants/routes";
import {useHistory} from "react-router-dom";

const SignUp = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { message, status } = useSelector((state) => state.signup);
  const onSubmit = async (values) => {
    await dispatch(signUpUser(values));
  };


  const  {
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: signUpInitialValues,
    validationSchema: signUpValidation,
    onSubmit,


  });
  const submitListener = () => {
    if (status === ACTION_STATUSES.SUCCEED) {
      resetForm();
      handleShowModal(
          dispatch,
          modalTypes.successAlert({
            message:
                "Thank you! Our Admins check and send you email with next steps.",
          }),
          true
      );

    }
  };
  useEffect(submitListener, [status]);

  return (
      <div className="auth-pages">
        <form onSubmit={handleSubmit}>
          <Paper className={`${classes.paper} paper`}>
            <div className={classes.margin}>
              <Grid container sx={{ p: 1 }} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                  <Typography variant="h5" style={{textAlign: "center"}}> Sign Up </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="div" style={{textAlign: "center"}}>
                    Already have an account?
                    <Button
                        disableFocusRipple
                        disableRipple
                        style={{ textTransform: "none", marginLeft: "4px" }}
                        variant="text"
                        color="primary"
                        onClick={() => {
                          history.push(routes.SIGNIN);
                        }}
                    >

                      Sign in
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ p: 1 }} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                      label="First Name"
                      name="first_name"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      value={values.first_name}
                      required
                      variant="standard"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.first_name &&
                        Boolean(errors.first_name)
                      }
                      helperText={
                        touched.first_name && errors.first_name
                      }
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ p: 1 }} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                      label="Last Name"
                      name="last_name"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      required
                      value={values.last_name}
                      variant="standard"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.last_name && Boolean(errors.last_name)
                      }
                      helperText={
                        touched.last_name && errors.last_name
                      }
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ p: 1 }} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                      label="Company Name"
                      name="company_name"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      required
                      value={values.company_name}
                      variant="standard"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.company_name &&
                        Boolean(errors.company_name)
                      }
                      helperText={
                        touched.company_name && errors.company_name
                      }
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ p: 1 }} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                      label="Company Address"
                      name="company_address"
                      type="text"
                      autoComplete="off"
                      fullWidth
                      required
                      variant="standard"
                      onChange={handleChange}
                      value={values.company_address}
                      onBlur={handleBlur}
                      error={
                        touched.company_address &&
                        Boolean(errors.company_address)
                      }
                      helperText={
                        touched.company_address &&
                        errors.company_address
                      }
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ p: 1 }} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                      label="Email"
                      name="email"
                      type="email"
                      autoComplete="off"
                      fullWidth
                      required
                      value={values.email}
                      variant="standard"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                  />
                </Grid>
              </Grid>

              <Grid container justify="center" style={{ marginTop: "20px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none" }}
                    fullWidth
                    disabled={status === ACTION_STATUSES.PENDING}
                    type="submit"
                >
                  Sign up
                </Button>
                {message && <Typography className="error">{message}</Typography>}
              </Grid>
            </div>
          </Paper>
        </form>
      </div>
  );
};

export default withStyles(styles)(SignUp);
