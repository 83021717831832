import * as React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { handleShowModal } from "components";
import { changeInviteStatusToInitial } from "vendor/redux/actions";

export default function SuccessAlert({ message, setOpen }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    handleShowModal(dispatch, "", false);
    dispatch(changeInviteStatusToInitial());
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <CheckIcon color="success" fontSize="large" />
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Success
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
        {message}
      </Typography>
      <Button variant="contained" color="success" onClick={handleClose}>
        OK
      </Button>
    </Box>
  );
}
