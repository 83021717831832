import requests from "./base";

const Audiences = {
  list: (filters) =>
    requests.get(
      `audience/crud/?limit=${filters.limit}&offset=${filters.offset}&search=${
        filters.search
      }&ordering=${filters.ordering}${
        filters.start_date ? `&start_date=${filters.start_date}` : ""
      }${filters.end_date ? `&end_date=${filters.end_date}` : ""}`
    ),
  download: (audienceId) => requests.get(`audience/export/${audienceId}/`),
  create: (data) => requests.post(`audience/crud/`, data),
  multiple_remove: (data) =>
    requests.put(`audience/crud/multiple-delete/`, data),
  update: (audienceId, data) =>
    requests.patch(`audience/crud/${audienceId}/`, data),
  remove: (audienceId) => requests.del(`audience/crud/${audienceId}/`),
  details: (audienceId) => requests.get(`audience/crud/${audienceId}/`),
  leads: (audienceId, filters) =>
    requests.get(
      `audience/get-leads/${audienceId}/?limit=${filters.limit}&offset=${filters.offset}&ordering=${filters.ordering}`
    ),
  remove_leads: (audienceId, data) =>
    requests.put(`audience/crud/${audienceId}/remove-lead/`, data),
};

export default Audiences;
