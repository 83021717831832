import FacebookSdk from 'vendor/services/facebook';
import {
    GET_FACEBOOK_ADACCOUNTS_REQUEST,
    GET_FACEBOOK_ADACCOUNTS_SUCCEED,
    GET_FACEBOOK_ADACCOUNTS_FAILURE,
    GET_FACEBOOK_PROMOTEPAGES_REQUEST,
    GET_FACEBOOK_PROMOTEPAGES_SUCCEED,
    GET_FACEBOOK_PROMOTEPAGES_FAILURE,
    GET_FACEBOOK_LEADGENFORMS_REQUEST,
    GET_FACEBOOK_LEADGENFORMS_SUCCEED,
    GET_FACEBOOK_LEADGENFORMS_FAILURE,
    EXPORT_FACEBOOK_DATA_SUCCEED,
    EXPORT_FACEBOOK_DATA_REQUEST,
    EXPORT_FACEBOOK_DATA_FAILURE,
    SET_SELECTED_ADACCOUNT,
    SET_INITIAL,
    SET_SELECTED_PROMOTE_PAGE,
    SET_SELECTED_LEAD_FORMS,
    GET_FACEBOOK_USER_TOKEN_REQUEST,
    GET_FACEBOOK_USER_TOKEN_SUCCEED,
    GET_FACEBOOK_USER_TOKEN_FAILURE,
    REMOVE_AUDIENCE_FAILURE,
    REMOVE_PAGE_LIST, SUBSCRIBE_FIELDS_REQUEST, SUBSCRIBE_FIELDS_SUCCEED, SUBSCRIBE_FIELDS_FAILURE,
} from "../../../constants/type";
import store from "../store";
import {exportFbPages} from "../../services/leadsService";
import {subscribeFields} from "../../services/sourceService";

export function fetchAdAccounts() {
    return async (dispatch) => {
        dispatch({ type: GET_FACEBOOK_ADACCOUNTS_REQUEST });
        await FacebookSdk.checkLoginStatus();
        const result = await FacebookSdk.getAdAccounts();
        if (result.data) {
            dispatch({
                type: GET_FACEBOOK_ADACCOUNTS_SUCCEED,
                payload: result.data,
            });
        } else {
            dispatch({
                type: GET_FACEBOOK_ADACCOUNTS_FAILURE,
                message: "",
            });
        }
    };
}

export function fetchPromotePages(pageId) {
    return async (dispatch) => {
        dispatch({ type: GET_FACEBOOK_PROMOTEPAGES_REQUEST });
        await FacebookSdk.checkLoginStatus();
        const result = await FacebookSdk.getAdAccountsPromotePage(pageId);
        if (result.data) {
            dispatch({
                type: GET_FACEBOOK_PROMOTEPAGES_SUCCEED,
                payload: result.data,
                pageId: pageId,
            });
        } else {
            dispatch({
                type: GET_FACEBOOK_PROMOTEPAGES_FAILURE,
                message: "",
            });
        }
    };
}

export function fetchLeadForms(token, id) {
    return async (dispatch) => {
        dispatch({ type: GET_FACEBOOK_LEADGENFORMS_REQUEST });
        await FacebookSdk.checkLoginStatus();
        const result = await FacebookSdk.getLeadGenForms(token, id);
        if (result.data) {
            dispatch({
                type: GET_FACEBOOK_LEADGENFORMS_SUCCEED,
                payload: result.data,
                promoteId: id,
            });
        } else {
            dispatch({
                type: GET_FACEBOOK_LEADGENFORMS_FAILURE,
                message: "",
            });
        }
    };
}

export function changeLeadForm({accountId, pageId, formId}) {
    return async (dispatch) => {
        dispatch({ type: GET_FACEBOOK_ADACCOUNTS_REQUEST });
        await FacebookSdk.checkLoginStatus();
        const result = await FacebookSdk.getAdAccounts();
        if (result.data) {
            dispatch({
                type: GET_FACEBOOK_ADACCOUNTS_SUCCEED,
                payload: result.data,
            });
        } else {
            dispatch({
                type: GET_FACEBOOK_ADACCOUNTS_FAILURE,
                message: "",
            });
        }
    };
}

export function fetchUserLongLivedToken() {
    return async (dispatch) => {
        dispatch({ type: GET_FACEBOOK_USER_TOKEN_REQUEST });
        const result = await FacebookSdk.getUserLongLivedAccessToken();
        if (result.access_token) {
            dispatch({
                type: GET_FACEBOOK_USER_TOKEN_SUCCEED,
                payload: result.access_token,
            });
        } else {
            dispatch({
                type: GET_FACEBOOK_USER_TOKEN_FAILURE,
                message: "",
            });
        }
    };
}

export function exportFBData(pageList) {
    const company_id = store.getState().signin.user.company?.id;
    const apiData = {
        company_id: company_id+"",
        page_list: [pageList],
    }
    return async (dispatch) => {
        dispatch({ type: EXPORT_FACEBOOK_DATA_REQUEST });
        const result = await exportFbPages(apiData);
        if (result?.data) {
            dispatch({
                type: EXPORT_FACEBOOK_DATA_SUCCEED,
            });
        } else {
            dispatch({
                type: EXPORT_FACEBOOK_DATA_FAILURE,
                message: "",
            });
        }
    };
}

export function subscribeField(page_id, token) {
    return async (dispatch) => {
        dispatch({ type: SUBSCRIBE_FIELDS_REQUEST });
        const result = await FacebookSdk.subscribedApps(page_id, token);
        if (result) {
            dispatch({
                type: SUBSCRIBE_FIELDS_SUCCEED,
                payload: result.success,
            });
        } else {
            dispatch({
                type: SUBSCRIBE_FIELDS_FAILURE,
                message: "",
            });
        }
    };
}

export function setSelectedAdAccount(account) {
    return (dispatch) => {
        dispatch({ type: SET_SELECTED_ADACCOUNT, payload: account });
    };
}

export function setSelectedPromotePage(promotePage) {
    return (dispatch) => {
        dispatch({ type: SET_SELECTED_PROMOTE_PAGE, payload: promotePage });
    };
}

export function setSelectedLeadForm(forms) {
    return (dispatch) => {
        dispatch({ type: SET_SELECTED_LEAD_FORMS, payload: forms });
    };
}

export function setAccountInitial() {
    return (dispatch) => {
        dispatch({type: SET_INITIAL});
    };
}

export function removePageList() {
    return (dispatch) => {
        dispatch({type: REMOVE_PAGE_LIST});
    };
}


