import {
  ACTION_STATUSES,
  SIGN_UP_USER_REQUEST,
  SIGN_UP_USER_SUCCEED,
  SIGN_UP_USER_FAILURE,
} from "constants/type";

const initialState = {
  status: null,
  message: "",
};

const SignupReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SIGN_UP_USER_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case SIGN_UP_USER_SUCCEED:
      return {
        ...state,
        status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case SIGN_UP_USER_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    default:
      return state;
  }
};

export default SignupReducer;
