import { deepmerge } from "@mui/utils";
import { createTheme } from "@mui/material/styles";
const palette = {
  palette: {
    primary: {
      main: "#cb0c9f",
    },
    secondary: {
      main: "#8392ab",
    },
    info: {
      main: "#17c1e8",
    },
    success: {
      main: "#82d616",
    },
    error: {
      main: "#ea0606",
    },
    warning: {
      main: "#fbcf33",
    },
  },
};

const overrides = {
  MuiOutlinedInput: {
    root: {
      borderRadius: 10,
    },
  },
};

const theme = createTheme(deepmerge(overrides, palette));
export { theme };
