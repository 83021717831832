import {
  GET_SOURCES_REQUEST,
  GET_SOURCES_SUCCEED,
  GET_SOURCES_FAILURE,
  GET_SOURCE_DETAILS_REQUEST,
  GET_SOURCE_DETAILS_SUCCEED,
  GET_SOURCE_DETAILS_FAILURE,
  UPDATE_SOURCE_REQUEST,
  UPDATE_SOURCE_SUCCEED,
  UPDATE_SOURCE_FAILURE,
} from "constants/type";
import { getSources, detials, update } from "vendor/services/sourceService";
import toast from "react-hot-toast";

export function fetchSources(filters) {
  return async (dispatch) => {
    dispatch({ type: GET_SOURCES_REQUEST });
    const result = await getSources(filters);
    if (result?.status === 200) {
      dispatch({
        type: GET_SOURCES_SUCCEED,
        payload: result?.data,
      });
    } else {
      dispatch({
        type: GET_SOURCES_FAILURE,
        message: "",
      });
    }
  };
}
export function fetchSourceDetails(sourceId) {
  return async (dispatch) => {
    dispatch({ type: GET_SOURCE_DETAILS_REQUEST });
    const result = await detials(sourceId);
    if (result?.status === 200) {
      dispatch({
        type: GET_SOURCE_DETAILS_SUCCEED,
        payload: result?.data,
      });
    } else {
      dispatch({
        type: GET_SOURCE_DETAILS_FAILURE,
        message: "",
      });
    }
  };
}
export function updateSource(id, data) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SOURCE_REQUEST });
    const result = await update(id, data);
    if (result.status === 200) {
      toast.success("Source has been updated successfully!");
      dispatch({
        type: UPDATE_SOURCE_SUCCEED,
        payload: result.data,
      });
      return result;
    } else {
      dispatch({
        type: UPDATE_SOURCE_FAILURE,
        message: "error",
      });
    }
  };
}
