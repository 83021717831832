import {
  ACTION_STATUSES,
  EXPORT_FACEBOOK_PAGES_FAILURE,
  EXPORT_FACEBOOK_PAGES_SUCCEED,
  EXPORT_FACEBOOK_PAGES_REQUEST,
  GET_FACEBOOK_LEADS_FAILURE,
  GET_FACEBOOK_LEADS_SUCCEED,
  GET_FACEBOOK_LEADS_REQUEST,
  LEADS_ASSIGNTO_USER_REQUEST,
  LEADS_ASSIGNTO_USER_SUCCEED,
  LEADS_ASSIGNTO_USER_FAILURE,
  LEADS_UNASSIGN_FROM_USER_REQUEST,
  LEADS_UNASSIGN_FROM_USER_SUCCEED,
  LEADS_UNASSIGN_FROM_USER_FAILURE,
  ASSIGN_TAG_LEADS_REQUEST,
  ASSIGN_TAG_LEADS_SUCCEED,
  ASSIGN_TAG_LEADS_FAILURE,
  REASSIGN_TAG_LEADS_REQUEST,
  REASSIGN_TAG_LEADS_SUCCEED,
  REASSIGN_TAG_LEADS_FAILURE,
  IMPORT_LEADS_REQUEST,
  IMPORT_LEADS_SUCCEED,
  IMPORT_LEADS_FAILURE,
  CREATE_A_LEAD_REQUEST,
  CREATE_A_LEAD_SUCCEED,
  CREATE_A_LEAD_FAILURE,
  REMOVE_LEADS_REQUEST,
  REMOVE_LEADS_SUCCEED,
  REMOVE_LEADS_FAILURE,
} from "constants/type";

const initialState = {
  companies: [],
  status: null,
  message: "",
  count: 0,
  assign_status: null,
  reassign_status: null,
  remove_status: null,
  createStatus: null,
  removeStatus: null,
  assignTagStatus: null,
  reassignTagStatus: null,
  assignUser: null,
  reassignUser: null,
};

const leadsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case EXPORT_FACEBOOK_PAGES_REQUEST:
      return {
        ...state,
        export_facebook_pages: ACTION_STATUSES.PENDING,
        message: "",
      };
    case EXPORT_FACEBOOK_PAGES_SUCCEED:
      return {
        ...state,
        export_facebook_pages: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case EXPORT_FACEBOOK_PAGES_FAILURE:
      return {
        ...state,
        export_facebook_pages: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case GET_FACEBOOK_LEADS_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        assign_status: null,
        message: "",
      };
    case GET_FACEBOOK_LEADS_SUCCEED:
      return {
        ...state,
        companies: action.payload.results,
        count: action.payload.count,
        status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case GET_FACEBOOK_LEADS_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case LEADS_ASSIGNTO_USER_REQUEST:
      return {
        ...state,
        assignUser: ACTION_STATUSES.PENDING,
        message: "",
      };
    case LEADS_ASSIGNTO_USER_SUCCEED:
      return {
        ...state,
        message: "",
        assignUser: ACTION_STATUSES.SUCCEED,
      };
    case LEADS_ASSIGNTO_USER_FAILURE:
      return {
        ...state,
        message: action.message,
        assignUser: ACTION_STATUSES.FAILURE,
      };
    case LEADS_UNASSIGN_FROM_USER_REQUEST:
      return {
        ...state,
        reassignUser: ACTION_STATUSES.PENDING,
        message: "",
        assign_status: null,
      };
    case LEADS_UNASSIGN_FROM_USER_SUCCEED:
      return {
        ...state,
        message: "",
        reassignUser: ACTION_STATUSES.SUCCEED,
      };
    case LEADS_UNASSIGN_FROM_USER_FAILURE:
      return {
        ...state,
        message: action.message,
        reassignUser: ACTION_STATUSES.FAILURE,
      };
    case ASSIGN_TAG_LEADS_REQUEST:
      return {
        ...state,
        assignTagStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case ASSIGN_TAG_LEADS_SUCCEED:
      return {
        ...state,
        assignTagStatus: ACTION_STATUSES.SUCCEED,
        message: "",
        reassignTagStatus: null,
      };
    case ASSIGN_TAG_LEADS_FAILURE:
      return {
        ...state,
        assignTagStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case REASSIGN_TAG_LEADS_REQUEST:
      return {
        ...state,
        reassignTagStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case REASSIGN_TAG_LEADS_SUCCEED:
      return {
        ...state,
        message: "",
        reassignTagStatus: ACTION_STATUSES.SUCCEED,
      };
    case REASSIGN_TAG_LEADS_FAILURE:
      return {
        ...state,
        reassignTagStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case CREATE_A_LEAD_REQUEST:
      return {
        ...state,
        createStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case CREATE_A_LEAD_SUCCEED:
      return {
        ...state,
        message: "",
        createStatus: ACTION_STATUSES.SUCCEED,
      };
    case CREATE_A_LEAD_FAILURE:
      return {
        ...state,
        createStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case IMPORT_LEADS_REQUEST:
      return {
        ...state,
        createStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case IMPORT_LEADS_SUCCEED:
      return {
        ...state,
        message: "",
        createStatus: ACTION_STATUSES.SUCCEED,
      };
    case IMPORT_LEADS_FAILURE:
      return {
        ...state,
        createStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //remove
    case REMOVE_LEADS_REQUEST:
      return {
        ...state,
        removeStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case REMOVE_LEADS_SUCCEED:
      return {
        ...state,
        message: "",
        removeStatus: ACTION_STATUSES.SUCCEED,
      };
    case REMOVE_LEADS_FAILURE:
      return {
        ...state,
        removeStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    default:
      return state;
  }
};

export default leadsReducer;
