import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTag, fetchTags } from "vendor/redux/actions";
import { ACTION_STATUSES } from "constants/type";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Button,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LeadsTags = (props) => {
  const { reassign, reassignTagId, assignTagId } = props;
  const dispatch = useDispatch();
  const { tagList } = useSelector((state) => state.tag);
  const { status } = useSelector((state) => state.tag);
  const { user } = useSelector((state) => state.user);
  const [tagsName] = useState([]);
  const [show, setShow] = useState(false);

  const [title, setTitle] = useState("");
  const [tagId, setTagId] = useState(0);

  useEffect(() => {
    setTagId(props.tagId);
  }, [props.tagId]);
  const onClickTag = async () => {
    let result = await dispatch(
      createTag({
        title: title,
        company: user?.company?.id,
      })
    );
    if (result) {
      dispatch(fetchTags());
      setTitle("");
    }
  };

  const onChangeCheckBox = (event, tagId) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      setTagId(tagId);
      reassign ? reassignTagId(tagId) : assignTagId(tagId);
    } else {
      setTagId(0);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl sx={{ mt: 3, minWidth: 400, width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={tagsName}
                onChange={() => {}}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {tagList?.results?.map((tag) => {
                  return (
                    <MenuItem key={tag.id} value={tag.id}>
                      <Checkbox
                        checked={tag.id === tagId}
                        onClick={(event) => onChangeCheckBox(event, tag.id)}
                      />
                      <ListItemText primary={tag.title} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {!reassign && (
            <Grid item md={12}>
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setShow(!show)}
                >
                  Add New Tag
                </Button>
              </Grid>
            </Grid>
          )}

          {show && (
            <Grid item xs={12}>
              <div className="add-tag-input-wrapper">
                <TextField
                  autoFocus
                  margin="dense"
                  id="tag"
                  label="Create new tag"
                  type="text"
                  fullWidth
                  disabled={status === ACTION_STATUSES.PENDING}
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
                <div className="add-tag-input-wrapper__actions">
                  {status === ACTION_STATUSES.PENDING ? (
                    <CircularProgress color="success" size={25} />
                  ) : (
                    <div onClick={() => (title !== "" ? onClickTag() : null)}>
                      <CheckIcon color="success" />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default LeadsTags;
