import {
  ACTION_STATUSES,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCEED,
  GET_TAGS_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCEED,
  CREATE_TAG_FAILURE,
  REMOVE_TAG_REQUEST,
  REMOVE_TAG_SUCCEED,
  REMOVE_TAG_FAILURE,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_SUCCEED,
  UPDATE_TAG_FAILURE,
} from "constants/type";

const initialState = {
  tagList: [],
  status: null,
  message: "",
  remove_status: null,
  update_status: null,
  create_status: null,
};

const tagReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TAGS_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case GET_TAGS_SUCCEED:
      return {
        ...state,
        tagList: action.payload,
        status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case GET_TAGS_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case CREATE_TAG_REQUEST:
      return {
        ...state,
        create_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case CREATE_TAG_SUCCEED:
      return {
        ...state,
        create_status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case CREATE_TAG_FAILURE:
      return {
        ...state,
        create_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //remove tag
    case REMOVE_TAG_REQUEST:
      return {
        ...state,
        remove_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case REMOVE_TAG_SUCCEED:
      return {
        ...state,
        remove_status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case REMOVE_TAG_FAILURE:
      return {
        ...state,
        remove_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //update tag
    case UPDATE_TAG_REQUEST:
      return {
        ...state,
        update_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case UPDATE_TAG_SUCCEED:
      return {
        ...state,
        update_status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case UPDATE_TAG_FAILURE:
      return {
        ...state,
        update_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    default:
      return state;
  }
};

export default tagReducer;
