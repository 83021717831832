import axios from "axios";
import toast from "react-hot-toast";
import { getRefreshToken, getAccessToken } from "vendor/helper/storage";
import { Retry, RefreshTokenRetry } from "./refreshTokenRetry";
import { ACTION_STATUSES } from "constants/type";

const baseURL = process.env.REACT_APP_BASE_API_URL || "https://nukak-api.codebnb.me/api/";

axios.defaults.baseURL = baseURL;

const requestOnFullFilled = (config) => {
    const token = getAccessToken();
    if (token) config.headers.Authorization = `JWT ${token}`;
    return config;
};

const requestOnRejected = (error) => {
    return Promise.reject(error);
};

const responseOnRejected = async (error) => {
    if (RefreshTokenRetry.status === ACTION_STATUSES.PENDING) return;

    if (error.message === "Network Error" && !error.response) {
        toast.error("Network error - make sure API is running!");
    }

    if (error.response) {
        const {status, data} = error.response;
        if (status) {
            if (status === 401) {
                if (getRefreshToken()) {
                    return await Retry.run();
                } else return data;
            }
            if (status === 404) {
                return data;
            }
            if (status === 400) {
                return data;
            }
            if (status === 500) {
                toast.error("Server error - check the terminal for more info!");
            }
        }
    }

    throw error.response;
};

axios.interceptors.request.use(requestOnFullFilled, requestOnRejected);

axios.interceptors.response.use(undefined, responseOnRejected);

const responseBody = (response) => response;

const errorBody = (error) => {
    return error.response;
};

const requests = {
    get: (url) => axios.get(url).then(responseBody).catch(errorBody),
    post: (url, body) =>
        axios.post(url, body).then(responseBody).catch(errorBody),
    put: (url, body) => axios.put(url, body).then(responseBody).catch(errorBody),
    patch: (url, body) =>
        axios.patch(url, body).then(responseBody).catch(errorBody),
    del: (url) => axios.delete(url).then(responseBody),
    postForm: (url, file) => {
        let formData = new FormData();
        formData.append('file', file.file, );

        return axios
            .post(url, formData, {
                headers: {"Content-type": "multipart/form-data"},
            })
            .then(responseBody);
    }
};

export default requests;
