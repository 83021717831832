import {
  GET_AUDIENCES_REQUEST,
  GET_AUDIENCES_SUCCEED,
  GET_AUDIENCES_FAILURE,
  ACTION_STATUSES,
  EXPORT_AUDIENCES_REQUEST,
  EXPORT_AUDIENCES_SUCCEED,
  EXPORT_AUDIENCES_FAILURE,
  CREATE_AUDIENCE_REQUEST,
  CREATE_AUDIENCE_SUCCEED,
  CREATE_AUDIENCE_FAILURE,
  MULTIPLE_REMOVE_AUDIENCES_REQUEST,
  MULTIPLE_REMOVE_AUDIENCES_SUCCEED,
  MULTIPLE_REMOVE_AUDIENCES_FAILURE,
  UPDATE_AUDIENCE_REQUEST,
  UPDATE_AUDIENCE_SUCCEED,
  UPDATE_AUDIENCE_FAILURE,
  REMOVE_AUDIENCE_REQUEST,
  REMOVE_AUDIENCE_SUCCEED,
  REMOVE_AUDIENCE_FAILURE,
  GET_AUDIENCE_DETAILS_REQUEST,
  GET_AUDIENCE_DETAILS_SUCCEED,
  GET_AUDIENCE_DETAILS_FAILURE,
  GET_AUDIENCE_LEADS_REQUEST,
  GET_AUDIENCE_LEADS_SUCCEED,
  GET_AUDIENCE_LEADS_FAILURE,
  REMOVE_AUDIENCE_LEADS_REQUEST,
  REMOVE_AUDIENCE_LEADS_SUCCEED,
  REMOVE_AUDIENCE_LEADS_FAILURE,
} from "constants/type";

const initialState = {
  audiencesList: [],
  status: null,
  message: "",
  count: 0,
  export_status: null,
  createStatus: null,
  remove_multi_status: null,
  update_status: null,
  remove_status: null,
  audienceDetails: null,
  details_status: null,
  leads_status: null,
  remove_leads_status: null,
  leads: [],
  leadsCount: 0,
};

const audiencesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_AUDIENCES_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case GET_AUDIENCES_SUCCEED:
      return {
        ...state,
        status: ACTION_STATUSES.SUCCEED,
        audiencesList: action.payload.results,
        count: action.payload.count,
        message: "",
        update_status: null,
        remove_status: null,
      };
    case GET_AUDIENCES_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
        update_status: null,
        remove_status: null,
      };
    //details
    case GET_AUDIENCE_DETAILS_REQUEST:
      return {
        ...state,
        details_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case GET_AUDIENCE_DETAILS_SUCCEED:
      return {
        ...state,
        details_status: ACTION_STATUSES.SUCCEED,
        audienceDetails: action.payload,
        message: "",
      };
    case GET_AUDIENCE_DETAILS_FAILURE:
      return {
        ...state,
        details_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //leads
    case GET_AUDIENCE_LEADS_REQUEST:
      return {
        ...state,
        leads_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case GET_AUDIENCE_LEADS_SUCCEED:
      return {
        ...state,
        leads_status: ACTION_STATUSES.SUCCEED,
        leads: action.payload.results,
        leadsCount: action.payload.count,
        message: "",
      };
    case GET_AUDIENCE_LEADS_FAILURE:
      return {
        ...state,
        leads_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //export
    case EXPORT_AUDIENCES_REQUEST:
      return {
        ...state,
        export_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case EXPORT_AUDIENCES_SUCCEED:
      return {
        ...state,
        export_status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case EXPORT_AUDIENCES_FAILURE:
      return {
        ...state,
        export_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //create
    case CREATE_AUDIENCE_REQUEST:
      return {
        ...state,
        createStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case CREATE_AUDIENCE_SUCCEED:
      return {
        ...state,
        createStatus: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case CREATE_AUDIENCE_FAILURE:
      return {
        ...state,
        createStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //remove mutiple audiences
    case MULTIPLE_REMOVE_AUDIENCES_REQUEST:
      return {
        ...state,
        remove_multi_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case MULTIPLE_REMOVE_AUDIENCES_SUCCEED:
      return {
        ...state,
        remove_multi_status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case MULTIPLE_REMOVE_AUDIENCES_FAILURE:
      return {
        ...state,
        remove_multi_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //update audiences
    case UPDATE_AUDIENCE_REQUEST:
      return {
        ...state,
        update_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case UPDATE_AUDIENCE_SUCCEED:
      return {
        ...state,
        update_status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case UPDATE_AUDIENCE_FAILURE:
      return {
        ...state,
        update_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //remove audiences
    case REMOVE_AUDIENCE_REQUEST:
      return {
        ...state,
        remove_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case REMOVE_AUDIENCE_SUCCEED:
      return {
        ...state,
        remove_status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case REMOVE_AUDIENCE_FAILURE:
      return {
        ...state,
        remove_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    //remove leads of audience
    case REMOVE_AUDIENCE_LEADS_REQUEST:
      return {
        ...state,
        remove_leads_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case REMOVE_AUDIENCE_LEADS_SUCCEED:
      return {
        ...state,
        remove_leads_status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case REMOVE_AUDIENCE_LEADS_FAILURE:
      return {
        ...state,
        remove_leads_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    default:
      return state;
  }
};

export default audiencesReducer;
