import requests from "./base";

const Tags = {
  list: () => requests.get(`tag/`),
  create: (data) => requests.post(`tag/`, data),
  remove: (id) => requests.del(`tag/${id}/`),
  update: (id, data) => requests.patch(`tag/${id}/`, data),
};

export default Tags;
