import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, Link, useParams } from "react-router-dom";

const BreadcrumbsShower = (props) => {
  const { breadcrumb } = props;
  const location = useLocation();
  const params = useParams();
  return (
      <Breadcrumbs
          mt={2}
          mb={2}
          className="breadcrumbs"
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
      >
        {breadcrumb.map((item, index) =>
            location.pathname === item.path || item.isEnd ? (
                <Typography key={item.path + index}>{item.name}</Typography>
            ) : item.isParam ? (
                <Typography key={item.path + index}>{params.name}</Typography>
            ) : (
                <Link
                    underline="hover"
                    color="inherit"
                    to={item.path}
                    key={item.path + index}
                >
                  {item.name}
                </Link>
            )
        )}
      </Breadcrumbs>
  );
};

export default BreadcrumbsShower;
