import { Charts } from "vendor/services/api/agent";

// getLeadsData
export const getLeadsData = async () => {
  try {
    const response = await Charts.leads();
    return response;
  } catch (error) {
    return error;
  }
};
// getDashboardData
export const getDashboardData = async () => {
  try {
    const response = await Charts.dashboard();
    return response;
  } catch (error) {
    return error;
  }
};
