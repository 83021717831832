import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import { Grid, Typography } from "@mui/material";
import { Facebook, LinkedIn, Google } from "@mui/icons-material";
import { envelopeIcon } from "assets";
import {handleShowModal, Loading, modalTypes} from "../../components";
import {fetchAdAccounts, fetchUserLongLivedToken} from "../../vendor/redux/actions";
import {useEffect} from "react";
import {ACTION_STATUSES} from "../../constants/type";

export default function SourcesAdd() {
  const dispatch = useDispatch();
  const {page_list, ad_account_status} = useSelector(state => state.facebookLeadsExport)
  const onOpenModal = () => handleShowModal(dispatch, modalTypes.facebookConnect(), true);

  const handleInit = async () => {
    await dispatch(fetchAdAccounts());
    await dispatch(fetchUserLongLivedToken());
  };

  useEffect(() => {if(page_list.length) {onOpenModal()}} ,[page_list]);

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        marginBottom: 30,
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Typography variant="h5">List of Sources</Typography>
      </Grid>
      <div className="sources-list">
        <div className="source__icons">
          <Facebook
            color="info"
            onClick={handleInit}
          />
          <Typography>Facebook lead forms</Typography>
        </div>
  {/*      <div className="source__icons">
          <Google />
          <Typography>Google Forms</Typography>
        </div>
        <div className="source__icons">
          <LinkedIn />
          <Typography>Linkedin Forms</Typography>
        </div>
        <div className="source__icons">
          <img src={envelopeIcon} alt="nukuk" />
          <Typography>TikTok Forms</Typography>
        </div>
        <div className="source__icons">
          <img src={envelopeIcon} alt="nukuk" />
          <Typography>TikTok Forms</Typography>
        </div>*/}
      </div>
      {ACTION_STATUSES.PENDING === ad_account_status && <Loading />}
    </Grid>
  );
}
