import {
  ACTION_STATUSES,
  FORGOT_PASS_USER_REQUEST,
  FORGOT_PASS_USER_SUCCEED,
  FORGOT_PASS_USER_FAILURE,
  SET_PASS_USER_REQUEST,
  SET_PASS_USER_SUCCEED,
  SET_PASS_USER_FAILURE,
  PRELIMINARY_CHECK_USER_REQUEST,
  PRELIMINARY_CHECK_USER_SUCCEED,
  PRELIMINARY_CHECK_USER_FAILURE,
} from "constants/type";

const initialState = {
  isLoging: localStorage.getItem("jwt-access-token") ? true : false,
  user: {},
  status: null,
  message: "",
  preliminaryCheckDetails: null,
};

const AuthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FORGOT_PASS_USER_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case FORGOT_PASS_USER_SUCCEED:
      return {
        ...state,
        status: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case FORGOT_PASS_USER_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case SET_PASS_USER_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case SET_PASS_USER_SUCCEED:
      return {
        ...state,
        user: action.payload,
        status: ACTION_STATUSES.SUCCEED,
        message: "",
        isLoging: true,
      };
    case SET_PASS_USER_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case PRELIMINARY_CHECK_USER_REQUEST:
      return {
        ...state,
      };
    case PRELIMINARY_CHECK_USER_SUCCEED:
      return {
        ...state,
        preliminaryCheckDetails: action.payload,
      };
    case PRELIMINARY_CHECK_USER_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default AuthReducer;
