import React, { useState } from "react";
import {
    Box,
    AppBar,
    Toolbar,
    CssBaseline,
    Divider,
    IconButton
} from "@mui/material";
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import {logo} from "../../assets";
import routes from "../../constants/routes";
import {useHistory} from "react-router-dom";
import SideBarMenu from "./SideBarMenu";
import Header from "./Header";
import { styled, useTheme } from "@mui/material/styles";
import BreadcrumbsShower from "./BreadcrumbsShower";
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,

    '& ul' : {
        display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerDesktop = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));


const DashboardLayout = (props) => {
    const { children, breadcrumb } = props;
    const { window } = props;
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [openMobile, setOpenMobile] = useState(false);
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleDrawerToggleMobile = () => {
        setOpenMobile(!openMobile)
    };

    const drawer = (
        <div>
            <DrawerHeader style={{ height: 80 }}>
                {
                    open &&  <img
                        src={logo}
                        alt="nukak"
                        className="main-logo"
                        onClick={() => {
                            history.push(routes.DASHBOARD);
                        }}
                    />
                }
                <IconButton onClick={matches ? handleDrawerToggle : handleDrawerToggleMobile}>
                    {
                        !open ? <ChevronRightIcon /> : <ChevronLeftIcon/>
                    }
                </IconButton>
            </DrawerHeader>
            <Divider />
            <SideBarMenu open={open} />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggleMobile}
                        sx={{ mr: "36px", }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Header open={open} />
                </Toolbar>
            </AppBar>
            {matches && <DrawerDesktop
                variant={"permanent"}
                sx={{
                    display: {sm: 'none', md: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
                open={open}
                className={!open && "drawer-closed"}
            >
                {drawer}
            </DrawerDesktop>}
            <Drawer
                container={container}
                variant="temporary"
                open={openMobile}
                onClose={handleDrawerToggleMobile}
                hideBackdrop={false}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', },
                }}
            >
                {drawer}
            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3 }}
                style={{ width: `calc(100% - ${drawerWidth}px)` }}
            >
                <DrawerHeader />
                <BreadcrumbsShower breadcrumb={breadcrumb} />
                {children}
            </Box>
        </Box>
    );
};




export default DashboardLayout
