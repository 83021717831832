import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_STATUSES } from "constants/type";
import { Button, Grid, TextField } from "@mui/material";
import { handleShowModal } from "../common/modal/handleShowModal";

const LeadsAudiences = ({ onSave }) => {
  const dispatch = useDispatch();
  const { create_status } = useSelector((state) => state.audiences);
  const [name, setName] = useState("");
  const onClose = () => {
    handleShowModal(dispatch, "", false);
  };
  // const onClose = handleShowModal(dispatch, "", false);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            margin="dense"
            id="tag"
            label="Audience Name"
            type="text"
            fullWidth
            disabled={create_status === ACTION_STATUSES.PENDING}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="space-between"
        justifyContent="space-between"
        style={{ marginTop: 15 }}
      >
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => onSave(name)}
          variant="contained"
          disabled={name === "" || create_status === ACTION_STATUSES.PENDING}
        >
          Save
        </Button>
      </Grid>
    </>
  );
};
export default LeadsAudiences;
