import React from "react";
import { Typography, Button, Grid, Container } from "@mui/material";
import { useHistory } from "react-router-dom";
import routes from "constants/routes";
const NotFound = () => {
  const history = useHistory();
  return (
    <section>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Typography variant="h1" component="h4" gutterBottom>
            404
          </Typography>
          <Typography variant="h3" component="h3" gutterBottom>
            Look like you're lost
          </Typography>
          <Typography variant="subtitle1" gutterBottom component="div">
            the page you are looking for not avaible!
          </Typography>
          <Button variant="outlined" onClick={() => history.push(routes.APP)}>
            Go to Home
          </Button>
        </Grid>
      </Container>
    </section>
  );
};
export default NotFound;
