import toast from "react-hot-toast";
import 'react-toastify/dist/ReactToastify.css';

const APP_ID = process.env.REACT_APP_ENV_FACEBOOK_APP_ID;
const APP_SECRET = process.env.REACT_APP_ENV_FACEBOOK_APP_SECRET;

class Facebook {
    static loginStatus = null;
    static userData = null;
    static pageData = null;
    static apiData = null;
    static userLongLifeToken = null;
    static pageLongLifeToken = null;
    static adaccounts = null;
    static leadgen = null;

    static checkFB() {
        if (!window.FB) {
            toast("Please wait until fb will loaded!");
            return false
        }
        return true;
    }

    static init() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : APP_ID,
                xfbml      : true,
                version    : 'v12.0'
            });
        };

        (function(d, s, id){
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        return false;
    }

    static async getAdAccounts() {
        return new Promise((res, rej) => {
            return window.FB.api(`/me/adaccounts?fields=amount_spent,id,name,status_code`, response => {
                this.adaccounts = response.data;
                return res(response);
            });
        })
    }

    static async getAdAccountsPromotePage(id) {
        return new Promise((res, rej) => {
            return window.FB.api(`/${id}/promote_pages?fields=id,name,access_token`, {
                access_token: this.userLongLifeToken
            }, response => {
                this.promotePages = response?.data;

                return res(response);
            });
        })
    }

    static async getLeadGenForms(page_access_token, page_id) {
        return new Promise((res, rej) => {
            return window.FB.api(`/${page_id}/leadgen_forms?fields=leads_count,expired_leads_count,name,status,id`,
                {access_token: page_access_token}, response => {
                this.leadgen = response?.data;
                return res(response);
            });
        })
    }

    static async getUserLongLivedAccessToken() {
        return new Promise((res, rej) => {
            return window.FB.api(`/oauth/access_token?grant_type=fb_exchange_token`, {
                fb_exchange_token : this.userData?.accessToken || this.loginStatus?.accessToken,
                client_id: APP_ID,
                client_secret: APP_SECRET,
            }, response => {
                this.userLongLifeToken = response.access_token;
                return res(response);
            });
        })
    }

    static async getPageLongLivedAccessToken() {
        return new Promise((res, rej) => {
            return window.FB.api(`/${this.loginStatus.userID}/accounts?access_token=${this.userLongLifeToken}`, response => {
                return res(response?.data);
            });
        })
    }

    static async checkLoginStatus() {
        const loaded = this.checkFB();
        if (!loaded) return;

        return new Promise((res, rej) => {
            return window.FB.getLoginStatus(response => {
                this.loginStatus = response.authResponse;

                if (response.status !== 'connected') {
                    return res(this.login());
                } else {
                    return res(this.getUserLongLivedAccessToken());
                }
            });
        })
    }

    static async login() {
        const loaded = this.checkFB();
        if (!loaded) return;

        return new Promise((res, rej) => {
            return window.FB.login(response => {
                this.userData = response.authResponse;

                if (response.status === 'connected') {
                    return res(this.getUserLongLivedAccessToken())
                } else {
                    return res(response)
                }
            }, {
                scope: 'email,business_management,pages_manage_ads,pages_show_list,pages_read_engagement,public_profile,ads_management,leads_retrieval',
                return_scopes: true
            });
        })
    }

    static async logOut() {
        const loaded = this.checkFB();
        if (!loaded) return;

        return new Promise((res, rej) => {
            return window.FB.logout(response => {
                return res(toast.success("Successfully logout from facebook!"));
            });
        })
    }

    static async subscribedApps(page_id, token) {
       return new Promise((res, rej) => {
           return window.FB.api(`/${page_id}/subscribed_apps?subscribed_fields=leadgen`, {"access_token": token}, "POST", response => {
               return res(response);
           });
       })
    }
}

export default Facebook;

