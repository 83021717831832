import {
  ACTION_STATUSES,
  EXPORT_FACEBOOK_DATA_FAILURE,
  EXPORT_FACEBOOK_DATA_REQUEST,
  EXPORT_FACEBOOK_DATA_SUCCEED,
  GET_FACEBOOK_ADACCOUNTS_FAILURE,
  GET_FACEBOOK_ADACCOUNTS_REQUEST,
  GET_FACEBOOK_ADACCOUNTS_SUCCEED,
  GET_FACEBOOK_LEADGENFORMS_FAILURE,
  GET_FACEBOOK_LEADGENFORMS_REQUEST,
  GET_FACEBOOK_LEADGENFORMS_SUCCEED,
  GET_FACEBOOK_PROMOTEPAGES_FAILURE,
  GET_FACEBOOK_PROMOTEPAGES_REQUEST,
  GET_FACEBOOK_PROMOTEPAGES_SUCCEED,
  GET_FACEBOOK_USER_TOKEN_FAILURE,
  GET_FACEBOOK_USER_TOKEN_REQUEST,
  GET_FACEBOOK_USER_TOKEN_SUCCEED,
  REMOVE_PAGE_LIST,
  SET_INITIAL,
  SET_SELECTED_ADACCOUNT,
  SET_SELECTED_LEAD_FORMS,
  SET_SELECTED_PROMOTE_PAGE,
  SUBSCRIBE_FIELDS_FAILURE,
  SUBSCRIBE_FIELDS_REQUEST,
  SUBSCRIBE_FIELDS_SUCCEED,
} from "../../../constants/type";

const initialState = {
  page_list: [],
  promote_pages: null,
  page_lead_forms: null,
  selectedAccount: {},
  selectedPromotePage: {},
  selectedLeadForms: [],
  userLongLivedToken: "",
  ad_account_status: null,
  pages_status: null,
  forms_status: null,
  export_facebook_data_status: null,
  subscribe_status: null,
  subscribe_success: null,
}

const facebookLeadsExportReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_FACEBOOK_ADACCOUNTS_REQUEST:
      return {
        ...state,
        ad_account_status: ACTION_STATUSES.PENDING,
        promote_pages: null,
        page_lead_forms: null,
      };
    case GET_FACEBOOK_ADACCOUNTS_SUCCEED:
      return {
        ...state,
        ad_account_status: ACTION_STATUSES.SUCCEED,
        page_list: action.payload,
      };
    case GET_FACEBOOK_ADACCOUNTS_FAILURE:
      return {
        ...state,
        ad_account_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case GET_FACEBOOK_PROMOTEPAGES_REQUEST:
      return {
        ...state,
        pages_status: ACTION_STATUSES.PENDING,
        promote_pages: null,
        page_lead_forms: null,
        subscribe_success: null,
      };
    case GET_FACEBOOK_PROMOTEPAGES_SUCCEED:
      return {
        ...state,
        pages_status: ACTION_STATUSES.SUCCEED,
        promote_pages: action.payload,
        pageId: action.pageId,
      };
    case GET_FACEBOOK_PROMOTEPAGES_FAILURE:
      return {
        ...state,
        pages_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case GET_FACEBOOK_LEADGENFORMS_REQUEST:
      return {
        ...state,
        forms_status: ACTION_STATUSES.PENDING,
      };
    case GET_FACEBOOK_LEADGENFORMS_SUCCEED:
      return {
        ...state,
        forms_status: ACTION_STATUSES.SUCCEED,
        page_lead_forms: action.payload,
        promoteId: action.promoteId,
      };
    case GET_FACEBOOK_LEADGENFORMS_FAILURE:
      return {
        ...state,
        forms_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case EXPORT_FACEBOOK_DATA_REQUEST:
      return {
        ...state,
        export_facebook_data_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case EXPORT_FACEBOOK_DATA_SUCCEED:
      return {
        ...state,
        companyUserList: action.payload,
        export_facebook_data_status: ACTION_STATUSES.SUCCEED,
      };
    case EXPORT_FACEBOOK_DATA_FAILURE:
      return {
        ...state,
        export_facebook_data_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case GET_FACEBOOK_USER_TOKEN_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        promote_pages: null,
        page_lead_forms: null,
      };
    case GET_FACEBOOK_USER_TOKEN_SUCCEED:
      return {
        ...state,
        userLongLivedToken: action.payload,
      };
    case GET_FACEBOOK_USER_TOKEN_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case SUBSCRIBE_FIELDS_REQUEST:
      return {
        ...state,
        subscribe_status: ACTION_STATUSES.PENDING,
      };
    case SUBSCRIBE_FIELDS_SUCCEED:
      return {
        ...state,
        subscribe_status: ACTION_STATUSES.PENDING,
        subscribe_success: action.payload,
      };
    case SUBSCRIBE_FIELDS_FAILURE:
      return {
        ...state,
        subscribe_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case SET_SELECTED_ADACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload,
      };
    case SET_SELECTED_PROMOTE_PAGE:
      return {
        ...state,
        selectedPromotePage: action.payload,
      };
    case SET_SELECTED_LEAD_FORMS:
      return {
        ...state,
        selectedLeadForms: action.payload,
      };
    case SET_INITIAL:
      return {
        ...state,
        selectedAccount: {},
        selectedPromotePage: {},
        selectedLeadForms: [],
      };
    case REMOVE_PAGE_LIST:
      return {
        ...state,
        page_list: [],
      };
    default:
      return state;
  }
};

export default facebookLeadsExportReducer;

