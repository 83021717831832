import {
  GET_AUDIENCES_REQUEST,
  GET_AUDIENCES_SUCCEED,
  GET_AUDIENCES_FAILURE,
  EXPORT_AUDIENCES_REQUEST,
  EXPORT_AUDIENCES_SUCCEED,
  EXPORT_AUDIENCES_FAILURE,
  CREATE_AUDIENCE_REQUEST,
  CREATE_AUDIENCE_SUCCEED,
  CREATE_AUDIENCE_FAILURE,
  MULTIPLE_REMOVE_AUDIENCES_REQUEST,
  MULTIPLE_REMOVE_AUDIENCES_SUCCEED,
  MULTIPLE_REMOVE_AUDIENCES_FAILURE,
  UPDATE_AUDIENCE_REQUEST,
  UPDATE_AUDIENCE_SUCCEED,
  UPDATE_AUDIENCE_FAILURE,
  REMOVE_AUDIENCE_REQUEST,
  REMOVE_AUDIENCE_SUCCEED,
  REMOVE_AUDIENCE_FAILURE,
  GET_AUDIENCE_DETAILS_REQUEST,
  GET_AUDIENCE_DETAILS_SUCCEED,
  GET_AUDIENCE_DETAILS_FAILURE,
  GET_AUDIENCE_LEADS_REQUEST,
  GET_AUDIENCE_LEADS_SUCCEED,
  GET_AUDIENCE_LEADS_FAILURE,
  REMOVE_AUDIENCE_LEADS_REQUEST,
  REMOVE_AUDIENCE_LEADS_SUCCEED,
  REMOVE_AUDIENCE_LEADS_FAILURE,
} from "constants/type";
import {
  getAudiences,
  download,
  create,
  multipleRemove,
  update,
  remove,
  details,
  leads,
  removeLeads,
} from "vendor/services/audiencesService";

export function fetchAllAudiences(filters) {
  return async (dispatch) => {
    dispatch({ type: GET_AUDIENCES_REQUEST });
    const result = await getAudiences(filters);
    if (result?.status === 200) {
      dispatch({
        type: GET_AUDIENCES_SUCCEED,
        payload: result.data,
      });
    } else {
      dispatch({
        type: GET_AUDIENCES_FAILURE,
        message: "",
      });
    }
  };
}
export function exportAudiences(audienceId) {
  return async (dispatch) => {
    dispatch({ type: EXPORT_AUDIENCES_REQUEST });
    const result = await download(audienceId);
    if (result?.status === 200) {
      dispatch({
        type: EXPORT_AUDIENCES_SUCCEED,
        payload: result.data,
      });
      return result.data;
    } else {
      dispatch({
        type: EXPORT_AUDIENCES_FAILURE,
        message: "",
      });
    }
  };
}
export function createAudience(data) {
  return async (dispatch) => {
    dispatch({ type: CREATE_AUDIENCE_REQUEST });
    const result = await create(data);
    if (result?.status === 200) {
      dispatch({
        type: CREATE_AUDIENCE_SUCCEED,
        payload: result.data,
      });
      return result.data;
    } else {
      dispatch({
        type: CREATE_AUDIENCE_FAILURE,
        message: "",
      });
    }
  };
}
//remove multiple audiences
export function removeMutipleAudiences(data) {
  return async (dispatch) => {
    dispatch({ type: MULTIPLE_REMOVE_AUDIENCES_REQUEST });
    const result = await multipleRemove(data);
    if (result?.status === 200) {
      dispatch({
        type: MULTIPLE_REMOVE_AUDIENCES_SUCCEED,
        payload: result.data,
      });
      return result.data;
    } else {
      dispatch({
        type: MULTIPLE_REMOVE_AUDIENCES_FAILURE,
        message: "",
      });
    }
  };
}
//update audiences
export function updateAudience(id, data) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_AUDIENCE_REQUEST });
    const result = await update(id, data);
    if (result?.status === 200) {
      dispatch({
        type: UPDATE_AUDIENCE_SUCCEED,
        payload: result.data,
      });
      return result.data;
    } else {
      dispatch({
        type: UPDATE_AUDIENCE_FAILURE,
        message: "",
      });
    }
  };
}
//remove audiences
export function removeAudience(id) {
  return async (dispatch) => {
    dispatch({ type: REMOVE_AUDIENCE_REQUEST });
    const result = await remove(id);
    if (result?.status === 200 || result?.status === 204) {
      dispatch({
        type: REMOVE_AUDIENCE_SUCCEED,
        payload: result.data,
      });
      return result.data;
    } else {
      dispatch({
        type: REMOVE_AUDIENCE_FAILURE,
        message: "",
      });
    }
  };
}
//get details of audience
export function fetchAudienceDetails(id) {
  return async (dispatch) => {
    dispatch({ type: GET_AUDIENCE_DETAILS_REQUEST });
    const result = await details(id);
    if (result?.status === 200 || result?.status === 204) {
      dispatch({
        type: GET_AUDIENCE_DETAILS_SUCCEED,
        payload: result.data,
      });
      return result.data;
    } else {
      dispatch({
        type: GET_AUDIENCE_DETAILS_FAILURE,
        message: "",
      });
    }
  };
}
//get leads of audience
export function fetchAudienceLeads(id, filters) {
  return async (dispatch) => {
    dispatch({ type: GET_AUDIENCE_LEADS_REQUEST });
    const result = await leads(id, filters);
    if (result?.status === 200) {
      dispatch({
        type: GET_AUDIENCE_LEADS_SUCCEED,
        payload: result.data,
      });
      return result.data;
    } else {
      dispatch({
        type: GET_AUDIENCE_LEADS_FAILURE,
        message: "",
      });
    }
  };
}
//remove leads of audience
export function removeAudienceLeads(id, data) {
  return async (dispatch) => {
    dispatch({ type: REMOVE_AUDIENCE_LEADS_REQUEST });
    const result = await removeLeads(id, data);
    if (result?.status === 200) {
      dispatch({
        type: REMOVE_AUDIENCE_LEADS_SUCCEED,
        payload: result.data,
      });
      return result.data;
    } else {
      dispatch({
        type: REMOVE_AUDIENCE_LEADS_FAILURE,
        message: "",
      });
    }
  };
}
