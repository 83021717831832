import {
    ACTION_STATUSES,
    GET_COMPANY_USERS_REQUEST,
    GET_COMPANY_USERS_SUCCEED,
    GET_COMPANY_USERS_FAILURE,
    DELETE_COMPANY_USER_REQUEST,
    DELETE_COMPANY_USER_SUCCEED,
    DELETE_COMPANY_USER_FAILURE,
    UPDATE_COMPANY_USER_REQUEST,
    UPDATE_COMPANY_USER_SUCCEED,
    UPDATE_COMPANY_USER_FAILURE,
} from "constants/type";

const initialState = {
    companyUserList: [],
    status: null,
    message: "",
    count: 0,
    remove_status: null,
    update_status: null,
    create_status: null,
};

const companyUsersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_COMPANY_USERS_REQUEST:
            return {
                ...state,
                status: ACTION_STATUSES.PENDING,
                message: "",
            };
        case GET_COMPANY_USERS_SUCCEED:
            return {
                ...state,
                companyUserList: action.payload,
                status: ACTION_STATUSES.SUCCEED,
                message: "",
            };
        case GET_COMPANY_USERS_FAILURE:
            return {
                ...state,
                status: ACTION_STATUSES.FAILURE,
                message: action.message,
            };
        case DELETE_COMPANY_USER_REQUEST:
            return {
                ...state,
                remove_status: ACTION_STATUSES.PENDING,
                message: "",
            };
        case DELETE_COMPANY_USER_SUCCEED:
            return {
                ...state,
                remove_status: ACTION_STATUSES.SUCCEED,
                message: "",
            };
        case DELETE_COMPANY_USER_FAILURE:
            return {
                ...state,
                remove_status: ACTION_STATUSES.FAILURE,
                message: action.message,
            };
        case UPDATE_COMPANY_USER_REQUEST:
            return {
                ...state,
                update_status: ACTION_STATUSES.PENDING,
                message: "",
            };
        case UPDATE_COMPANY_USER_SUCCEED:
            return {
                ...state,
                update_status: ACTION_STATUSES.SUCCEED,
                message: "",
            };
        case UPDATE_COMPANY_USER_FAILURE:
            return {
                ...state,
                update_status: ACTION_STATUSES.FAILURE,
                message: action.message,
            };
        default:
            return state;
    }
};

export default companyUsersReducer;
