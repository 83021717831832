import { useState } from "react";
import {Link, useHistory} from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Menu,
  Button,
  AppBar,
  Toolbar,
  MenuItem,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import routes from "constants/routes";
import { logo } from "assets";
import { HeaderRightPart } from "components";

const pages = [
  {
    name: "How it works",
    route: routes.HOW_IT_WORKS,
  },
  {
    name: "Pricing",
    route: routes.PRICING,
  },
];

const Header = () => {
  const history = useHistory();
  const { isLoging } = useSelector((state) => state.signin);
  const { user } = useSelector((state) => state.user);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <img
                src={logo}
                alt=""
                className="main-logo has-cursor"
                onClick={() =>
                  isLoging ? history.push(routes.APP) : null
                }
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => history.push(page.route)}
                  >
                    <Typography textAlign="center" color="#000">
                      {page.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <img
                src={logo}
                alt="NUKUK"
                className="main-logo has-cursor"
                onClick={() =>
                  isLoging ? history.push(routes.DASHBOARD) : null
                }
              />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                marginLeft: 2,
              }}
            >
              {pages.map((page) => (
                <Link
                  key={page.name}
                  onClick={() => history.push(page.route)}
                  sx={{
                    my: 2,
                    display: "block",
                    marginLeft: 5,
                    fontWeight: "600",
                  }}
                >
                  {page.name}
                </Link>
              ))}
            </Box>
            {isLoging ? (
              <HeaderRightPart
                user={user}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                anchorElNav={anchorElNav}
                setAnchorElNav={setAnchorElNav}
                handleMenu={handleMenu}
                handleClose={handleClose}
              />
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <Button
                  onClick={() => history.push(routes.SIGNIN)}
                  color="info"
                  variant="outlined"
                >
                  Login
                </Button>
                <Button
                  // sx={{ my: 2, color: "white", display: "block" }}
                  onClick={() => history.push(routes.SIGNUP)}
                  color="info"
                  variant="contained"
                  style={{ marginLeft: 10, color: "#ffffff" }}
                >
                  Register
                </Button>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
export default Header;
