import { Leads } from "vendor/services/api/agent";

// exportFbPages
export const exportFbPages = async (data) => {
  try {
    const response = await Leads.exportFbPages(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFbLeads = async (company_id, filters) => {
  try {
    const response = await Leads.getFbLeads(company_id, filters);
    return response;
  } catch (error) {
    return error;
  }
};
export const leadsAssignToUser = async (data) => {
  try {
    const response = await Leads.leadsAssignToUser(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const leadsUnassignUser = async (data) => {
  try {
    const response = await Leads.leadsUnassignUser(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const assignTags = async (data) => {
  try {
    const response = await Leads.assignTags(data);
    return response;
  } catch (error) {
    return error;
  }
};
export const importLeads = async (file) => {
  try {
    const response = await Leads.importLeads(file);
    return response;
  } catch (error) {
    return error;
  }
};
export const reAssignTags = async (data) => {
  try {
    const response = await Leads.reAssignTags(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createLead = async (data) => {
  try {
    const response = await Leads.createLead(data);
    return response;
  } catch (error) {
    return error;
  }
};
export const remove = async (data) => {
  try {
    const response = await Leads.remove(data);
    return response;
  } catch (error) {
    return error;
  }
};
