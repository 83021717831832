import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {Box, Button, Grid, TextField} from "@mui/material";
import {createLeadInitialValues, createLeadValidation} from "vendor/services/yup-schemas/createLeadValidation";
import {
  ACTION_STATUSES,
} from "constants/type";
import {createALead} from "vendor/redux/actions";
import {handleShowModal} from "../common/modal/handleShowModal";
import { format } from "date-fns";

const CreateSingleLead = () => {
  const dispatch = useDispatch();
  const {id} = useSelector(state => state.user.user.company);
  const { modalState } = useSelector(state => state.modals);
  const { createStatus } = useSelector(state => state.leads);

  const onSubmit = async (values) => {
    values.acquisition_date = format (new Date (), "yyyy-MM-dd")
    await dispatch(createALead(values));
    handleShowModal(dispatch, "", false);
  };

  const formik = useFormik({
    initialValues: createLeadInitialValues,
    validationSchema: createLeadValidation,
    onSubmit,
  });

  useEffect(() => {
    if(id) {
      formik.setValues({company: id})
    }
  },[id])

  return(
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="name"
              label="Name"
              type="text"
              variant="standard"
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="email"
              label="Email"
              type="email"
              variant="standard"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.email &&
                Boolean(formik.errors.email)
              }
              helperText={
                formik.touched.email &&
                formik.errors.email
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="country"
              label="Country"
              type="text"
              variant="standard"
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="phone"
              label="Phone"
              type="text"
              variant="standard"
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="date"
              label="Birthday"
              name="bd"
              type="date"
              variant="standard"
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="campaign"
              label="Campaign"
              type="text"
              variant="standard"
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="source"
              label="Source"
              type="text"
              variant="standard"
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                fullWidth
                name="form"
                label="Form"
                type="text"
                variant="standard"
                onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end" mt={4}>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              disabled={createStatus === ACTION_STATUSES.PENDING}
              type="submit"
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => handleShowModal(dispatch, "", false)}
              disabled={createStatus === ACTION_STATUSES.PENDING }
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default CreateSingleLead;