import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Toolbar,
  Typography,
  FormGroup,
  Checkbox,
  List,
  ListItem,
  Box,
  Button,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  exportFBData,
  fetchAdAccounts,
  fetchLeadForms,
  fetchPromotePages,
  fetchSources,
  fetchUserLongLivedToken,
  setAccountInitial,
  setSelectedAdAccount,
  setSelectedLeadForm,
  setSelectedPromotePage,
  subscribeField
} from "../../vendor/redux/actions";
import {handleShowModal, Loading} from "../common";
import {ACTION_STATUSES} from "../../constants/type";
import routes from "../../constants/routes";

const Connection = ({history}) => {
  const dispatch = useDispatch();
  const {
    page_list,
    promote_pages,
    pageId,
    page_lead_forms,
    promoteId,
    selectedAccount,
    selectedPromotePage,
    selectedLeadForms,
    userLongLivedToken,
    ad_account_status,
    export_facebook_data_status,
    subscribe_success,
  } = useSelector(state => state.facebookLeadsExport);
  const {sourcesList} = useSelector(state => state.sources);
  const [activePromotePage,setActivePromotePage] = useState(0);

  const handleInit = async () => {
    await dispatch(fetchAdAccounts());
    await dispatch(fetchUserLongLivedToken());
    await dispatch(fetchSources({
      limit: 10,
      offset: 0,
      search: "",
      ordering: "name",
    }));
    if(sourcesList[0]) {
      handleSelectAccount(sourcesList[0]?.ad_account_id);
    }
  };

  const handleSelectAccount = id => {
    setActivePromotePage(0);
    const account = {
      ...page_list.find(account => account.id === id),
      access_token: userLongLivedToken,
      promote_pages: []
    };
    !sourcesList[0] && dispatch(setAccountInitial());
    dispatch(setSelectedAdAccount(account));
    dispatch(fetchPromotePages(id));
  };

  const handleSelectPromotePage = (accessToken, id) => {
    const isActive = activePromotePage === id;
    setActivePromotePage(id);

    if (!isActive) {
      const promotePage = {...promote_pages.find(page => page.id === id)};
      dispatch(setSelectedLeadForm(selectedPromotePage.page_lead_forms || []))
      dispatch(setSelectedPromotePage(promotePage));
      dispatch(fetchLeadForms(accessToken, id));
    }
  };

  const newFormObject = form => {
    const {expired_leads_count, leads_count, ...newForm} = form;
    return newForm;
  }

  const handleSelectLeadForm = (id) => {
    const exists = selectedLeadForms.find(page => page.id === id)
    const forms = !exists ? [...selectedLeadForms, newFormObject(page_lead_forms.find(form => form.id === id))] :
        selectedLeadForms.filter(form => form.id !== id);
    dispatch(setSelectedLeadForm(forms));
  }

  const handleSave = () => {
    dispatch(exportFBData(selectedAccount));
  };

  useEffect(() => {
    if(export_facebook_data_status === ACTION_STATUSES.SUCCEED) {
      window.location.href = routes.LEADS;
      dispatch(setAccountInitial());
    }
  }, [export_facebook_data_status])

  const handleCancel = () => {
    handleShowModal(dispatch, "", false);
    dispatch(setAccountInitial());
  };

  const selectEffect = () =>  {
    if (promote_pages && page_list) {
      page_list.find(account => (account.id === pageId)).promote_pages = promote_pages;
    }
    if (promote_pages && page_lead_forms) {
      promote_pages.find(page => page.id === promoteId).page_lead_forms = page_lead_forms;
    }
    dispatch(setSelectedPromotePage({...selectedPromotePage, page_lead_forms: selectedLeadForms}));
  }

  const leadFormEffect = () => {
    const account = {
      ...selectedAccount,
      promote_pages: [{...selectedPromotePage, page_lead_forms: selectedLeadForms}]
    }
    dispatch(setSelectedAdAccount(account));
  }

  const pageSubscribtion = () => {
    if(selectedPromotePage.id && !subscribe_success) {
      dispatch(subscribeField(selectedPromotePage.id, selectedPromotePage.access_token));
    }
  }

  useEffect(handleInit, [sourcesList.length]);
  useEffect(selectEffect, [promote_pages, page_lead_forms, selectedLeadForms]);
  useEffect(leadFormEffect,[selectedLeadForms]);
  useEffect(pageSubscribtion, [selectedPromotePage]);

  return (
      <>
        {ACTION_STATUSES.PENDING === ad_account_status && <Loading />}
        {
          ACTION_STATUSES.PENDING === export_facebook_data_status &&
          <Box className="loading-text-box">
            <p>Please wait while we are getting your data.</p>
            <Loading/>
          </Box>
        }
        <Box className="connection-modal-content">
          <Box className={selectedAccount.id? "content-left" : "content-left without-campaigns"}>
            <Toolbar style={{paddingLeft: "0"}}>
              {sourcesList[0]?
                <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
                  {sourcesList[0].ad_account_name}
                </Typography> :
                <>
                  <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleCancel}>
                    <ChevronLeftIcon style={{fontSize: "50px"}} />
                  </IconButton>
                  <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
                    Select page
                  </Typography>
                </>
              }
            </Toolbar>
            {!sourcesList[0] &&
              <FormControl component="fieldset" style={{marginTop: "32px"}}>
                <RadioGroup
                    aria-label="names"
                    // defaultValue="name1"
                    name="radio-buttons-group"
                    onChange={event => handleSelectAccount(event.target.value)}
                >
                  {page_list?.map(item => {
                    return (
                        <>
                          <FormControlLabel value={item.id} control={<Radio/>} label={item.name}/>
                        </>
                    )
                  })}
                </RadioGroup>
              </FormControl>
            }
          </Box>
          {promote_pages &&
          <Box className="content-right">
            <div>
              <h2>
                {promote_pages.length? "Select account" : "No Campaigns"}
              </h2>
              <List className="list-checkbox">
                {promote_pages?.map(page => {
                  return (
                      <>
                        <ListItem>
                          <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={activePromotePage === page.id} onChange={() => handleSelectPromotePage(page.access_token, page.id)}/>}
                                className="label-checkbox"
                                label={page.name}/>
                          </FormGroup>
                        </ListItem>
                      </>
                  )
                })}
              </List>
            </div>
            {page_lead_forms &&
            <div>
              <h2>
                {page_lead_forms.length? "Select Forms": "No Form"}
              </h2>
              <List className="list-checkbox">
                {page_lead_forms?.map(page => {
                  const imported = Boolean(sourcesList[0]?.page_forms?.find(form => form.lead_id === page.id*1));

                  return (
                    <>
                      <ListItem>
                        <FormGroup>
                          <FormControlLabel
                            disabled={imported}
                            control={
                              <Checkbox
                                  checked={selectedLeadForms.find(p => p.id === page.id) || imported}
                                  onChange={() => handleSelectLeadForm(page.id)} />}
                            className="label-checkbox"
                            label={page.name}
                          />
                        </FormGroup>
                      </ListItem>
                    </>
                  )
                })}
              </List>
            </div>
            }
            <Box className="action-buttons">
              {Boolean(selectedLeadForms.length) &&
              <Button
                  style={{marginRight: "20px"}}
                  variant="contained"
                  onClick={handleSave}
              >
                Save
              </Button>
              }
              <Button
                  variant="outlined"
                  onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
          }
        </Box>
      </>
  );
}

export default Connection;