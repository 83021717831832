import axios from "axios";
import store from 'vendor/redux/store/index';
import { fetchUserDetails } from "vendor/redux/actions/authActions";
import { clearStorage, getRefreshToken, setToken } from "vendor/helper/storage";
import { ACTION_STATUSES } from "constants/type";

class RefreshTokenRetry {
  refreshUrl = "user/refresh/";
  signInUrl = "/signin";

  axiosConfig = {
    baseURL:
      process.env.REACT_APP_BASE_API_URL || "https://nukak-api.codebnb.me/api/",
    timeout: 1000,
  };
  axios = axios.create(this.axiosConfig);

  apiResponse;

  static status = ACTION_STATUSES.RESET;

  async run() {
    if (RefreshTokenRetry.status === ACTION_STATUSES.PENDING) return;
    try {
      RefreshTokenRetry.status = ACTION_STATUSES.PENDING;
      this.apiResponse = await this.makeCall();
      if (!this.apiResponse) return this.logout()
      this.setTokens();

    } catch (e) {
      this.logout();
    }
  }

   async makeCall() {
    return await axios.post(this.refreshUrl, {
      refresh: getRefreshToken(),
    });
  }

  async setTokens() {
    await setToken(this.apiResponse.data.access, this.apiResponse.data.refresh);
    RefreshTokenRetry.status = ACTION_STATUSES.SUCCEED;
    store.dispatch(fetchUserDetails())
  }

  logout() {
    clearStorage();
    window.location.href = this.signInUrl;
  }
}

const Retry = new RefreshTokenRetry();

export {
  Retry,
  RefreshTokenRetry
}

