import React from "react";
import {Typography, Grid, Link, List, ListItem, Container} from "@mui/material";
import {Facebook, LinkedIn, Instagram, Twitter} from "@mui/icons-material";

import { logo } from "assets";
const Footer = (props) => {
  return (
      <footer>
        <Container>
          <Grid container justifyContent={"space-between"} spacing={1}>
            <Grid item>
              <div className="single-cta">
                <div className="cta-text">
                  <Typography variant="h6" component="h1" gutterBottom>
                    Find us
                  </Typography>
                  <List className="social-links">
                    <ListItem style={{marginLeft: 0}}>
                      <Link href="#">
                        <Facebook />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="#">
                        <LinkedIn />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="#">
                        <Instagram />
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="#">
                        <Twitter />
                      </Link>
                    </ListItem>
                  </List>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="single-cta">
                <List className="nav-list">
                  <ListItem>
                    <Link href="#" underline="hover">
                      About us
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#" underline="hover">
                      Privacy policy
                    </Link>
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item>
              <div className="single-cta">
                <List className="nav-list">
                  <ListItem>
                    <Link href="#" underline="hover">
                      Contact us
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#" underline="hover">
                      Terms of use
                    </Link>
                  </ListItem>
                </List>
              </div>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} flexDirection={"column"} className="footer-text">
            <div className="footer-logo">
              <Link href="#">
                <img
                    src={logo}
                    alt=""
                    className="main-logo has-cursor"
                    // onClick={() => (isLoging ? history.push(routes.DASHBOARD) : null)}
                />
              </Link>
            </div>
            <Typography component="p">
              Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed
              do eiusmod tempor incididuntut consec tetur adipisicing
              elit,Lorem ipsum dolor sit amet.
            </Typography>
          </Grid>
        </Container>
      </footer>
  );
};

Footer.propTypes = {};
export default Footer;
