import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {Paper, Grid, TextField, Button, Typography, Box} from "@mui/material";
import { withStyles } from "@mui/styles";
import { ACTION_STATUSES } from "constants/type";
import { sendInvitationTeamMember } from "vendor/redux/actions";
import {handleShowModal, modalTypes} from "components";
import {
  userSignUpInitialValues,
  userSignUpValidation,
} from "vendor/services/yup-schemas/userSignUpValidation";
import styles from "./styles";

const Invitation = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { message, status } = useSelector((state) => state.invite);
  const onSubmit = async (values) => {
    await dispatch(sendInvitationTeamMember(values));
  };
  const formik = useFormik({
    initialValues: userSignUpInitialValues,
    validationSchema: userSignUpValidation,
    onSubmit,
  });

  return (
    <div className="auth">
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <div className={classes.margin}>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item xs={12}>
                <Typography variant="h5"> Invitation </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item xs={12}>
                <TextField
                  label="First Name"
                  name="first_name"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                />
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item xs={12}>
                <TextField
                  label="Last Name"
                  name="last_name"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                />
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1 }} alignItems="flex-end">
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  fullWidth
                  required
                  variant="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                fullWidth
                disabled={status === ACTION_STATUSES.PENDING}
                type="submit"
              >
                Invite
              </Button>

              {message && <Typography className="error">{message}</Typography>}
            </Grid>
          </div>
        </Box>
      </form>
    </div>
  );
};

export default withStyles(styles)(Invitation);
