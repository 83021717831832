import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeadsChartData } from "vendor/redux/actions";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
    getPieChartOptions,
    getLineChartOptions,
} from "constants/chart-options";
import {Box, Card, Grid} from "@mui/material";
import { WidgetNumber } from "components";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import {percentSower} from "../../vendor/helper/percentShower";

const LeadsChart = () => {
    const dispatch = useDispatch();
    const { leadsData } = useSelector(state => state.charts);
    const { count } = useSelector(state => state.leads);

    useEffect(() => {
        dispatch(fetchLeadsChartData());
    }, [dispatch, count, leadsData.leads_total_count]);

    return (
        <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            mb={3}
        >
            <Grid item xs={12} sm={6}>
                <Card>
                    <WidgetNumber
                        number={leadsData?.leads_total_count}
                        text="Total leads"
                        icon={<AccountTreeOutlinedIcon />}
                    />
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card>
                    <WidgetNumber
                        number={`$ ${leadsData?.fb_cost_per_lead}`}
                        text="Cost per lead"
                        icon={<MonetizationOnOutlinedIcon />}
                    />
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card className="chart-wrapper">
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                leadsData?.leads_by_campaign?.length > 0 ?   <HighchartsReact
                                    highcharts={Highcharts}
                                    options={getLineChartOptions(
                                        "Leads by Campaign",
                                        null,
                                        "100%",

                                        leadsData?.leads_by_campaign
                                            ? leadsData?.leads_by_campaign?.map((data) => ({
                                                name: data.campaign,
                                                type: "line",
                                                data: data.leads_by_date.map((item) => item.total),
                                                showInNavigator: false,
                                                color: "#000",

                                                marker: {
                                                    enabled: true,
                                                },
                                            }))
                                            : []
                                    )}
                                /> :  <Box>
                                    <p className="title">Leads by Campaign</p>
                                    <p className="no-data-text">No data to show</p>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card className="chart-wrapper">
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                leadsData?.leads_by_country?.length > 0 ? <HighchartsReact
                                    highcharts={Highcharts}
                                    options={getPieChartOptions(
                                        "Leads by country",
                                        220,
                                        "100%",
                                        leadsData?.leads_by_country
                                            ? leadsData.leads_by_country.map((data) => ({
                                                y: data.total,
                                                name: data.country + " " +  percentSower(data.total, leadsData.leads_total_count) + "%",
                                            }))
                                            : []
                                    )}
                                /> :  <Box>
                                    <p className="title">Leads by country</p>
                                    <p className="no-data-text">No data to show</p>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default LeadsChart;
