import { User } from "vendor/services/api/agent";

// singIn
export const singIn = async (data) => {
  try {
    const response = await User.login(data);
    return response;
  } catch (error) {
    return error;
  }
};
// singUp
export const singUp = async (data) => {
  try {
    const response = await User.register(data);
    return response;
  } catch (error) {
    return error;
  }
};
// forget pass
export const forgotPassword = async (data) => {
  try {
    const response = await User.forgotPass(data);
    return response;
  } catch (error) {
    return error;
  }
};
// set/reset pass
export const changePassword = async (data) => {
  try {
    const response = await User.changePass(data);
    return response;
  } catch (error) {
    return error;
  }
};
// invite team member
export const inviteTeamMember = async (data) => {
  try {
    const response = await User.inviteTeamMember(data);
    return response;
  } catch (error) {
    return error;
  }
};

// preliminary check user with uuid
export const preliminaryCheck = async (uuid) => {
  try {
    const response = await User.preliminaryCheck(uuid);
    return response;
  } catch (error) {
    return error;
  }
};
// logout
export const logout = async (refresh) => {
  try {
    const response = await User.logout(refresh);
    return response;
  } catch (error) {
    return error;
  }
};
// user details
export const getUserDetails = async () => {
  try {
    const response = await User.details();
    return response;
  } catch (error) {

    return error;
  }
};

// update user info
export const updateInfo = async (data) => {
  try {
    const response = await User.update(data);
    return response;
  } catch (error) {
    return error;
  }
};
// change password of current user
export const changePassMe = async (data) => {
  try {
    const response = await User.changePassMe(data);
    return response;
  } catch (error) {
    return error;
  }
};
