import React from "react";
import { ACTION_STATUSES } from "constants/type";
import { TextField, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const Input = (props) => {
  const {
    status,
    value,
    onChange,
    onUpdate,
    onClose,
    label,
    InputLabelProps,
    isEdit,
  } = props;
  return (
    <div className="input-wrapper">
      <TextField
        autoFocus
        margin="dense"
        id="tag"
        label={label}
        type="text"
        // fullWidth
        disabled={!isEdit}
        value={value}
        onChange={onChange}
        InputLabelProps={InputLabelProps}
      />
      {isEdit && (
        <div className="input-wrapper__actions">
          {status === ACTION_STATUSES.PENDING ? (
            <CircularProgress color="success" size={25} />
          ) : (
            <div>
              <CheckIcon color="success" onClick={onUpdate} />
              <CloseIcon color="error" onClick={onClose} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Input;
