import React, {useEffect} from "react";
import { Header, Footer } from "components";
import { Typography, Box, Button, Grid, Container } from "@mui/material";
import {useSelector} from "react-redux";
import routes from "../../constants/routes";
import {useHistory} from "react-router-dom";
const LandingPage = () => {
  const { isLoging } = useSelector((state) => state.signin);
  const history = useHistory();

  useEffect(() => {
    if(isLoging) {
      history.push(routes.DASHBOARD)
    } else history.push((routes.SIGNIN))
  }, [isLoging])

  return (
    <>
      {
        !isLoging && (<>
          <Header />
          <section className="landing-wrapper">
            <div className="landing-wrapper__banner">
              <div className="details">
                <Typography variant="h1" component="h1" gutterBottom>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="div">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                </Typography>
                <div className="btns">
                  <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: 20 }}
                  >
                    Get Started Free
                  </Button>
                  <Button variant="contained" color="primary">
                    Book a Demo
                  </Button>
                </div>
              </div>
            </div>
            <Box sx={{ maxWidth: 700, my: 1, mx: "auto", p: 2, mb: 10 }}>
              <Typography variant="h2" component="h2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </Typography>
            </Box>
            <Container maxWidth="xl" className="landing-wrapper__features">
              <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant="h4" component="h4" gutterBottom>
                    Track your Leads easily
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                    in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant="h4" component="h4" gutterBottom>
                    Title xyz
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                    in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant="h4" component="h4" gutterBottom>
                    Join for free
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                    in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            <Box sx={{ maxWidth: 700, my: 1, mx: "auto", p: 2, mb: 10 }}>
              <Button variant="outlined" color="primary">
                Explore all features
              </Button>
            </Box>
          </section>
          <Footer />
        </>)
      }
    </>
  );
};
export default LandingPage;
