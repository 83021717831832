import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyUsers, assignToUser } from "vendor/redux/actions";

import {
  Grid,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
} from "@mui/material";
import {handleShowModal} from "../common/modal/handleShowModal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LeadsAssignTo = ({action}) => {
  const dispatch = useDispatch();
  const [tagsName, setTagsName] = useState([]);
  const { companyUserList } = useSelector(state => state.companyUsers);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (tagsName.indexOf(value) > -1) {
    } else {
      setTagsName(value, ...tagsName);
    }
  };

  const getCompanyUsers = () => {
    dispatch(fetchCompanyUsers());
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getCompanyUsers, [dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl sx={{ m: 1, minWidth: 400, width: "100%" }}>
          <InputLabel id="demo-multiple-checkbox-label">Assign To</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={tagsName}
            onChange={handleChange}
            input={<OutlinedInput label="User" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {companyUserList.results?.map((user) => {
              return (
                <MenuItem
                  key={user.id}
                  value={`${user.first_name}  ${user.last_name}`}
                  onClick={() => action(user.id)}
                >
                  <ListItemText
                    primary={`${user.first_name}  ${user.last_name}`}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
export default LeadsAssignTo;
