import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Home from "@mui/icons-material/Home";
import { withStyles } from "@mui/styles";
import routes from "constants/routes";
import { COMPANY_ADMIN_ROLE } from "constants/userRols";
import styles from "./styles";
import { modalTypes, handleShowModal } from "components";
import BillingNavItem from "./BillingNavItem";

const SideBarMenu = (props) => {
  const { open } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [expanded, setExpanded] = useState(false);
  const pathname = window.location.pathname;

  const onCreateLead = () => handleShowModal(dispatch, modalTypes.createSingleLead(), true);
  const onImportLead = () => handleShowModal(dispatch, modalTypes.importLead(), true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };
  const onClickMenu = (event, path, panel) => {
    event.stopPropagation(false);
    history.push(path);
    window.scrollTo(0, 0);
  };

  return (
      <div className="side-bar">
        <Paper sx={{ width: "100%", maxWidth: "100%" }}>
          <Box className="drawer-nav">
            <NavLink
                to={routes.DASHBOARD}
                activeClassName="active"
                className={"link-button"}
            >
              <Home />
              {open && <Typography sx={{ flexShrink: 0 }}>Dashboard</Typography>}
            </NavLink>
          </Box>
          <Accordion
              onChange={handleChange("panel1")}
              className="accordion-items"
              defaultExpanded={pathname === routes.LEADS}
          >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="accordion-items"
            >
              <FilterAltIcon />
              {open && (
                  <Typography
                      sx={{ flexShrink: 0 }}
                      className={"link-button"}
                  >

                    Leads
                  </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <List className="nav-items">
                <ListItem>
                  <NavLink
                      activeClassName="active"
                      className={"link-button"}
                      to={routes.LEADS}
                      underline="hover">
                    Leads Dashboard
                  </NavLink>
                </ListItem>
                <ListItem>
                  <Button
                      className={"link-button"}
                      onClick={onImportLead}>
                    Import
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                      className={"link-button"}
                      onClick={onCreateLead}>
                    Create Lead
                  </Button>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Box className="drawer-nav">
            <NavLink
                className={"link-button"}
                activeClassName="active"
                to={routes.AUDIENCES}>
              <GroupIcon />
              {open && <p>Audiences</p>}
            </NavLink>
          </Box>
          <Accordion
              defaultExpanded={(pathname === routes.SOURCES) || (pathname === routes.TAGS) || (pathname === routes.TEAM)}
              className="accordion-items"
          >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
            >
              <SettingsIcon />
              {open && (
                  <Typography
                      sx={{ flexShrink: 0 }}
                  >
                    Configurations
                  </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <List className="nav-items">
                <ListItem>
                  <NavLink activeClassName="active"
                           className={"link-button"}
                           to={routes.SOURCES}
                  >
                    Sources
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                      activeClassName="active"
                      className={"link-button"}
                      to={routes.TAGS}
                  >
                    Tags
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                      to={routes.TEAM}
                      activeClassName="active"
                      className={"link-button"}>
                    Team
                  </NavLink>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
     {/*     {user.role === COMPANY_ADMIN_ROLE && (
              <BillingNavItem open={open} />
          )}*/}
        </Paper>
      </div>
  );
};

export default withStyles(styles)(SideBarMenu);
