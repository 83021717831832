import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import './user-details.scss';
import { updateUserInfo, changePassCurrentUser } from "vendor/redux/actions";
import { ACTION_STATUSES } from "constants/type";
import toast from "react-hot-toast";
import {Grid, Typography, Box, Card} from "@mui/material";
import { Input, Select } from "components";
import EditIcon from "@mui/icons-material/Edit";
import ChangePassForm from "./ChangePassForm";
const UserDetails = () => {
    const dispatch = useDispatch();
    const { user, updateStatus, changePassStatus } = useSelector(
        (state) => state.user
    );
    const [isEdit, setIsEdit] = useState({
        name: false,
        surname: false,
        phone: false,
        gender: false,
    });
    const [userData, setUserData] = useState({
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        gender: user.gender,
    });
    const handleUpdate = () => {
        dispatch(updateUserInfo(userData));
    };
    const handleChangePass = (values) => {
        dispatch(changePassCurrentUser(values));
    };
    const submitListener = () => {
        if (updateStatus === ACTION_STATUSES.SUCCEED) {
            setIsEdit({
                name: false,
                surname: false,
                phone: false,
                gender: false,
            });
            toast.success("Your information has been changed successfully!");
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(submitListener, [updateStatus]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
                <Card style={{padding: "16px 24px", height: "100%"}}>
                    <Box>
                        <Typography variant="h5" fontSize={"18px"} mb={2}>Basic info</Typography>
                    </Box>
                    <Box>
                        <Box sx={{ display: "flex" }} className="user-details-row">
                            {isEdit.name ? (
                                <Input
                                    value={userData?.first_name}
                                    onChange={(event) =>
                                        setUserData({ ...userData, first_name: event.target.value })
                                    }
                                    onUpdate={handleUpdate}
                                    onClose={() => setIsEdit({ ...isEdit, name: false })}
                                    status={updateStatus}
                                    label="Name"
                                    isEdit
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            ) : (
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography className={"text-label"}> Name: </Typography>
                                    <Typography className={"text-value"} sx={{ marginLeft: 1 }}>
                                        {userData.first_name}
                                    </Typography>
                                    <EditIcon
                                        onClick={() => setIsEdit({ ...isEdit, name: true })}
                                        className="user-details-row__icon"
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: "flex" }} className="user-details-row">
                            {isEdit.surname ? (
                                <Input
                                    value={userData?.last_name}
                                    onChange={(event) =>
                                        setUserData({ ...userData, last_name: event.target.value })
                                    }
                                    onUpdate={handleUpdate}
                                    onClose={() => setIsEdit({ ...isEdit, surname: false })}
                                    status={updateStatus}
                                    label="Surname"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    isEdit
                                />
                            ) : (
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    {" "}
                                    <Typography className={"text-label"}> Surname: </Typography>
                                    <Typography className={"text-value"} sx={{ marginLeft: 1 }}>
                                        {userData.last_name}
                                    </Typography>
                                    <EditIcon
                                        className="user-details-row__icon"
                                        onClick={() => setIsEdit({ ...isEdit, surname: true })}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Card style={{padding: "16px 24px", height: "100%"}}>
                    <Grid item>
                        <Typography variant="h5" fontSize={"18px"} mb={2}>Contact Info</Typography>
                        <Grid item >
                            <Box sx={{ display: "flex" }} className="user-details-row">
                                {isEdit.phone ? (
                                    <Input
                                        value={userData?.phone_number}
                                        onChange={(event) => {
                                            setUserData({ ...userData, phone_number: event.target.value });
                                        }}
                                        onUpdate={handleUpdate}
                                        onClose={() => setIsEdit({ ...isEdit, phone: false })}
                                        status={updateStatus}
                                        isEdit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Phone"
                                        style={{width: "100%"}}
                                    />
                                ) : (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography className={"text-label"}> Phone: </Typography>
                                        <Typography className={"text-value"} sx={{ marginLeft: 1 }}>
                                            {userData.phone_number}
                                        </Typography>
                                        <EditIcon
                                            className="user-details-row__icon"
                                            onClick={() => setIsEdit({ ...isEdit, phone: true })}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography className={"text-label"}> Email: </Typography>
                                <Typography className={"text-value"} sx={{ marginLeft: 1 }}>{user.email}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Card style={{padding: "16px 24px", height: "100%"}}>
                    <Grid item>
                        <Typography variant="h5" fontSize={"18px"} mb={2}>General Details</Typography>
                        <Box>
                            <Box sx={{ display: "flex" }} className="user-details-row">
                                {isEdit.gender ? (
                                    <Select
                                        value={userData.gender}
                                        label="Gender"
                                        onChange={(event) => {
                                            setUserData({ ...userData, gender: event.target.value });
                                        }}
                                        onUpdate={handleUpdate}
                                        onClose={() => setIsEdit({ ...isEdit, gender: false })}
                                        status={updateStatus}
                                        isEdit
                                    />
                                ) : (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography className={"text-label"}> Gender: </Typography>
                                        <Typography className={"text-value"} sx={{ marginLeft: 1 }}>{userData.gender}</Typography>
                                        <EditIcon
                                            className="user-details-row__icon"
                                            onClick={() => setIsEdit({ ...isEdit, gender: true })}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{padding: "16px 24px"}}>
                    <Grid item xs={12}>
                        <Typography variant="h5" fontSize={"18px"} mb={2}>Password</Typography>
                    </Grid>
                    <ChangePassForm
                        disabled={changePassStatus === ACTION_STATUSES.PENDING}
                        handleChangePass={handleChangePass}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};

export default UserDetails;
