import React from "react";
const WidgetNumber = (props) => {
  const { text, number, icon } = props;
  return (
    <div className="widget-number">
      <div>{icon}</div>
      <div className="widget-number__content">
        <h5> {text}</h5>
        <span>{number}</span>
      </div>
    </div>
  );
};
export default WidgetNumber;
