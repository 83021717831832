import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import routes from "constants/routes";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox, IconButton,
} from "@mui/material";

import EnhancedTableHead from "./EnhancedTableHead";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function AudiencesList(props) {
  const { onClickDownload, selected, setSelected, onDelete } = props;
  const { audiencesList, count } = useSelector((state) => state.audiences);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(props.orderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selectedListener = () => setSelected(selected);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(selectedListener, [selected]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    props.handleRequestSort(isAsc ? `-${property}` : property);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = audiencesList.map((n) => n.id);
      props.setSelected(newSelecteds);
      return;
    }
    props.setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = props.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }
    props.setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.handleChangeRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => props.selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={props.selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={audiencesList?.length}
            />
            <TableBody>
              {audiencesList?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                  >
                    <TableCell onClick={(event) => handleClick(event, row.id)}>
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell id={labelId} scope="row" align="left">
                        {index + 1}
                    </TableCell>
                    <TableCell align="left">
                      <Link
                          to={routes.AUDIENCES_EDIT.replace(
                              ":id",
                              row.id
                          ).replace(":name", row.name)}
                      >
                        {row?.name}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{row?.size}</TableCell>
                    <TableCell align="left">
                      {format(new Date(row.created_at), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell align="left">
                      {format(new Date(row.updated_at), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell align="left">{row.creator_name}</TableCell>

                    <TableCell align="left">
                      <IconButton>
                      <FileDownloadIcon
                        onClick={() => onClickDownload(row.id)}
                      />
                      <DeleteIcon
                        onClick={() => {
                          onDelete(row);
                        }}
                        color="secondary"
                      />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
