import * as Yup from "yup";

export const forgotPassValidation = () =>
    Yup.object({
        email: Yup.string()
            .email("The e-mail address is not valid")
            .required("This field is required"),
    });

export const forgotPassInitialValues = {
    email: "",
};