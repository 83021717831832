import React, { useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import routes from "constants/routes";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
} from "@mui/material";

import EnhancedTableHead from "./EnhancedTableHead";

export default function AudiencesList(props) {
  const { sourcesList, count } = useSelector((state) => state.sources);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(props.orderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    props.handleRequestSort(isAsc ? `-${property}` : property);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.handleChangeRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={sourcesList?.length}
            />
            <TableBody>
              {sourcesList?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell align="left">
                      <Link
                        to={routes.SOURCES_EDIT.replace(":id", row.id).replace(
                          ":name",
                          row.page_name
                        )}
                      >
                        {row?.page_name ? row?.page_name : "-"}
                      </Link>
                    </TableCell>

                    <TableCell align="left">{row?.status}</TableCell>
                    <TableCell align="left">
                      {format(new Date(row.created_at), "MM/dd/yyyy")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
