import requests from "./base";

const Sources = {
  list: (filters) =>
    requests.get(
      `facebook/source/?limit=${filters.limit}&offset=${filters.offset}&search=${filters.search}&ordering=${filters.ordering}`
    ),
  details: (sourceId) => requests.get(`facebook/source/${sourceId}/`),
  update: (id, data) => requests.patch(`facebook/source/${id}/`, data),
};

export default Sources;
