import { combineReducers } from "redux";

import signin from "./signinReducer";
import forget from "./forgetReducer";
import signup from "./signupReducer";
import invite from "./inviteReducer";
import user from "./userReducer";
import leads from "./leadsReducer";
import tag from "./tagReducer";
import companyUsers from "./companyUsersReducer";
import audiences from "./audiencesReducer";
import sources from "./sourcesReducer";
import charts from "./chartsReducer";
import modals from "./modalReducer";
import facebookLeadsExport from "./facebookLeadsExportReducer";

export default combineReducers({
  signin,
  forget,
  signup,
  leads,
  tag,
  companyUsers,
  invite,
  user,
  audiences,
  sources,
  charts,
  modals,
  facebookLeadsExport,
});
