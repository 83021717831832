import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {fetchDashboardChartData, fetchLeadsChartData} from "vendor/redux/actions";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Box, Card, Grid} from "@mui/material";
import {
    getPieChartOptions,
    getLineChartOptions,
} from "constants/chart-options";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import {WidgetNumber} from "../../components";
import {percentSower} from "../../vendor/helper/percentShower";

const Dashboard = () => {
    const dispatch = useDispatch();
    const { dashboardData, leadsData } = useSelector((state) => state.charts);

    const init = () => {
        dispatch(fetchDashboardChartData());
        dispatch(fetchLeadsChartData());
    };
    useEffect(init, [dispatch]);

    const rowSize = {
        height: 450,
        width: 200,
    };

    return (
        <>
            <Grid container spacing={{ xs: 2,}}>
                <Grid item xs={12} md={6} xl={4}>
                    <Card className="chart-wrapper">
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    dashboardData?.leads_by_country?.length > 0 ?
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={getPieChartOptions(
                                                "Leads by source",
                                                350,
                                                "100%",
                                                dashboardData?.leads_by_country?.length > 0
                                                    ? dashboardData.leads_by_source.map((data) => ({
                                                        y: data.total,
                                                        name: data.source,
                                                    }))
                                                    : []
                                            )}
                                        /> :        <Box>
                                            <p className="title">Leads by source</p>
                                            <p className="no-data-text">No data to show</p>
                                        </Box>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Card className="chart-wrapper">
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    dashboardData?.leads_by_country?.length > 0 ?  <HighchartsReact
                                            highcharts={Highcharts}
                                            options={getPieChartOptions(
                                                "Leads by country",
                                                350,
                                                "100%",
                                                dashboardData?.leads_by_country
                                                    ? dashboardData.leads_by_country.map((data) => ({
                                                        y: data.total,
                                                        name: data.country + " " + percentSower(data.total, dashboardData.leads_total_count) + "%",
                                                    }))
                                                    : []
                                            )}
                                        /> :
                                        <Box>
                                            <p className="title">Leads by country</p>
                                            <p className="no-data-text">No data to show</p>
                                        </Box>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Card className="chart-wrapper">
                        <Grid container>
                            <Grid item xs={12}>
                                { dashboardData?.leads_by_campaign?.length > 0 ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={getLineChartOptions(
                                            "Leads by Campaign",
                                            250,
                                            null,
                                            dashboardData?.leads_by_campaign
                                                ? dashboardData?.leads_by_campaign?.map((data) => ({
                                                    name: data.campaign,
                                                    type: "line",
                                                    data: data.leads_by_date.map(
                                                        (item) => item.total
                                                    ),
                                                    showInNavigator: false,

                                                    marker: {
                                                        enabled: false,
                                                    },
                                                }))
                                                : []
                                        )}
                                    /> :  <Box>
                                        <p className="title">Leads by Campaign</p>
                                        <p className="no-data-text">No data to show</p>
                                    </Box> }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Card className="chart-wrapper">
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    {leadsData.fb_cost_per_lead?
                                      <WidgetNumber
                                          number={`$ ${leadsData?.fb_cost_per_lead}`}
                                          text="Cost per lead"
                                          icon={<MonetizationOnOutlinedIcon />}
                                      /> :
                                      <>
                                         <p className="title">Cost per lead</p>
                                         <p className="no-data-text">No data to show</p>
                                      </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Card className="chart-wrapper">
                        <Grid container>
                            <Grid item xs={12}>
                                { dashboardData?.leads_count_in_audience?.length > 0 ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                            chart: {
                                                plotBackgroundColor: null,
                                                height: 400,
                                                plotBorderWidth: null,
                                                plotShadow: false,
                                                type: "column",
                                                style: {
                                                    float: "centre",
                                                },
                                            },
                                            title: {
                                                text: "Leads per Audience",
                                            },
                                            xAxis: {
                                                categories: [""],
                                                crosshair: true,
                                            },
                                            yAxis: {
                                                min: 0,
                                                title: {
                                                    text: "Leads count",
                                                },
                                            },
                                            plotOptions: {
                                                column: {
                                                    pointPadding: 0.2,
                                                    borderWidth: 0,
                                                },
                                            },
                                            credits: {
                                                enabled: false,
                                            },
                                            series: dashboardData?.leads_count_in_audience
                                                ? dashboardData.leads_count_in_audience.map((data) => ({
                                                    data: [data.size],
                                                    name: data.name,
                                                }))
                                                : [],
                                        }}
                                    /> :    <Box>
                                        <p className="title">Leads per Audience</p>
                                        <p className="no-data-text">No data to show</p>
                                    </Box> }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Card className="chart-wrapper">
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    dashboardData?.leads_count_in_audience_by_source?.length > 0 ?    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                            chart: {
                                                type: "column",
                                            },
                                            credits: {
                                                enabled: false,
                                            },
                                            title: {
                                                text: "Leads count in audience by source",
                                            },
                                            xAxis: {
                                                categories: dashboardData?.leads_count_in_audience_by_source
                                                    ? dashboardData.leads_count_in_audience_by_source.map(
                                                        (data) => data.lead_by_source.map((item) => item.source)
                                                    )
                                                    : [],
                                            },

                                            yAxis: {
                                                allowDecimals: false,
                                                min: 0,
                                                title: {
                                                    text: "Leads count"
                                                }
                                            },

                                            tooltip: {
                                                formatter: function () {
                                                    return (
                                                        "<b>" +
                                                        this.x +
                                                        "</b><br/>" +
                                                        this.series.name +
                                                        ": " +
                                                        this.y +
                                                        "<br/>" +
                                                        "Total: " +
                                                        this.point.stackTotal
                                                    );
                                                },
                                            },
                                            plotOptions: {
                                                column: {
                                                    stacking: "normal",
                                                },
                                            },
                                            series: dashboardData?.leads_count_in_audience_by_source
                                                ? dashboardData.leads_count_in_audience_by_source.map(
                                                    (data) => ({
                                                        name: data.name,
                                                        data: data.lead_by_source.map((item) => item.total),
                                                        stack: "source",
                                                    })
                                                )
                                                : [],
                                        }} /> :  <Box>
                                        <p className="title">Leads count in audience by source</p>
                                        <p className="no-data-text">No data to show</p>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};
export default Dashboard;
