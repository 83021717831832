import {
    GET_COMPANY_USERS_REQUEST,
    GET_COMPANY_USERS_SUCCEED,
    GET_COMPANY_USERS_FAILURE,
    DELETE_COMPANY_USER_REQUEST,
    DELETE_COMPANY_USER_SUCCEED,
    DELETE_COMPANY_USER_FAILURE,
    UPDATE_COMPANY_USER_REQUEST,
    UPDATE_COMPANY_USER_SUCCEED,
    UPDATE_COMPANY_USER_FAILURE,
} from "constants/type";
import {
    deleteCompanyUser,
    getCompanyUsers,
    updateCompanyUser
} from "vendor/services/companyUsersService";

export function fetchCompanyUsers() {
    return async (dispatch) => {
        dispatch({ type: GET_COMPANY_USERS_REQUEST });
        const result = await getCompanyUsers();
        if (result?.status === 200) {
            dispatch({
                type: GET_COMPANY_USERS_SUCCEED,
                payload: result?.data,
            });
        } else {
            dispatch({
                type: GET_COMPANY_USERS_FAILURE,
                message: "",
            });
        }
    };
}

export function removeCompanyUser(id) {
    return async (dispatch) => {
        dispatch({ type: DELETE_COMPANY_USER_REQUEST });
        const result = await deleteCompanyUser(id);
        if (result?.status === 204) {
            dispatch({
                type: DELETE_COMPANY_USER_SUCCEED,
                payload: result?.data,
            });
        } else {
            dispatch({
                type: DELETE_COMPANY_USER_FAILURE,
                message: "",
            });
        }
    };
}

export function updateUser(id, data) {
    return async (dispatch) => {
        dispatch({ type: UPDATE_COMPANY_USER_REQUEST });
        const result = await updateCompanyUser(id, data);
        if (result?.status === 200) {
            dispatch({
                type: UPDATE_COMPANY_USER_SUCCEED,
                payload: result?.data,
            });
        } else {
            dispatch({
                type: UPDATE_COMPANY_USER_FAILURE,
                message: "",
            });
        }
    };
}
