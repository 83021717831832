import {useEffect, useState} from "react";

import {Alert, Box, Button, Grid} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { saveAs } from "file-saver";
import DownloadIcon from '@mui/icons-material/Download';
import template from "assets/files/template.csv";
import {importLeadsFile} from "../../vendor/redux/actions";
import {handleShowModal} from "../common";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {ACTION_STATUSES} from "../../constants/type";
import {useDropzone} from 'react-dropzone';
import {importLeadInitialValues} from "../../vendor/services/yup-schemas/importLeadsValidation";
import styled from "@emotion/styled";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

const ImportLead = (props) => {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        fileRejections,

    } = useDropzone({
        accept: '.csv',
        multiple: false
    });


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <p key={file.path}>
            {errors.map(e => (
                <span key={e.code}>{e.message}</span>
            ))}
        </p>
    ));


    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    const { createStatus } = useSelector(state => state.leads);

    useEffect( () => {
        setFile(acceptedFiles[0])
    }, [acceptedFiles])

    const saveFile = () => {
        saveAs(
            `${template}`,
            "template.csv"
        );
    };

    const onSubmit = async () => {
        await dispatch(importLeadsFile({
            file
        }));
        handleShowModal(dispatch, "", false);
    };

    const formik = useFormik({
        onSubmit,
        initialValues: importLeadInitialValues,
    });

    return(
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1} justifyContent="flex-end" mt={4}>
                    <Grid item xs={12} mb={2} className="drag-drop-upload" spacing={0}>
                        <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
                            <input {...getInputProps()}  />
                            <Box sx={{display: "flex", alignItems: "center", marginRight: "10px"}}>
                                <FileUploadIcon />
                                Upload or drop a file right here
                            </Box>

                            <aside  style={{marginTop: "12px", width: "100%"}}>
                                {
                                    fileRejectionItems.length > 0 &&
                                    <Alert style={{justifyContent: "center", alignItems: "center"}} severity="warning">{fileRejectionItems}</Alert>
                                }
                                {
                                    file &&
                                    <Alert style={{justifyContent: "center"}} severity="success">File has been successfully uploaded!</Alert>
                                }
                            </aside>
                        </Container>
                    </Grid>
                    <Grid item xs={12} mb={2} >
                        <Box sx={{width: "100%", textAlign: "center"}}>
                            <Button onClick={saveFile}>
                                Download Template<DownloadIcon />
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            disabled={createStatus === ACTION_STATUSES.PENDING || !file}
                        >
                            Import
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Button
                            fullWidth
                            onClick={() => handleShowModal(dispatch, "", false)}
                            color="secondary"
                            variant="contained">
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ImportLead;