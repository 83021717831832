import {
  SET_USER_REQUEST,
  SET_USER_SUCCEED,
  SET_USER_FAILURE,
  ACTION_STATUSES,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCEED,
  UPDATE_USER_FAILURE,
  CHANGE_PASS_ME_REQUEST,
  CHANGE_PASS_ME_SUCCEED,
  CHANGE_PASS_ME_FAILURE,
} from "constants/type";

const initialState = {
  user: {},
  status: null,
  message: "",
  updateStatus: null,
  changePassStatus: null,
  changePassErrors: {},
};

const UserReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case SET_USER_SUCCEED:
      return {
        ...state,
        user: action.payload,
        status: ACTION_STATUSES.SUCCEED,
        message: "",
        isLoging: true,
        changePassStatus: null,
        updateStatus: null,
      };
    case SET_USER_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updateStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case UPDATE_USER_SUCCEED:
      return {
        ...state,
        user: action.payload,
        updateStatus: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case CHANGE_PASS_ME_REQUEST:
      return {
        ...state,
        changePassStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case CHANGE_PASS_ME_SUCCEED:
      return {
        ...state,
        changePassStatus: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case CHANGE_PASS_ME_FAILURE:
      return {
        ...state,
        changePassStatus: ACTION_STATUSES.FAILURE,
        changePassErrors: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
