import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function createData(name, price) {
  return { name, price };
}

const rows = [
  createData("December 01", "$199.00"),
  createData("November 01", "$199.00"),
  createData("October 01", "$199.00"),
  createData("September 01", "$199.00"),
  createData("August 01", "$199.00"),
];

export default function BasicTable() {
  const [selected, setSelected] = useState("");
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">
                <Button
                  variant={selected === row.name ? "contained" : "outlined"}
                  color="success"
                  onClick={() => setSelected(row.name)}
                >
                  View
                  <ArrowForwardIosIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
