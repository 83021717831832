import React from "react";
import {
  CreateSingleLead,
  LeadsAssignTo,
  LeadsAudiences,
  LeadsTags,
  CreateTag,
} from "components";
import ConfirmationModal from "./ConfirmationModal";
import { InvitationPage } from "screens/index";
import { SuccessAlert } from "../../user";
import Connection from "../../facebook/Connection";
import ImportLead from "../../leads/ImportLead";

export const modalTypes = {
  addTags: (props) => {
    return {
      title: "Tags",
      component: <LeadsTags {...props} />,
    };
  },
  createTag: (props) => {
    return {
      title: "Create a New Tag",
      component: <CreateTag {...props} />,
    };
  },
  deleteTag: (props) => {
    return {
      title: "Remove a Tag",
      component: <ConfirmationModal {...props} />,
    };
  },
  assignTo: (props) => {
    return {
      title: "Assign to",
      component: <LeadsAssignTo {...props} />,
    };
  },
  removeTags: (props) => {
    return {
      title: "Remove Tags",
      component: <ConfirmationModal {...props} />,
    };
  },
  unassignUser: (props) => {
    return {
      title: "Unassign User",
      component: <ConfirmationModal {...props} />,
    };
  },
  removeLead: (props) => {
    return {
      title: "Remove Leads",
      component: <ConfirmationModal {...props} />,
    };
  },
  export: (props) => {
    return {
      title: "To Export leads create a new audience",
      component: <LeadsAudiences {...props} />,
    };
  },
  createSingleLead: (props) => {
    return {
      title: "Create lead",
      component: <CreateSingleLead {...props} />,
    };
  },
  importLead: (props) => {
    return {
      title: "Import Leads",
      component: <ImportLead {...props} />,
    };
  },
  removeAudiences: (props) => {
    return {
      title: "Remove Audiences",
      component: <ConfirmationModal {...props} />,
    };
  },
  removeAudience: (props) => {
    return {
      title: "Delete Audience",
      component: <ConfirmationModal {...props} />,
    };
  },
  removeAudienceLeads: (props) => {
    return {
      title: "Remove Leads Audience",
      component: <ConfirmationModal {...props} />,
    };
  },
  removeTeammate: (props) => {
    return {
      title: "Remove User",
      component: <ConfirmationModal {...props} />,
    };
  },
  invitation: (props) => {
    return {
      component: <InvitationPage {...props} />,
    };
  },
  successAlert: (props) => {
    return {
      component: <SuccessAlert {...props} />,
    };
  },
  facebookConnect: (props) => {
    return {
      component: <Connection {...props} />,
      fullScreen: true,
    };
  },
};
