import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_STATUSES } from "constants/type";
import { fetchSourceDetails, updateSource } from "vendor/redux/actions";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  Button,
} from "@mui/material";

export default function SourcesAdd(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { sourceDetails, details_status, update_status } = useSelector(
    (state) => state.sources
  );
  const [formData, setFromData] = useState({
    name: "",
    status: false,
  });
  useEffect(() => {
    dispatch(fetchSourceDetails(params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const submitListener = () => {
    if (details_status === ACTION_STATUSES.SUCCEED) {
      setFromData({
        name: sourceDetails.name,
        status: sourceDetails.status === "enable" ? true : false,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(submitListener, [details_status]);

  const handleUpdateResource = () => {
    dispatch(
      updateSource(params.id, {
        name: formData.name,
        status: formData.status ? "enable" : "disable",
      })
    );
  };
  console.log("sourceDetails", sourceDetails)
  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{
          marginBottom: 30,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5">Edit Source Setting</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="title"
            style={{ marginBottom: 10 }}
            component="div"
          >
            Name*
          </Typography>

          <TextField
            id="outlined-basic"
            label={"Pick a name to identify source"}
            variant="outlined"
            fullWidth
            color="info"
            required
            value={formData.name}
            onChange={(event) => {
              setFromData({ ...formData, name: event.target.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="title">Enable Source</Typography>
          <br />
          <FormControlLabel
            control={
              <Switch
                name="jason"
                checked={formData.status}
                onChange={(event) => {
                  const { checked } = event.target;
                  setFromData({ ...formData, status: checked });
                }}
              />
            }
            label="Enabling this source will aloow it to send data"
          />
        </Grid>
      </Grid>
      <Grid
        container
        align="right"
        justifyContent={"flex-end"}
        spacing={{ xs: 1, sm: 0, md: 0, mt: 8, mr: 8 }}
        columns={{ xs: 1, sm: 2, md: 1, mr: 10, mt: 8 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateResource}
          disabled={
            formData.name === "" || update_status === ACTION_STATUSES.PENDING
          }
        >
          Save Source
        </Button>
      </Grid>
    </>
  );
}
