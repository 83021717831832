import {
  SET_LOGIN,
  SET_USER_REQUEST,
  SET_USER_SUCCEED,
  SET_USER_FAILURE,
  ACTION_STATUSES,
} from "constants/type";

const initialState = {
  isLoging: localStorage.getItem("jwt-access-token") ? true : false,
  user: {},
  status: null,
  message: "",
};

const SignInReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        isLoging: action.payload,
        status: null,
      };
    case SET_USER_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case SET_USER_SUCCEED:
      return {
        ...state,
        user: action.payload,
        status: ACTION_STATUSES.SUCCEED,
        message: "",
        isLoging: true,
      };
    case SET_USER_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    default:
      return state;
  }
};

export default SignInReducer;
