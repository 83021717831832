import * as Yup from "yup";

export const signUpValidation = () =>
  Yup.object({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    company_name: Yup.string().required("This field is required"),
    company_address: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("The e-mail address is not valid")
      .required("This field is required"),
  });

export const signUpInitialValues = {
  first_name: "",
  last_name: "",
  company_name: "",
  email: "",
  company_address: "",
};
