import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_STATUSES } from "constants/type";
import { useHistory } from "react-router-dom";
import { fetchSources } from "vendor/redux/actions";
import { Grid, Button, Typography } from "@mui/material";
import { SourcesList, Search, Loading } from "components";
import routes from "constants/routes";

const Sources = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useSelector((state) => state.sources);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    search: "",
    ordering: "name",
  });
  useEffect(() => {
    dispatch(fetchSources(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const handleChangePage = (page) => {
    dispatch(fetchSources({ ...filters, offset: page * filters.limit }));
    setFilters({ ...filters, offset: page * filters.limit });
  };
  const handleChangeRowsPerPage = (limit) => {
    dispatch(fetchSources({ ...filters, offset: 0, limit: limit }));
    setFilters({ ...filters, limit: limit });
  };
  const handleRequestSort = (order) => {
    dispatch(fetchSources({ ...filters, ordering: order }));
    setFilters({ ...filters, ordering: order });
  };
  const handleSearch = () => {
    dispatch(fetchSources(filters));
  };
  const onClickAdd = () => {
    history.push(routes.SOURCES_ADD);
  };

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{
          marginBottom: 30,
        }}
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Typography>List of Source configured</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Search
            label="Search sources by name"
            value={filters.search}
            onChange={(event) =>
              setFilters({ ...filters, search: event.target.value })
            }
            onSearch={handleSearch}
          />
        </Grid>

        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button variant="contained" color="primary" onClick={onClickAdd}>
            Add Source
          </Button>
        </Grid>
      </Grid>

      {ACTION_STATUSES.PENDING === status && <Loading />}
      <SourcesList
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        orderBy={filters.ordering}
        handleRequestSort={handleRequestSort}
      />
    </>
  );
};
export default Sources;
