import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Button } from "@mui/material";
import {
  fetchCompanyUsers,
  removeCompanyUser,
  updateUser,
} from "vendor/redux/actions";
import {
  TeamList,
  Loading,
  handleShowModal,
  modalTypes,
} from "components";
import { ACTION_STATUSES } from "constants/type";

const TeamPage = () => {
  const dispatch = useDispatch();
  const {
    companyUserList,
    remove_status,
    status,
    update_status,
    create_status,
  } = useSelector((state) => state.companyUsers);
  const { inviteStatus } = useSelector(state => state.invite);
  const {role} = useSelector(state => state.signin.user);
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const onOpenModal = component => handleShowModal(dispatch, component, true);
  const getCompanyUsers = () => dispatch(fetchCompanyUsers());
  const onDeleteUser = (first_name, last_name, id) => {
    onOpenModal(
      modalTypes.removeTeammate({
        message: `delete ${first_name} ${last_name} from team`,
        action: () => dispatch(removeCompanyUser(id))
      }),
    );
  };
  const onUpdateUser = async () => {
    await dispatch(updateUser(selectedUser.id, selectedUser));
  };

  const submitListener = () => {
    if (remove_status === ACTION_STATUSES.SUCCEED) {
      dispatch(fetchCompanyUsers());
      setSelectedUser(null);
      handleShowModal(dispatch, "",false);
    }
    if (update_status === ACTION_STATUSES.SUCCEED) {
      dispatch(fetchCompanyUsers());
      setIsEdit(false);
      setSelectedUser(null);
    }
    if (create_status === ACTION_STATUSES.SUCCEED) {
      dispatch(fetchCompanyUsers());
      setSelectedUser({});
      setOpenModal(false);
    }
    if (inviteStatus === ACTION_STATUSES.SUCCEED) {
      handleShowModal(dispatch, modalTypes.successAlert({message:"The invitation has been send successfully."}), true);
      dispatch(fetchCompanyUsers());
    }
  };

  useEffect(getCompanyUsers, [dispatch]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(submitListener, [remove_status, update_status, create_status, inviteStatus]);

  return (
    <>
      {ACTION_STATUSES.PENDING === status && <Loading />}
      <div className="dashboard">
        <Container maxWidth="xl">
          <Grid direction="row">
            {role === "company_admin" &&
              <Grid
                container
                align="right"
                justifyContent={"flex-end"}
                spacing={{xs: 1, sm: 0, md: 0, mt: 8, mr: 8}}
                columns={{xs: 1, sm: 2, md: 1, mr: 10, mt: 8}}
                style={{
                  marginBottom: 10,
                  marginLeft: "auto",
                  width: "250px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onOpenModal(modalTypes.invitation({inviteStatus}))}
                >
                  Add teammate
                </Button>
              </Grid>
            }
          </Grid>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            style={{ marginTop: 15, marginBottom: 30 }}
          >
            <TeamList
              users={companyUserList?.results || []}
              onEdit={(user) => {
                setIsEdit(true);
                setSelectedUser({
                  id: user.id,
                  first_name: user.first_name,
                  last_name: user.last_name,
                  role: user.role,
                });
              }}
              onDelete={user => onDeleteUser(user.first_name, user.last_name, user.id)}
              isEdit={isEdit}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              changeSelectedUser={(event) =>
                setSelectedUser({
                  ...selectedUser,
                  [event.target.id]: event.target.value,
                })
              }
              onUpdateRow={onUpdateUser}
              onClose={() => {
                setIsEdit(false);
                setSelectedUser(null);
              }}
            />
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default TeamPage;
