import {
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCEED,
  GET_TAGS_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCEED,
  CREATE_TAG_FAILURE,
  REMOVE_TAG_REQUEST,
  REMOVE_TAG_SUCCEED,
  REMOVE_TAG_FAILURE,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_SUCCEED,
  UPDATE_TAG_FAILURE,
} from "constants/type";
import { getTags, create, remove, update } from "vendor/services/tagService";

export function fetchTags() {
  return async (dispatch) => {
    dispatch({ type: GET_TAGS_REQUEST });
    const result = await getTags();
    if (result?.status === 200) {
      dispatch({
        type: GET_TAGS_SUCCEED,
        payload: result?.data,
      });
    } else {
      dispatch({
        type: GET_TAGS_FAILURE,
        message: "",
      });
    }
  };
}
export function createTag(values) {
  return async (dispatch) => {
    dispatch({ type: CREATE_TAG_REQUEST });
    const result = await create(values);
    if (result.status === 201) {
      dispatch({
        type: CREATE_TAG_SUCCEED,
        payload: result.data.user,
      });
      return result;
    } else {
      dispatch({
        type: CREATE_TAG_FAILURE,
        message: "error",
      });
    }
  };
}

export function removeTag(id) {
  return async (dispatch) => {
    dispatch({ type: REMOVE_TAG_REQUEST });
    const result = await remove(id);
    if (result.status === 204) {
      dispatch({
        type: REMOVE_TAG_SUCCEED,
        payload: result.data,
      });
      return result;
    } else {
      dispatch({
        type: REMOVE_TAG_FAILURE,
        message: "error",
      });
    }
  };
}
export function updateTag(id, data) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TAG_REQUEST });
    const result = await update(id, data);
    if (result.status === 200) {
      dispatch({
        type: UPDATE_TAG_SUCCEED,
        payload: result.data,
      });
      return result;
    } else {
      dispatch({
        type: UPDATE_TAG_FAILURE,
        message: "error",
      });
    }
  };
}
