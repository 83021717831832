import React from "react";
import { ACTION_STATUSES } from "constants/type";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const SelectInput = (props) => {
  const { status, value, onChange, onUpdate, onClose, label, isEdit } = props;
  return (
    <div className="select-wrapper">
      <FormControl style={{ maxWidth: 400, width: 400 }}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Gender"
          onChange={onChange}
        >
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
        </Select>
      </FormControl>
      {isEdit && (
        <div className="select-wrapper__actions">
          {status === ACTION_STATUSES.PENDING ? (
            <CircularProgress color="success" size={25} />
          ) : (
            <div>
              <CheckIcon color="success" onClick={onUpdate} />
              <CloseIcon color="error" onClick={onClose} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default SelectInput;
