const styles = (theme) => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
  },
  paper: {
    maxWidth: 500,
    padding: theme.spacing.unit,
    marginTop: 20,
    width: 500,
  },
});
export default styles;
