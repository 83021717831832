import {
  GET_SOURCES_REQUEST,
  GET_SOURCES_SUCCEED,
  GET_SOURCES_FAILURE,
  ACTION_STATUSES,
  GET_SOURCE_DETAILS_REQUEST,
  GET_SOURCE_DETAILS_SUCCEED,
  GET_SOURCE_DETAILS_FAILURE,
  UPDATE_SOURCE_REQUEST,
  UPDATE_SOURCE_SUCCEED,
  UPDATE_SOURCE_FAILURE,
} from "constants/type";

const initialState = {
  sourcesList: [],
  status: null,
  message: "",
  count: 0,
  sourceDetails: null,
  details_status: null,
  update_status: null,
};

const sourcesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SOURCES_REQUEST:
      return {
        ...state,
        status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case GET_SOURCES_SUCCEED:
      return {
        ...state,
        status: ACTION_STATUSES.SUCCEED,
        sourcesList: action.payload.results,
        count: action.payload.count,
        message: "",
      };
    case GET_SOURCES_FAILURE:
      return {
        ...state,
        status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case GET_SOURCE_DETAILS_REQUEST:
      return {
        ...state,
        details_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case GET_SOURCE_DETAILS_SUCCEED:
      return {
        ...state,
        details_status: ACTION_STATUSES.SUCCEED,
        sourceDetails: action.payload,
        message: "",
      };
    case GET_SOURCE_DETAILS_FAILURE:
      return {
        ...state,
        details_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    case UPDATE_SOURCE_REQUEST:
      return {
        ...state,
        update_status: ACTION_STATUSES.PENDING,
        message: "",
      };
    case UPDATE_SOURCE_SUCCEED:
      return {
        ...state,
        update_status: ACTION_STATUSES.SUCCEED,
        sourceDetails: action.payload,
        message: "",
      };
    case UPDATE_SOURCE_FAILURE:
      return {
        ...state,
        update_status: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    default:
      return state;
  }
};

export default sourcesReducer;
