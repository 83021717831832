import * as Yup from "yup";
import ErrorMessages from "./errorMessages";

export const signInValidation = () =>
  Yup.object({
    email: Yup.string()
      .email("The e-mail address is not valid")
      .required("This field is required"),
    password: Yup.string()
      .min(6, ErrorMessages.scemasMin(6))
      .required("This field is required"),
  });

export const signInInitialValues = {
  email: "",
  password: "",

};

