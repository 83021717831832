import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Button, Grid, Box } from "@mui/material";
import { handleShowModal } from "components";

const ConfirmationModal = ({ message, action }) => {
  const dispatch = useDispatch();
  return (
    <Box>
      {message ? (
        <Box>
          <Typography mt={3}>Are you sure about {message}?</Typography>
        </Box>
      ) : null}
      <Grid container spacing={1} justifyContent="flex-end" mt={4}>
        <Grid item xs={6} sm={3}>
          <Button
            fullWidth
            variant="contained"
            onClick={action}
            // disabled={status === ACTION_STATUSES.PENDING}
          >
            Yes
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            onClick={() => handleShowModal(dispatch, "", false)}
            color="secondary"
            fullWidth
            variant="contained"
          >
            No
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmationModal;
