//common helper functions
export const csvDownload = (data, fileName) => {
  let element = document.createElement("a");
  let blob = new Blob([data], {
    type: "text/csv;charset=utf-8;",
  });
  let url = window.URL.createObjectURL(blob);
  element.setAttribute("href", url);
  element.setAttribute("target", "_blank");
  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
export const isEmpty = (obj) => {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};