import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Chip,
  Button,
} from "@mui/material";

import EnhancedTableHead from "./EnhancedTableHead";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

export default function LeadsList(props) {
  const {
    handleReAssignTags,
    handleReAssignUser,
    selected,
    setSelected,
    leadsData,
    count,
    isLeadPage,
    onDeleteLead,
    onClickAssign,
    onClickTag,
  } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(props.orderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selectedListener = () => setSelected(selected);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(selectedListener, [selected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    props.handleRequestSort(isAsc ? `-${property}` : property);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leadsData.map((n) => n.id);
      props.setSelected(newSelecteds);
      return;
    }
    props.setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = props.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          props.selected.slice(0, selectedIndex),
          props.selected.slice(selectedIndex + 1)
      );
    }
    props.setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.handleChangeRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => props.selected.indexOf(name) !== -1;
 
  return (
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
            >
              <EnhancedTableHead
                  numSelected={props.selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={leadsData?.length}
                  isLeadPage={isLeadPage}
              />
              <TableBody>
                {leadsData?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                      <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                      >
                        <TableCell onClick={(event) => handleClick(event, row.id)}>
                          <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                          />
                        </TableCell>
                        <TableCell id={labelId} >
                           {page*rowsPerPage+index+1}
                        </TableCell>
                        <TableCell align="left">{row?.name || "(none)"}</TableCell>
                        <TableCell align="left">{row?.email || "(none)"}</TableCell>
                        <TableCell align="left">
                          {row?.country || "(none)"}
                        </TableCell>
                        <TableCell align="left">{row?.phone || "(none)"}</TableCell>
                        <TableCell align="left">
                          {format(new Date(row.acquisition_date), "MM/dd/yyyy")}
                        </TableCell>
                        <TableCell align="left">
                          {row.assigned_user?.first_name ? (
                              <Chip
                                  label={row.assigned_user?.first_name}
                                  onDelete={() =>
                                      isLeadPage ? handleReAssignUser([row.id]) : null
                                  }
                              />
                          ) : !isLeadPage ? null : (
                              <Chip
                                  label={"Add"}
                                  className={"add-chip"}
                                  onClick={() => {
                                    onClickAssign([row.id]);
                                    props.setSelected([row.id]);
                                  }}
                                  sx={{backgroundColor: "rgba(203, 12, 159, 0.04)", color: "#cb0c9f", fontWeight: "600"}}
                                  onDelete={() => {
                                    onClickAssign([row.id]);
                                    props.setSelected([row.id]);
                                  }}
                                  deleteIcon={<AddIcon />}
                              />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {row?.tag && row?.tag.length > 0 ? (
                              <>
                                {row?.tag?.map((item) => {
                                  return (
                                      <div key={item.id} style={{ marginBottom: 5 }}>
                                        <Chip
                                            label={item?.title}
                                            onDelete={() =>
                                                isLeadPage
                                                    ? handleReAssignTags(item.id, [row.id])
                                                    : null
                                            }
                                        />
                                      </div>
                                  );
                                })}
                              </>
                          ) : !isLeadPage ? null : (
                              <Chip
                                  label={"Add"}
                                  className={"add-chip"}
                                  onClick={() => {
                                    onClickTag([row.id]);
                                    props.setSelected([row.id]);
                                  }}
                                  sx={{backgroundColor: "rgba(203, 12, 159, 0.04)", color: "#cb0c9f", fontWeight: "600"}}
                                  onDelete={() => {
                                    onClickTag([row.id]);
                                    props.setSelected([row.id]);
                                  }}
                                  deleteIcon={<AddIcon />}
                              />
                          )}
                        </TableCell>
                        <TableCell align="left" padding="checkbox" width="30%">
                          {row?.campaign || "(none)"}
                        </TableCell>
                        <TableCell align="left">{row?.form || "(none)"}</TableCell>
                        <TableCell align="left">{row?.bd || "(none)"}</TableCell>
                        <TableCell align="left">
                          {row?.source || "(none)"}
                        </TableCell>

                        <TableCell align="center">
                          <Button  onClick={() =>
                              isLeadPage ? onDeleteLead(row.id) : null
                          }>
                            <DeleteIcon
                                color="secondary"
                            />
                          </Button>
                        </TableCell>
                      </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
  );
}
