import { Audiences } from "vendor/services/api/agent";

export const getAudiences = async (filters) => {
  try {
    const response = await Audiences.list(filters);
    return response;
  } catch (error) {
    return error;
  }
};

export const download = async (audienceId) => {
  try {
    const response = await Audiences.download(audienceId);
    return response;
  } catch (error) {
    return error;
  }
};
export const create = async (data) => {
  try {
    const response = await Audiences.create(data);
    return response;
  } catch (error) {
    return error;
  }
};
export const multipleRemove = async (data) => {
  try {
    const response = await Audiences.multiple_remove(data);
    return response;
  } catch (error) {
    return error;
  }
};
export const update = async (audienceId, data) => {
  try {
    const response = await Audiences.update(audienceId, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const remove = async (audienceId) => {
  try {
    const response = await Audiences.remove(audienceId);
    return response;
  } catch (error) {
    return error;
  }
};
export const details = async (audienceId) => {
  try {
    const response = await Audiences.details(audienceId);
    return response;
  } catch (error) {
    return error;
  }
};
export const leads = async (audienceId, filters) => {
  try {
    const response = await Audiences.leads(audienceId, filters);
    return response;
  } catch (error) {
    return error;
  }
};
export const removeLeads = async (audienceId, data) => {
  try {
    const response = await Audiences.remove_leads(audienceId, data);
    return response;
  } catch (error) {
    return error;
  }
};
