import {
  ACTION_STATUSES,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCEED,
  INVITE_USER_FAILURE,
  CHANGE_INVITE_USER_STATUS,
} from "constants/type";

const initialState = {
  isLoging: localStorage.getItem("jwt-access-token") ? true : false,
  user: {},
  inviteStatus: null,
  message: "",
  preliminaryCheckDetails: null,
};

const InviteReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CHANGE_INVITE_USER_STATUS:
      return {
        ...state,
        inviteStatus: null,
        message: "",
      };
    case INVITE_USER_REQUEST:
      return {
        ...state,
        inviteStatus: ACTION_STATUSES.PENDING,
        message: "",
      };
    case INVITE_USER_SUCCEED:
      return {
        ...state,
        inviteStatus: ACTION_STATUSES.SUCCEED,
        message: "",
      };
    case INVITE_USER_FAILURE:
      return {
        ...state,
        inviteStatus: ACTION_STATUSES.FAILURE,
        message: action.message,
      };
    default:
      return state;
  }
};

export default InviteReducer;
