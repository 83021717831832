// AUTH CONSTANTS
export const ACTION_STATUSES = {
  PENDING: 0,
  SUCCEED: 1,
  FAILURE: 3,
  RESET: null,
};

export const SIGN_IN_USER = "SIGN_IN_USER";
export const SIGN_OUT_USER = "SIGN_OUT_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOGIN = "SET_LOGIN";

export const SET_USER_REQUEST = "SET_USER_REQUEST";
export const SET_USER_SUCCEED = "SET_USER_SUCCEED";
export const SET_USER_FAILURE = "SET_USER_FAILURE";

export const SIGN_UP_USER_REQUEST = "SIGN_UP_USER_REQUEST";
export const SIGN_UP_USER_SUCCEED = "SIGN_UP_USER_SUCCEED";
export const SIGN_UP_USER_FAILURE = "SIGN_UP_USER_FAILURE";

export const FORGOT_PASS_USER_REQUEST = "FORGOT_PASS_USER_REQUEST";
export const FORGOT_PASS_USER_SUCCEED = "FORGOT_PASS_USER_SUCCEED";
export const FORGOT_PASS_USER_FAILURE = "FORGOT_PASS_USER_FAILURE";

export const SET_PASS_USER_REQUEST = "SET_PASS_USER_REQUEST";
export const SET_PASS_USER_SUCCEED = "SET_PASS_USER_SUCCEED";
export const SET_PASS_USER_FAILURE = "SET_PASS_USER_FAILURE";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCEED = "INVITE_USER_SUCCEED";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";
export const CHANGE_INVITE_USER_STATUS = "CHANGE_INVITE_USER_STATUS";

export const PRELIMINARY_CHECK_USER_REQUEST = "PRELIMINARY_CHECK_USER_REQUEST";
export const PRELIMINARY_CHECK_USER_SUCCEED = "PRELIMINARY_CHECK_USER_SUCCEED";
export const PRELIMINARY_CHECK_USER_FAILURE = "PRELIMINARY_CHECK_USER_FAILURE";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCEED = "UPDATE_USER_SUCCEED";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const CHANGE_PASS_ME_REQUEST = "CHANGE_PASS_ME_REQUEST";
export const CHANGE_PASS_ME_SUCCEED = "CHANGE_PASS_ME_SUCCEED";
export const CHANGE_PASS_ME_FAILURE = "CHANGE_PASS_ME_FAILURE";

//company users
export const GET_COMPANY_USERS_REQUEST = "GET_COMPANY_USERS_REQUEST";
export const GET_COMPANY_USERS_SUCCEED = "GET_COMPANY_USERS_SUCCEED";
export const GET_COMPANY_USERS_FAILURE = "GET_COMPANY_USERS_FAILURE";

export const DELETE_COMPANY_USER_REQUEST = "DELETE_COMPANY_USER_REQUEST";
export const DELETE_COMPANY_USER_SUCCEED = "DELETE_COMPANY_USER_SUCCEED";
export const DELETE_COMPANY_USER_FAILURE = "DELETE_COMPANY_USER_FAILURE";

export const UPDATE_COMPANY_USER_REQUEST = "UPDATE_COMPANY_USER_REQUEST";
export const UPDATE_COMPANY_USER_SUCCEED = "UPDATE_COMPANY_USER_SUCCEED";
export const UPDATE_COMPANY_USER_FAILURE = "UPDATE_COMPANY_USER_FAILURE";

//leads
export const GET_FACEBOOK_LEADS_REQUEST = "GET_FACEBOOK_LEADS_REQUEST";
export const GET_FACEBOOK_LEADS_SUCCEED = "GET_FACEBOOK_LEADS_SUCCEED";
export const GET_FACEBOOK_LEADS_FAILURE = "GET_FACEBOOK_LEADS_FAILURE";

export const ASSIGN_TAG_LEADS_REQUEST = "ASSIGN_TAG_LEADS_REQUEST";
export const ASSIGN_TAG_LEADS_SUCCEED = "ASSIGN_TAG_LEADS_SUCCEED";
export const ASSIGN_TAG_LEADS_FAILURE = "ASSIGN_TAG_LEADS_FAILURE";

export const IMPORT_LEADS_REQUEST = "IMPORT_LEADS_REQUEST";
export const IMPORT_LEADS_SUCCEED = "IMPORT_LEADS_SUCCEED";
export const IMPORT_LEADS_FAILURE = "IMPORT_LEADS_FAILURE";

export const REASSIGN_TAG_LEADS_REQUEST = "REASSIGN_TAG_LEADS_REQUEST";
export const REASSIGN_TAG_LEADS_SUCCEED = "REASSIGN_TAG_LEADS_SUCCEED";
export const REASSIGN_TAG_LEADS_FAILURE = "REASSIGN_TAG_LEADS_FAILURE";

export const CREATE_A_LEAD_REQUEST = "CREATE_A_LEAD_REQUEST";
export const CREATE_A_LEAD_SUCCEED = "CREATE_A_LEAD_SUCCEED";
export const CREATE_A_LEAD_FAILURE = "CREATE_A_LEAD_FAILURE";

//tags
export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCEED = "GET_TAGS_SUCCEED";
export const GET_TAGS_FAILURE = "GET_TAGS_FAILURE";

export const CREATE_TAG_REQUEST = "CREATE_TAG_REQUEST";
export const CREATE_TAG_SUCCEED = "CREATE_TAG_SUCCEED";
export const CREATE_TAG_FAILURE = "CREATE_TAG_FAILURE";

export const REMOVE_TAG_REQUEST = "REMOVE_TAG_REQUEST";
export const REMOVE_TAG_SUCCEED = "REMOVE_TAG_SUCCEED";
export const REMOVE_TAG_FAILURE = "REMOVE_TAG_FAILURE";

export const UPDATE_TAG_REQUEST = "UPDATE_TAG_REQUEST";
export const UPDATE_TAG_SUCCEED = "UPDATE_TAG_SUCCEED";
export const UPDATE_TAG_FAILURE = "UPDATE_TAG_FAILURE";

export const LEADS_ASSIGNTO_USER_REQUEST = "LEADS_ASSIGNTO_USER_REQUEST";
export const LEADS_ASSIGNTO_USER_SUCCEED = "LEADS_ASSIGNTO_USER_SUCCEED";
export const LEADS_ASSIGNTO_USER_FAILURE = "LEADS_ASSIGNTO_USER_FAILURE";

export const LEADS_UNASSIGN_FROM_USER_REQUEST =
  "LEADS_UNASSIGN_FROM_USER_REQUEST";
export const LEADS_UNASSIGN_FROM_USER_SUCCEED =
  "LEADS_UNASSIGN_FROM_USER_SUCCEED";
export const LEADS_UNASSIGN_FROM_USER_FAILURE =
  "LEADS_UNASSIGN_FROM_USER_FAILURE";
export const REMOVE_LEADS_REQUEST = "REMOVE_LEADS_REQUEST";
export const REMOVE_LEADS_SUCCEED = "REMOVE_LEADS_SUCCEED";
export const REMOVE_LEADS_FAILURE = "REMOVE_LEADS_FAILURE";

//audiences
export const GET_AUDIENCES_REQUEST = "GET_AUDIENCES_REQUEST";
export const GET_AUDIENCES_SUCCEED = "GET_AUDIENCES_SUCCEED";
export const GET_AUDIENCES_FAILURE = "GET_AUDIENCES_FAILURE";
export const EXPORT_AUDIENCES_REQUEST = "EXPORT_AUDIENCES_REQUEST";
export const EXPORT_AUDIENCES_SUCCEED = "EXPORT_AUDIENCES_SUCCEED";
export const EXPORT_AUDIENCES_FAILURE = "EXPORT_AUDIENCES_FAILURE";
export const CREATE_AUDIENCE_REQUEST = "CREATE_AUDIENCE_REQUEST";
export const CREATE_AUDIENCE_SUCCEED = "CREATE_AUDIENCE_SUCCEED";
export const CREATE_AUDIENCE_FAILURE = "CREATE_AUDIENCE_FAILURE";
export const UPDATE_AUDIENCE_REQUEST = "UPDATE_AUDIENCE_REQUEST";
export const UPDATE_AUDIENCE_SUCCEED = "UPDATE_AUDIENCE_SUCCEED";
export const UPDATE_AUDIENCE_FAILURE = "UPDATE_AUDIENCE_FAILURE";
export const REMOVE_AUDIENCE_REQUEST = "REMOVE_AUDIENCE_REQUEST";
export const REMOVE_AUDIENCE_SUCCEED = "REMOVE_AUDIENCE_SUCCEED";
export const REMOVE_AUDIENCE_FAILURE = "REMOVE_AUDIENCE_FAILURE";
export const MULTIPLE_REMOVE_AUDIENCES_REQUEST =
  "MULTIPLE_REMOVE_AUDIENCES_REQUEST";
export const MULTIPLE_REMOVE_AUDIENCES_SUCCEED =
  "MULTIPLE_REMOVE_AUDIENCES_SUCCEED";
export const MULTIPLE_REMOVE_AUDIENCES_FAILURE =
  "MULTIPLE_REMOVE_AUDIENCES_FAILURE";
export const GET_AUDIENCE_DETAILS_REQUEST = "GET_AUDIENCE_DETAILS_REQUEST";
export const GET_AUDIENCE_DETAILS_SUCCEED = "GET_AUDIENCE_DETAILS_SUCCEED";
export const GET_AUDIENCE_DETAILS_FAILURE = "GET_AUDIENCE_DETAILS_FAILURE";
export const GET_AUDIENCE_LEADS_REQUEST = "GET_AUDIENCE_LEADS_REQUEST";
export const GET_AUDIENCE_LEADS_SUCCEED = "GET_AUDIENCE_LEADS_SUCCEED";
export const GET_AUDIENCE_LEADS_FAILURE = "GET_AUDIENCELEADS_FAILURE";
export const REMOVE_AUDIENCE_LEADS_REQUEST = "REMOVE_AUDIENCE_LEADS_REQUEST";
export const REMOVE_AUDIENCE_LEADS_SUCCEED = "REMOVE_AUDIENCE_LEADS_SUCCEED";
export const REMOVE_AUDIENCE_LEADS_FAILURE = "REMOVE_AUDIENCE_LEADS_FAILURE";

//sources
export const GET_SOURCES_REQUEST = "GET_SOURCES_REQUEST";
export const GET_SOURCES_SUCCEED = "GET_SOURCES_SUCCEED";
export const GET_SOURCES_FAILURE = "GET_SOURCES_FAILURE";
export const GET_SOURCE_DETAILS_REQUEST = "GET_SOURCE_DETAILS_REQUEST";
export const GET_SOURCE_DETAILS_SUCCEED = "GET_SOURCE_DETAILS_SUCCEED";
export const GET_SOURCE_DETAILS_FAILURE = "GET_SOURCE_DETAILS_FAILURE";
export const UPDATE_SOURCE_REQUEST = "UPDATE_SOURCE_REQUEST";
export const UPDATE_SOURCE_SUCCEED = "UPDATE_SOURCE_SUCCEED";
export const UPDATE_SOURCE_FAILURE = "UPDATE_SOURCE_FAILURE";

//charts
export const GET_CHARTS_LEADS_REQUEST = "GET_CHARTS_LEADS_REQUEST";
export const GET_CHARTS_LEADS_SUCCEED = "GET_CHARTS_LEADS_SUCCEED";
export const GET_CHARTS_LEADS_FAILURE = "GET_CHARTS_LEADS_FAILURE";
export const GET_DASHBOARD_LEADS_REQUEST = "GET_DASHBOARD_LEADS_REQUEST";
export const GET_DASHBOARD_LEADS_SUCCEED = "GET_DASHBOARD_LEADS_SUCCEED";
export const GET_DASHBOARD_LEADS_FAILURE = "GET_DASHBOARD_LEADS_FAILURE";

//modal
export const CHANGE_MODAL_STATE = "CHANGE_MODAL_STATE";
export const CHANGE_MODAL_COMPONENT = "CHANGE_MODAL_COMPONENT";

//facebook
export const GET_FACEBOOK_ADACCOUNTS_REQUEST = "GET_FACEBOOK_ADACCOUNTS_REQUEST";
export const GET_FACEBOOK_ADACCOUNTS_SUCCEED = "GET_FACEBOOK_ADACCOUNTS_SUCCEED";
export const GET_FACEBOOK_ADACCOUNTS_FAILURE = "GET_FACEBOOK_ADACCOUNTS_FAILURE";

export const GET_FACEBOOK_PROMOTEPAGES_REQUEST = "GET_FACEBOOK_PROMOTEPAGES_REQUEST";
export const GET_FACEBOOK_PROMOTEPAGES_SUCCEED = "GET_FACEBOOK_PROMOTEPAGES_SUCCEED";
export const GET_FACEBOOK_PROMOTEPAGES_FAILURE = "GET_FACEBOOK_PROMOTEPAGES_FAILURE";

export const GET_FACEBOOK_LEADGENFORMS_REQUEST = "GET_FACEBOOK_LEADGENFORMS_REQUEST";
export const GET_FACEBOOK_LEADGENFORMS_SUCCEED = "GET_FACEBOOK_LEADGENFORMS_SUCCEED";
export const GET_FACEBOOK_LEADGENFORMS_FAILURE = "GET_FACEBOOK_LEADGENFORMS_FAILURE";

export const EXPORT_FACEBOOK_DATA_REQUEST = "EXPORT_FACEBOOK_DATA_REQUEST";
export const EXPORT_FACEBOOK_DATA_SUCCEED = "EXPORT_FACEBOOK_DATA_SUCCEED";
export const EXPORT_FACEBOOK_DATA_FAILURE = "EXPORT_FACEBOOK_DATA_FAILURE";

export const EXPORT_FACEBOOK_PAGES_REQUEST = "EXPORT_FACEBOOK_PAGES_REQUEST";
export const EXPORT_FACEBOOK_PAGES_SUCCEED = "EXPORT_FACEBOOK_PAGES_SUCCEED";
export const EXPORT_FACEBOOK_PAGES_FAILURE = "EXPORT_FACEBOOK_PAGES_FAILURE";

export const SET_SELECTED_ADACCOUNT = "SET_SELECTED_ADACCOUNT";
export const SET_SELECTED_PROMOTE_PAGE = "SET_SELECTED_PROMOTE_PAGE";
export const SET_SELECTED_LEAD_FORMS = "SET_SELECTED_LEAD_FORMS";
export const SET_INITIAL = "SET_INITIAL";
export const REMOVE_PAGE_LIST = "REMOVE_PAGE_LIST";

export const GET_FACEBOOK_USER_TOKEN_REQUEST = "GET_FACEBOOK_USER_TOKEN_REQUEST";
export const GET_FACEBOOK_USER_TOKEN_SUCCEED = "GET_FACEBOOK_USER_TOKEN_SUCCEED";
export const GET_FACEBOOK_USER_TOKEN_FAILURE = "GET_FACEBOOK_USER_TOKEN_FAILURE";

export const SUBSCRIBE_FIELDS_REQUEST = "SUBSCRIBE_FIELDS_REQUEST";
export const SUBSCRIBE_FIELDS_SUCCEED = "SUBSCRIBE_FIELDS_SUCCEED";
export const SUBSCRIBE_FIELDS_FAILURE = "SUBSCRIBE_FIELDS_FAILURE";

