const headCells = [
    {
        id: "name",
        numeric: true,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "surname",
        numeric: true,
        disablePadding: false,
        label: "Surname",
    },
    {
        id: "email",
        numeric: true,
        disablePadding: false,
        label: "Email",
    },
    {
        id: "role",
        numeric: true,
        disablePadding: false,
        label: "Role",
    },
    {
        id: "actions",
        numeric: true,
        disablePadding: false,
        label: "Actions",
    },
];

export { headCells };
