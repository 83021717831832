import {
  CHANGE_MODAL_STATE,
  CHANGE_MODAL_COMPONENT,
} from "constants/type";

const initialState = {
  modalState: false,
  modalComponent: "",
};

const modalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CHANGE_MODAL_STATE:
      return {
        ...state,
        modalState: action.payload,
      };
    case CHANGE_MODAL_COMPONENT:
      return {
        ...state,
        modalComponent: action.payload,
      };
    default:
      return state;
  }
};

export default modalReducer;