import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { stripeIcon } from "assets";

const Payment = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h4">Payment Information</Typography>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              All your information payment is securely submitted.
            </Typography>
            <img
              src={stripeIcon}
              alt="NUKUK"
              className="stripe-icon has-cursor"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Payment;
