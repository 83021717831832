const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "#",
    hideSortIcon: true,
    disabled: true,
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
    disabled: false,
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
    disabled: false,
  },
  {
    id: "country",
    numeric: true,
    disablePadding: false,
    label: "Country",
    disabled: false,
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
    hideSortIcon: true,
    disabled: true,
  },
  {
    id: "acquisition_date",
    numeric: true,
    disablePadding: false,
    label: "Acquisition Date",
    disabled: false,
  },
  {
    id: "assigned_user",
    numeric: true,
    disablePadding: false,
    label: "Assigned",
    hideSortIcon: true,
    disabled: true,
  },
  {
    id: "tag",
    numeric: true,
    disablePadding: false,
    label: "Tag",
    hideSortIcon: true,
    disabled: true,
  },
  {
    id: "compaign",
    numeric: true,
    disablePadding: false,
    label: "Campaign",
    disabled: false,
  },
  {
    id: "formColumn",
    numeric: true,
    disablePadding: false,
    label: "Form",
    disabled: false,
  },
  {
    id: "bd",
    numeric: true,
    disablePadding: false,
    label: "Birthday",
    hideSortIcon: true,
    disabled: true,
  },
  {
    id: "source",
    numeric: true,
    disablePadding: false,
    label: "Source",
    disabled: false,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    hideSortIcon: true,
    disabled: true,
  },
];

export { headCells };
