const routes = {
  APP: "/",
  HOME: "/home",
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  USER_INVITATION: "/invitation",
  FORGOT_PASS: "/forgot-password",
  RESET_PASS: "/reset-password/:uuid",
  SET_PASS: "/set-password/:uuid",
  DASHBOARD: "/dashboard",
  LEADS: "/leads",
  CONFIG: "/configurations",
  TAGS: "/configurations/tags",
  AUDIENCES: "/audiences",
  AUDIENCES_EDIT: "/audience/:name/:id/edit",
  SOURCES: "/configurations/sources",
  SOURCES_ADD: "/configurations/sources/add",
  SOURCES_EDIT: "/configurations/sources/:name/:id/edit",
  TEAM: "/configurations/team",
  USER_ACCOUNT: "/user-account",
  BILLING_SUBSCRIPTION: "/configurations/billing/subscriptions",
  BILLING_INVOICE: "/configurations/billing/invoices",
  BILLING_PAYMENT: "/configurations/billing/payment_information",
  PRICING: "/pricing",
  HOW_IT_WORKS: "/how-it-works",
};

export default routes;
