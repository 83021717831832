import requests from "./base";

const Leads = {
  exportFbPages: (data) => requests.post(`facebook/connect/`, data),
  getFbLeads: (company_id, filters) =>
    requests.get(
      `lead/get/${company_id}/?limit=${filters.limit}&offset=${
        filters.offset
      }&search=${filters.search}&ordering=${filters.ordering}${
        filters.start_date ? `&start_date=${filters.start_date}` : ""
      }${filters.end_date ? `&end_date=${filters.end_date}` : ""}`
    ),
  getTagsLeads: (company_id) => requests.get(`lead/get/${company_id}/`),
  leadsAssignToUser: (data) => requests.patch("lead/assign-user/", data),
  leadsUnassignUser: (data) => requests.patch("lead/reassign-user/", data),
  assignTags: (data) => requests.post(`lead/assign-tag/`, data),
  importLeads: (file) => requests.postForm(`lead/import/`, file),
  reAssignTags: (data) => requests.put(`lead/reassign-tag/`, data),
  createLead: (data) => requests.post(`lead/crud/`, data),
  remove: (data) => requests.put(`lead/crud/delete/`, data),
};

export default Leads;
