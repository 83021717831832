import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_STATUSES } from "constants/type";
import {
  createTag,
  fetchTags,
  removeTag,
  updateTag,
} from "vendor/redux/actions";
import { Container, Grid, Button } from "@mui/material";
import { TagsList, Loading, handleShowModal, modalTypes } from "components";

const Tags = () => {
  const dispatch = useDispatch();
  const { tagList, remove_status, status, update_status, create_status } =
    useSelector((state) => state.tag);
  const { user } = useSelector((state) => state.user);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const onOpenModal = (component) => handleShowModal(dispatch, component, true);

  const init = () => {
    dispatch(fetchTags());
  };
  useEffect(init, [dispatch]);

  const onDeleteTag = async (id) => {
    await dispatch(removeTag(id));
    handleShowModal(dispatch, "", false);
  };
  const onUpdateTag = async () => {
    await dispatch(updateTag(selectedTag.id, { title: selectedTag.title }));
  };

  const onCreateTag = async (value) => {
    await dispatch(
      createTag({
        title: value,
        company: user?.company?.id,
      })
    );
    handleShowModal(dispatch, "", false);
  };
  const submitListener = () => {
    if (remove_status === ACTION_STATUSES.SUCCEED) {
      init();
      setSelectedTag(null);
    }
    if (update_status === ACTION_STATUSES.SUCCEED) {
      init();
      setIsEdit(false);
      setSelectedTag(null);
    }
    if (create_status === ACTION_STATUSES.SUCCEED) {
      init();
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(submitListener, [remove_status, update_status, create_status]);

  return (
    <>
      {ACTION_STATUSES.PENDING === status && <Loading />}
      <div className="dashboard">
        <Container maxWidth="xl">
          <Grid direction="row">
            <Grid
              container
              align="right"
              justifyContent={"flex-end"}
              spacing={{ xs: 1, sm: 0, md: 0, mt: 8, mr: 8 }}
              columns={{ xs: 1, sm: 2, md: 1, mr: 10, mt: 8 }}
              style={{
                marginBottom: 10,
                marginLeft: "auto",
                width: "250px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  onOpenModal(
                    modalTypes.createTag({
                      message: "Create New Tag",
                      onSave: onCreateTag,
                      status: status,
                    })
                  )
                }
              >
                Create a new tag
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            style={{ marginTop: 15, marginBottom: 30 }}
          >
            <TagsList
              tags={tagList?.results ? tagList?.results : []}
              onEdit={(tag) => {
                setIsEdit(true);
                setSelectedTag(tag);
              }}
              onDelete={(tag) => {
                onOpenModal(
                  modalTypes.deleteTag({
                    message: `deletting tag`,
                    action: () => onDeleteTag(tag.id),
                  })
                );
              }}
              isEdit={isEdit}
              selectedTag={selectedTag}
              setSelectedTag={(event) =>
                setSelectedTag({
                  ...selectedTag,
                  title: event.target.value,
                })
              }
              onUpdateTag={onUpdateTag}
              onClose={() => {
                setIsEdit(false);
                setSelectedTag(null);
              }}
            />
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default Tags;
