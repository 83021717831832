import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ACTION_STATUSES } from "constants/type";
import {
  updateAudience,
  fetchAudienceLeads,
  removeAudienceLeads,
} from "vendor/redux/actions";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button, Box } from "@mui/material";
import { LeadsList, Input, handleShowModal, modalTypes } from "components";
import EditIcon from "@mui/icons-material/Edit";

export default function AudienceEdit(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { update_status, leads, leadsCount, remove_leads_status } = useSelector(
    (state) => state.audiences
  );
  const [name, setName] = useState(params.name);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    ordering: "id",
  });
  const onOpenModal = (component) => handleShowModal(dispatch, component, true);

  const handleRemoveLeadsAudience = async () => {
    await dispatch(removeAudienceLeads(params.id, { lead_ids: selectedLeads }));
  };

  const submitListener = () => {
    if (update_status === ACTION_STATUSES.SUCCEED) {
      toast.success("The Audience has been updated successfully!");
      setIsEdit(false);
    }
    if (remove_leads_status === ACTION_STATUSES.SUCCEED) {
      dispatch(fetchAudienceLeads(params.id, filters));
      handleShowModal(dispatch, "", false);
      setSelectedLeads([]);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(submitListener, [update_status, remove_leads_status]);

  useEffect(() => {
    dispatch(fetchAudienceLeads(params.id, filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params]);

  const handleUpdateAudience = () => {
    dispatch(
      updateAudience(params.id, {
        name: name,
      })
    );
  };

  const handleChangePage = (page) => {
    dispatch(
      fetchAudienceLeads(params.id, {
        ...filters,
        offset: page * filters.limit,
      })
    );
    setFilters({ ...filters, offset: page * filters.limit });
  };
  const handleChangeRowsPerPage = (limit) => {
    dispatch(
      fetchAudienceLeads(params.id, { ...filters, offset: 0, limit: limit })
    );
    setFilters({ ...filters, limit: limit });
  };
  const handleRequestSort = (order) => {
    dispatch(fetchAudienceLeads(params.id, { ...filters, ordering: order }));
    setFilters({ ...filters, ordering: order });
  };

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{
          marginBottom: 30,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5">Edit Audiecne Setting</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Typography
            variant="title"
            style={{ marginBottom: 10, fontSize: 15 }}
            component="div"
          >
            Name*
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Input
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              onUpdate={handleUpdateAudience}
              onClose={() => setIsEdit(false)}
              status={update_status}
              label={"Pick a name to identify Audience"}
              InputLabelProps={{
                shrink: true,
              }}
              isEdit={isEdit}
            />
            <div style={{ marginTop: 15, marginLeft: 10, cursor: "pointer" }}>
              <EditIcon onClick={() => setIsEdit(true)} color="info" />
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        align="right"
        justifyContent={"flex-end"}
        spacing={{ xs: 1, sm: 0, md: 0, mt: 8, mr: 8 }}
        columns={{ xs: 1, sm: 2, md: 1, mr: 10, mt: 8 }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            onOpenModal(
              modalTypes.removeAudienceLeads({
                message: `Remove these leads from ${params.name}`,
                action: handleRemoveLeadsAudience,
              })
            );
          }}
          style={{ marginRight: 10 }}
          disabled={selectedLeads.length === 0}
        >
          Remove Leads from Audience
        </Button>
      </Grid>
      <LeadsList
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        orderBy={filters.ordering}
        handleRequestSort={handleRequestSort}
        setSelected={(leads) => setSelectedLeads(leads)}
        selected={selectedLeads}
        leadsData={leads}
        count={leadsCount}
        onDelete={(id) => {
          setSelectedLeads([id]);
        }}
        isLeadPage={false}
      />
    </>
  );
}
