const headCells = [
  {
    id: "name",
    numeric: true,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "create_at",
    numeric: true,
    disablePadding: false,
    label: "Created date",
  },
];

export { headCells };
