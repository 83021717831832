import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import {handleShowModal} from "./handleShowModal";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {useHistory} from "react-router-dom";

const Modal = () => {
    const dispatch = useDispatch();
    const {modalState, modalComponent} = useSelector(state => state.modals)
    const onCloseModal = () => handleShowModal(dispatch, "", false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.up('xs'));
    const [maxWidth, setMaxWidth] = useState('sm');
    const [fullWidth, setFullWidth] = useState(true);
    const history = useHistory();


    return (
        <Dialog
            open={modalState}
            history={history}
            onClose={onCloseModal}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            fullScreen={modalComponent?.fullScreen? fullScreen: null}
        >
            <DialogTitle>
                {modalComponent?.title || null}
                <IconButton
                    aria-label="close"
                    onClick={onCloseModal}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {modalComponent?.text && (
                    <DialogContentText id="alert-dialog-description">
                        {modalComponent.text}
                    </DialogContentText>
                )}
                {modalComponent?.component ?? ""}
            </DialogContent>
            {modalComponent?.actions && <DialogActions>{modalComponent.actions}</DialogActions>}
        </Dialog>
    );
};

export default Modal;
