import {
  LeadsPage,
  DashboardPage,
  ConfigurationsPage,
  TagsPage,
  AudiencesPage,
  SourcesPage,
  SourcesAddPage,
  SourcesEditPage,
  AudienceEditPage,
  TeamPage,
  UserDetailsPage,
  PaymentPage,
  InvoicesPage,
  SubscriptionPage,
} from "screens";
import routes from "constants/routes";
const DashboardRoutes = [
  {
    id: "leads",
    component: <LeadsPage />,
    path: routes.LEADS,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Leads",
        path: routes.LEADS,
      },
    ],
  },
  {
    id: "details",
    component: <UserDetailsPage />,
    path: routes.USER_ACCOUNT,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "My account",
        path: routes.USER_ACCOUNT,
      },
    ],
  },
  {
    id: "dashboard",
    component: <DashboardPage />,
    path: routes.DASHBOARD,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.DASHBOARD,
      },
    ],
  },
  {
    id: "configuration",
    component: <ConfigurationsPage />,
    path: routes.CONFIG,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
    ],
  },
  {
    id: "tags",
    component: <TagsPage />,
    path: routes.TAGS,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
      {
        name: "Tags",
        path: routes.TAGS,
      },
    ],
  },
  {
    id: "audiences",
    component: <AudiencesPage />,
    path: routes.AUDIENCES,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Audiences",
        path: routes.AUDIENCES,
      },
    ],
  },
  {
    id: "audience_edit",
    component: <AudienceEditPage />,
    path: routes.AUDIENCES_EDIT,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Audiences",
        path: routes.AUDIENCES,
      },
      {
        name: "audience_name",
        path: routes.AUDIENCES_EDIT,
        isParam: true,
      },
      {
        name: "edit",
        path: routes.AUDIENCES_EDIT,
        isEnd: true,
      },
    ],
  },
  {
    id: "sources",
    component: <SourcesPage />,
    path: routes.SOURCES,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
      {
        name: "Sources",
        path: routes.SOURCES,
      },
    ],
  },
  {
    id: "sources_add",
    component: <SourcesAddPage />,
    path: routes.SOURCES_ADD,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
      {
        name: "Sources",
        path: routes.SOURCES,
      },
      {
        name: "Add",
        path: routes.SOURCES_ADD,
      },
    ],
  },
  {
    id: "sources_edit",
    component: <SourcesEditPage />,
    path: routes.SOURCES_EDIT,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
      {
        name: "Sources",
        path: routes.SOURCES,
      },
      {
        name: "source_name",
        path: routes.SOURCES_EDIT,
        isParam: true,
      },
      {
        name: "edit",
        path: routes.SOURCES_EDIT,
        isEnd: true,
      },
    ],
  },
  {
    id: "team",
    component: <TeamPage />,
    path: routes.TEAM,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
      {
        name: "Team",
        path: routes.TEAM,
      },
    ],
  },
  {
    id: "subscriptions",
    component: <SubscriptionPage />,
    path: routes.BILLING_SUBSCRIPTION,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
      {
        name: "Billing",
        path: "",
        isEnd: true,
      },
      {
        name: "Subscription",
        path: routes.BILLING_SUBSCRIPTION,
      },
    ],
  },
  {
    id: "invoice",
    component: <InvoicesPage />,
    path: routes.BILLING_INVOICE,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
      {
        name: "Billing",
        path: "",
        isEnd: true,
      },
      {
        name: "Invoice",
        path: routes.BILLING_INVOICE,
      },
    ],
  },
  {
    id: "payment_information",
    component: <PaymentPage />,
    path: routes.BILLING_PAYMENT,
    breadcrumb: [
      {
        name: "Dashboard",
        path: routes.APP,
      },
      {
        name: "Configurations",
        path: routes.CONFIG,
      },
      {
        name: "Billing",
        path: "",
        isEnd: true,
      },
      {
        name: "Payment Information",
        path: routes.BILLING_PAYMENT,
      },
    ],
  },
];

export default DashboardRoutes;
