import {User} from "./api/agent";

export const getCompanyUsers = async () => {
    try {
        const response = await User.list();
        return response;
    } catch (error) {
        return error;
    }
};

export const deleteCompanyUser = async (id) => {
    try {
        const response = await User.deleteUser(id);
        return response;
    } catch (error) {
        return error;
    }
};

export const updateCompanyUser = async (id, data) => {
    try {
        const response = await User.updateUser(id, data);
        return response;
    } catch (error) {
        return error;
    }
};
